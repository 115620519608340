import React, { Component } from 'react';
import Intro from '../Intro';
import countries from '../../../countries';

export default class PostIdent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      externalPersonId: '',
      salutation: 'male',
      identificationDocument: 'personalausweis',
      birthName: '',
      birthDate: '',
      birthPlace: '',
      nationality: 'DEU',
      streetAddress: '',
      postalCode: '',
      city: '',
      country: 'DEU',
      dateIssued: '',
      dateOfExpiry: '',
      authority: '',
      placeOfIssue: '',
      countryOfDocument: 'DEU',
      countries: countries,
    };
  }

  // if (this.state.birthName.length <=0) {
  //   this.setState({birthName: ' '})
  // }

  inputHandler = (event) => {
    // console.log('Was changed', event.target);
    this.setState({ [event.target.name]: event.target.value });
  };

  // event handler for radio buttons
  handleOptionChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    let countriesList = this.state.countries.map((element) => {
      // eslint-disable-next-line no-lone-blocks
      {
        return (
          <option
            key={Object.values(element)[0]}
            value={Object.keys(element)[0]}
          >
            {Object.values(element)[0]}
          </option>
        );
      }
    });

    return (
      <>
        <div className="forms form2">
          <h2>Post Ident</h2>
          <form onSubmit noValidate>
            <label>
              External Person ID:
              <input
                onChange={this.inputHandler}
                value={this.state.externalPersonId}
                type="text"
                name="externalPersonId"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <br />

            <label>
              Anrede:
              <input
                type="radio"
                name="salutation"
                value="male"
                checked={this.state.salutation === 'male'}
                onChange={this.handleOptionChange}
                className="form-check-input"
                noValidate
              />
              Mann
              <input
                type="radio"
                name="salutation"
                value="female"
                checked={this.state.salutation === 'female'}
                onChange={this.handleOptionChange}
                className="form-check-input"
                noValidate
              />
              Frau
            </label>
            <br />

            <label>
              Ausweisart:
              <input
                type="radio"
                name="identificationDocument"
                value="personalausweis"
                checked={
                  this.state.identificationDocument === 'personalausweis'
                }
                onChange={this.handleOptionChange}
                className="form-check-input"
                noValidate
              />
              Personalausweis
              <input
                type="radio"
                name="identificationDocument"
                value="reisepass"
                checked={this.state.identificationDocument === 'reisepass'}
                onChange={this.handleOptionChange}
                className="form-check-input"
                noValidate
              />
              Reisepass
            </label>
            <br />

            <label>
              Geburtsname:
              <input
                onChange={this.inputHandler}
                value={this.state.birthName}
                type="text"
                name="birthName"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <br />

            <label>
              Geburtsdatum:
              <input
                onChange={this.inputHandler}
                value={this.state.birthDate}
                type="text"
                name="birthDate"
                noValidate
                placeholder="yyyy-mm-dd"
                required
              />
            </label>
            <br />

            <label>
              Geburtsort:
              <input
                onChange={this.inputHandler}
                value={this.state.birthPlace}
                type="text"
                name="birthPlace"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <br />

            <label>
              Nationalität:
              <select
                className="select"
                onChange={this.inputHandler}
                value={this.state.nationality}
                name="nationality"
                noValidate
              >
                {countriesList}
              </select>
            </label>

            <label>
              Adresse
              <br />
              Straße und Hausnummer:
              <input
                onChange={this.inputHandler}
                value={this.state.streetAddress}
                type="text"
                name="streetAddress"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <br />

            <label>
              Postleitzahl
              <input
                onChange={this.inputHandler}
                value={this.state.postalCode}
                type="text"
                name="postalCode"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <br />

            <label>
              Stadt
              <input
                onChange={this.inputHandler}
                value={this.state.city}
                type="text"
                name="city"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <br />

            <label>
              Land:
              <select
                className="select"
                onChange={this.inputHandler}
                value={this.state.country}
                name="country"
                noValidate
              >
                {countriesList}
              </select>
            </label>

            <label>
              Ausstellungsdatum:
              <input
                onChange={this.inputHandler}
                value={this.state.dateIssued}
                type="text"
                name="dateIssued"
                noValidate
                placeholder="yyyy-mm-dd"
                required
              />
            </label>
            <br />

            <label>
              Ablaufdatum:
              <input
                onChange={this.inputHandler}
                value={this.state.dateOfExpiry}
                type="text"
                name="dateOfExpiry"
                noValidate
                placeholder="yyyy-mm-dd"
                required
              />
            </label>
            <br />

            <label>
              Behörde:
              <input
                onChange={this.inputHandler}
                value={this.state.authority}
                type="text"
                name="authority"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <br />

            <label>
              Ort der Ausstellung:
              <input
                onChange={this.inputHandler}
                value={this.state.placeOfIssue}
                type="text"
                name="placeOfIssue"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <br />

            <label>
              Land des Ausweisdokuments :
              <select
                className="select"
                onChange={this.inputHandler}
                value={this.state.countryOfDocument}
                name="countryOfDocument"
                noValidate
              >
                {countriesList}
              </select>
            </label>

            <button type="submit">Absenden</button>
          </form>
        </div>
      </>
    );
  }
}
