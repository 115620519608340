/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import axios from 'axios';
import countries from '../../../countries';

const initialState = {
  searchValue: '',
  externalId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  salutation: '',
  birthDate: '',
  birthPlace: '',
  birthCertificateNumber: '',
  birthCertificateIssuer: '',
  birthCertificateIssuerDate: '',
  birthCertificateCountryOfIssuer: 'DEU',
  birthCertificateCityOfIssuer: '',
  searchResult: {},
  errorFirstName: '',
  errorMiddleName: '',
  errorLastName: '',
  errorSalutation: '',
  errorbirthDate: '',
  errorbirthPlace: '',
  errorbirthCertificateNumber: '',
  errorbirthCertificateIssuer: '',
  errorbirthCertificateCityOfIssuer: '',
  errorbirthCertificateIssuerDate: '',
  errorbirthCertificateCountryOfIssuer: '',
  form_is_submitting: false,
  readOnly: false,
};

const regExDateFormat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

class GeburtsurkundeForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  // 1) input method for search field
  inputHandler = (e) => {
    if (e.target.name === 'searchValue') {
      this.resetForm();
      if (e.target.value.length >= 6) {
        this.searchUserExternalId(e.target.value);
      } else {
        this.setState((prevState) => ({
          searchResult: {
            ...prevState.searchResult,
            childFound: false,
            message: '',
          },
        }));
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  // 2) search and check the externalID if its a child
  searchUserExternalId = async (id) => {
    axios
      .post(`${window.quirion_rest}getuser`, { externalId: parseInt(id, 10) })
      .then((res) => this.setState({ searchResult: this.isChildExternalId(res.data) }))
      .catch((error) => {
        console.log(error);
        this.setState({
          searchResult: {
            childFound: false,
            message: `Nutzer mit External ID: ${id} braucht keine Geburtsurkunde oder existiert nicht.`,
          },
        });
      });
  }

  // 3) check if externalID === childExternalId
  isChildExternalId = (data) => {
    const childFound = false;
    if (data.user.externalId) {
      if (data.cases && data.cases.length > 0) {
        for (let i = 0; i < data.cases.length; i++) {
          if (data.cases[i].childExternalId === data.user.externalId) {
            // check if the child certificate was already submited
            if (
              data.cases[i].caseStatus
              && data.cases[i].caseStatus.childIdent
            ) {
              this.setState({
                salutation: data.user.salutation,
                externalId: data.user.externalId,
                firstName: data.user.firstName,
                lastName: data.user.lastName,
                gender: data.user.gender,
                birthDate: data.user.birthDate,
                birthPlace: data.user.birthPlace,
                birthCertificateNumber: data.user.passportNumber,
                birthCertificateIssuer: data.user.authority,
                birthCertificateIssuerDate: data.user.dateIssued,
                birthCertificateCountryOfIssuer: data.user.countryOfDocument,
                birthCertificateCityOfIssuer: data.user.cityOfDocument,
                readOnly: true,
              });
              return {
                childFound: true,
                message: `Der Benutzer mit externer ID: ${data.user.externalId} hat die Geburtsurkunde bereits eingereicht.`,
              };
            }
            // populating the form
            this.setState({
              salutation: data.cases[i].childSalutation,
              externalId: data.cases[i].childExternalId,
              firstName: data.cases[i].childFirstName,
              lastName: data.cases[i].childLastName,
              gender: data.cases[i].childGender,
            });
            return {
              childFound: true,
              message: `The User with ${data.user.externalId} have been found`,
            };
          }
        }
        return {
          childFound,
          message: `Der Benutzer mit externer ID: ${data.user.externalId} ist kein Kinderkonto oder benötigt keine Geburtsurkunde.`,
        };
      } else {
        return {
          childFound,
          message: `Der Benutzer mit externer ID: ${data.user.externalId} ist kein Kinderkonto oder benötigt keine Geburtsurkunde.`,
        };
      }
    } else {
      return {
        childFound,
        message: `Der Benutzer mit externer ID: ${data.user.externalId} existiert nicht.`,
      };
    }
  };

  resetForm = () => this.setState(initialState);


  // event handler for radio buttons
  handleOptionChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (this.state.gender === 'female') this.setState({ salutation: 'Herr' });
    else this.setState({ salutation: 'Frau' });
  };

  checkForm = (event) => {
    event.preventDefault();

    let errorExists = false;

    if (this.state.firstName.length < 3) {
      this.setState({ errorFirstName: 'Der Vorname muss mindestens 3 Buchstaben lang sein' });
      errorExists = true;
    } else this.setState({ errorFirstName: '' });

    if (this.state.lastName.length < 3) {
      this.setState({ errorLastName: 'Der Nachname muss mindestens 3 Buchstaben lang sein' });
      errorExists = true;
    } else this.setState({ errorLastName: '' });

    if (this.state.salutation.length <= 3) {
      this.setState({ errorSalutation: 'Die Anrede muss mindestens drei Buchstaben lang sein' });
      errorExists = true;
    } else this.setState({ errorSalutation: '' });

    if (regExDateFormat.test(this.state.birthDate) === false) {
      this.setState({
        errorbirthDate: 'Das Geburtsdatum muss das folgende Format haben: JJJJ-MM-TT',
      });
      errorExists = true;
    } else this.setState({ errorbirthDate: '' });

    if (this.state.birthPlace.length < 3) {
      this.setState({ errorbirthPlace: 'Der Geburtsort muss mindestens 3 Buchstaben lang sein' });
      errorExists = true;
    } else this.setState({ errorbirthPlace: '' });

    if (this.state.birthCertificateNumber.length < 3) {
      this.setState({
        errorbirthCertificateNumber:
          'Die Nummer der Geburtsurkunde muss mindestens 3 Zeichen lang sein',
      });
      errorExists = true;
    } else this.setState({ errorbirthCertificateNumber: '' });

    if (this.state.birthCertificateIssuer.length < 3) {
      this.setState({
        errorbirthCertificateIssuer: 'Ausstellende Behörde muss mindestens 3 Buchstaben lang sein',
      });
      errorExists = true;
    } else this.setState({ errorbirthCertificateIssuer: '' });

    if (this.state.birthCertificateCityOfIssuer.length < 3) {
      this.setState({
        errorbirthCertificateCityOfIssuer:
          'Ort der Ausstellung muss mindestens 3 Buchstaben lang sein',
      });
      errorExists = true;
    } else this.setState({ errorbirthCertificateCityOfIssuer: '' });

    if (regExDateFormat.test(this.state.birthCertificateIssuerDate) === false) {
      this.setState({
        errorbirthCertificateIssuerDate:
          'Datum der Ausstellung muss das folgende Format haben: JJJJ-MM-TT',
      });
      errorExists = true;
    } else this.setState({ errorbirthCertificateIssuerDate: '' });

    if (this.state.birthCertificateCountryOfIssuer.length < 3) {
      this.setState({
        errorbirthCertificateCountryOfIssuer:
          'Land der Behörde muss mindestens 3 Buchstaben lang sein',
      });
      errorExists = true;
    } else this.setState({ errorbirthCertificateCountryOfIssuer: '' });

    if (errorExists) console.log('do nothing');
    else {
      console.log('jsonaction');
      if (this.state.form_is_submitting === false) {
        this.setState({ form_is_submitting: true });
        this.sendBirthCertificate();
      }
    }
  };

  async sendBirthCertificate() {
    const {
      externalId, firstName, middleName, lastName, gender, salutation, birthDate,
      birthPlace, birthCertificateNumber, birthCertificateCountryOfIssuer,
      birthCertificateIssuerDate, birthCertificateCityOfIssuer, birthCertificateIssuer,
    } = this.state;
    const data = {
      externalId,
      firstName,
      middleName,
      lastName,
      gender,
      salutation,
      birthDate,
      birthPlace,
      birthCertificateNumber,
      birthCertificateIssuer,
      birthCertificateIssuerDate,
      birthCertificateCountryOfIssuer,
      birthCertificateCityOfIssuer,
    };

    axios
      .post(`${window.quirion_rest}birthcertificate`, data)
      .then((res) => {
        alert('Vielen Dank!');
        this.resetForm();
        location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert('The submission went wrong');
        location.reload();
      });
  }

  // 4) render the form or message
  render() {
    const {
      readOnly, searchResult, searchValue, externalId, firstName, middleName,
      errorFirstName, lastName, errorLastName, gender, salutation, errorMiddleName,
      errorSalutation, birthDate, errorbirthDate, birthPlace, errorbirthPlace,
      birthCertificateIssuer, errorbirthCertificateIssuer, birthCertificateCityOfIssuer,
      errorbirthCertificateCityOfIssuer, birthCertificateCountryOfIssuer,
      errorbirthCertificateCountryOfIssuer, birthCertificateIssuerDate,
      errorbirthCertificateIssuerDate, birthCertificateNumber, errorbirthCertificateNumber,
    } = this.state;
    // let externalIdFound = this.state.searchResult.childFound;

    // create dropdown elements for the countries
    const countriesList = countries.map((element) => (
      <option key={Object.values(element)[0]} value={Object.keys(element)[0]}>
        {Object.values(element)[0]}
      </option>
    ));

    // render form
    return (
      <>
        <div className="forms form2">
          <h2>Geburtsurkunde</h2>
          {readOnly === true
            && (
            <div style={{ marginBottom: '20px' }}>
              <b>
                {searchResult?.message}
              </b>
            </div>
            )}
          <form onSubmit={this.checkForm} noValidate>
            <label>
              Suchen (External Id):
              <input
                onChange={this.inputHandler}
                value={searchValue}
                type="text"
                name="searchValue"
                noValidate
                placeholder=" "
                required
              />
            </label>
            {searchResult?.childFound ? (
              <div>
                <label>
                  {`ExternalId: ${externalId}`}
                </label>
                <br />
                <label>
                  Vorname:
                  <input
                    onChange={this.inputHandler}
                    value={firstName}
                    disabled={readOnly}
                    type="text"
                    name="firstName"
                    noValidate
                    placeholder=" "
                    required
                  />
                </label>
                <div className="errorMessage">{errorFirstName}</div>
                <label>
                  weitere Vornamen:
                  <input
                    onChange={this.inputHandler}
                    value={middleName}
                    disabled={readOnly}
                    type="text"
                    name="middleName"
                    noValidate
                    placeholder=" "
                  />
                </label>
                <div className="errorMessage">{errorMiddleName}</div>
                <label>
                  Nachname:
                  <input
                    onChange={this.inputHandler}
                    value={lastName}
                    type="text"
                    name="lastName"
                    noValidate
                    placeholder=" "
                    required
                    disabled={readOnly}
                  />
                </label>
                <div className="errorMessage">{errorLastName}</div>
                <label>
                  Geschlecht:
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    checked={gender === 'male'}
                    onChange={this.handleOptionChange}
                    className="form-check-input"
                    noValidate
                    disabled={readOnly}
                  />
                  männlich
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    checked={gender === 'female'}
                    onChange={this.handleOptionChange}
                    className="form-check-input"
                    noValidate
                    disabled={readOnly}
                  />
                  weiblich
                </label>
                <br />
                <label>
                  Anrede:
                  <input
                    onChange={this.inputHandler}
                    value={salutation}
                    type="text"
                    name="salutation"
                    noValidate
                    placeholder=" "
                    required
                    disabled={readOnly}
                  />
                </label>
                <div className="errorMessage">{errorSalutation}</div>
                <label>
                  Geburtsdatum:
                  <input
                    onChange={this.inputHandler}
                    value={birthDate}
                    type="text"
                    name="birthDate"
                    placeholder="JJJJ-MM-TT"
                    noValidate
                    required
                    disabled={readOnly}
                  />
                </label>
                <div className="errorMessage">{errorbirthDate}</div>
                <label>
                  Geburtsort
                  <input
                    onChange={this.inputHandler}
                    value={birthPlace}
                    type="text"
                    name="birthPlace"
                    noValidate
                    placeholder=" "
                    required
                    disabled={readOnly}
                  />
                </label>
                <div className="errorMessage">{errorbirthPlace}</div>
                <label>
                  Geburtsurkunde Nummer:
                  <input
                    onChange={this.inputHandler}
                    value={birthCertificateNumber}
                    type="text"
                    name="birthCertificateNumber"
                    noValidate
                    placeholder=" "
                    required
                    disabled={readOnly}
                  />
                </label>
                <div className="errorMessage">
                  {errorbirthCertificateNumber}
                </div>
                <label>
                  Ausstellende Behörde:
                  <input
                    onChange={this.inputHandler}
                    value={birthCertificateIssuer}
                    type="text"
                    name="birthCertificateIssuer"
                    noValidate
                    placeholder=" "
                    required
                    disabled={readOnly}
                  />
                </label>
                <div className="errorMessage">
                  {errorbirthCertificateIssuer}
                </div>
                <label>
                  Ort der Ausstellung:
                  <input
                    onChange={this.inputHandler}
                    value={birthCertificateCityOfIssuer}
                    type="text"
                    name="birthCertificateCityOfIssuer"
                    noValidate
                    placeholder=" "
                    required
                    disabled={readOnly}
                  />
                </label>
                <div className="errorMessage">
                  {errorbirthCertificateCityOfIssuer}
                </div>
                <label>
                  Datum der Ausstellung:
                  <input
                    onChange={this.inputHandler}
                    value={birthCertificateIssuerDate}
                    type="text"
                    name="birthCertificateIssuerDate"
                    placeholder="JJJJ-MM-TT"
                    noValidate
                    required
                    disabled={readOnly}
                  />
                </label>
                <div className="errorMessage">
                  {errorbirthCertificateIssuerDate}
                </div>
                <label>
                  Land der Behörde:
                  <select
                    className="select"
                    onChange={this.inputHandler}
                    value={birthCertificateCountryOfIssuer}
                    name="birthCertificateCountryOfIssuer"
                    noValidate
                    disabled={readOnly}
                  >
                    {countriesList}
                  </select>
                </label>
                <div className="errorMessage">
                  {errorbirthCertificateCountryOfIssuer}
                </div>
                <button
                  type="submit"
                  disabled={readOnly}
                >
                  Absenden
                </button>
              </div>
            ) : (
              <div>
                {` ${searchResult?.message} `}
              </div>
            )}
          </form>
        </div>
      </>
    );
  }
}

export default GeburtsurkundeForm;
