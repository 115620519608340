import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

export default class SearchInput extends Component {
  constructor(props) {
    super();
    this.state = {
      errorMessage: '',
      searchValue: '***',
      form_is_submitting: false,
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
      ranges: {
        //        'Heute': [moment(), moment()],
        //        'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '3 Tage': [moment().subtract(2, 'days'), moment()],
        '30 Tage': [moment().subtract(29, 'days'), moment()],
        '90 Tage': [moment().subtract(89, 'days'), moment()],
        //        'Aktueller Monat': [moment().startOf('month'), moment().endOf('month')],
        //        'Letzer Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };
  }

  checkSubmitHandler = (event) => {
    event.preventDefault();
    let errorExists = false;
    let input = this.state.searchValue.trim();
    if (input.length < 3) {
      this.setState({ errorMessage: 'Bitte mindestens 3 Zeichen eingeben!' });
      errorExists = true;
    } else {
      this.setState({ errorMessage: '' });
    }

    if (!errorExists && !this.state.form_is_submitting) {
      this.props.onSearch(input, [this.state.startDate, this.state.endDate.endOf('day')]);
    }
  };

  handleEvent = (event, picker) => {
    //    console.log(event.type, event.constructor.name)
    if (event.type === 'apply') { // picker
      this.setState({ startDate: picker.startDate });
      this.setState({ endDate: picker.endDate });
    }
    else if (event.type === 'change' && (event.constructor.name === 'SyntheticEvent' || event.constructor.name === '_t')) { // input
      // event.constructor.name changes in environment?
      // local === SyntheticEvent
      // prod  === _t
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  getSelectedDateRange() {
    let rangeSelected = null;
    const { startDate, endDate, ranges } = this.state;
    for (let [key] of Object.entries(ranges)) {
      if (ranges[key][0].startOf('day').isSame(startDate.startOf('day'))
        && ranges[key][1].startOf('day').isSame(endDate.startOf('day'))
      ) {
        rangeSelected = key;
      }
    }

    if (rangeSelected) {
      return rangeSelected;
    }
    return `${startDate.format('D.MM.YYYY')} - ${endDate.format('D.MM.YYYY')}`;
  }

  render() {
    return (
      <div className="SearchInput">
        <form className="form3flex" onSubmit={this.checkSubmitHandler}>
          <div>
            <label style={{ marginLeft: '15px', display: 'block' }}>
              <span>Name / QuirionId:</span>
              <input
                className="inputform3"
                type="text"
                name="searchValue"
                onChange={this.handleEvent}
                value={this.state.searchValue}
              />
              <div className="errorMessage">{this.state.errorMessage}</div>
            </label>
            <label style={{ marginLeft: '15px' }}>
              <span>Zeitraum:</span>
              <DateRangePicker startDate={this.state.startDate} endDate={this.state.endDate} onEvent={this.handleEvent} ranges={this.state.ranges} showDropdowns>
                {this.getSelectedDateRange()}
              </DateRangePicker>
            </label>
          </div>
          <div>
            <button className="form3button" type="submit">
              Absenden
              {this.state.form_is_submitting && <div>Loading</div>}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
