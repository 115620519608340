import axios from 'axios';
import awsConfigDev from '../../data/aws-exports-dev';
import awsConfigTest from '../../data/aws-exports-test';
import awsConfigProd from '../../data/aws-exports-prod';

const helperGlobal = {

  getRestApiUrl() {
    let apiUrl = false;

    let awsConfig = awsConfigDev;

    if (process.env.NODE_ENV && process.env.REACT_APP_BRANCH === 'production') {
      awsConfig = awsConfigProd;
    } else if (
      process.env.NODE_ENV
      && process.env.REACT_APP_BRANCH === 'test'
    ) {
      awsConfig = awsConfigTest;
    }

    if (awsConfig.aws_cloud_logic_custom[0]) {
      apiUrl = `${awsConfig.aws_cloud_logic_custom[0].endpoint}/`;
    }
    return apiUrl;
  },

  getEnv() {
    let env = 'dev';
    if (process.env.NODE_ENV && process.env.REACT_APP_BRANCH === 'production') {
      env = 'prod';
    } else if (process.env.NODE_ENV && process.env.REACT_APP_BRANCH === 'test') {
      env = 'test';
    }
    return env;
  },

  async downloadDocument(key, name) {
    axios
      .post(`${window.quirion_rest}gets3document`, { 'key': key })
      .then((response) => {
        const linkSource = `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement('a');
        const fileName = name;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        console.log(
          `Download error for document: ${name}, Error: ${error}`,
        );
      });
  },

};

export default helperGlobal;
