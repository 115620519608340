import React, { Component } from 'react';
import axios from 'axios';
import { isValidIban } from './utils';

export default class ChangeBankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountHolder: '',
      iban: '',
      bic: '',
      bank: '',
      erroraccountHolder: '',
      erroriban: '',
      ibanFormatValid: false,
      formIsSubmitting: false,
      bicFound: false,
      bankNameFound: false,
    };
  }

  inputHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => this.checkForm(event));
  };

  resetBankValues = () => {
    this.setState({
      iban: '',
      bank: '',
      bic: '',
      ibanFormatValid: false,
      erroriban: '',
      errorbank: '',
      errorbic: '',
    });
  }

  checkForm = (event) => {
    const { accountHolder, ibanFormatValid } = this.state;
    if (event) event.preventDefault();
    this.setState({
      erroriban: !ibanFormatValid ? 'Bitte korrektes IBAN-Format eingeben' : '',
      erroraccountHolder: accountHolder.length < 3
        ? 'Bitte geben Sie den Namen des Kontoinhabers an'
        : '',
    });
  }

  async checkIban(iban) {
    this.resetBankValues();
    const ibanWithoutSpaces = iban.replace(/\s/g, '');
    this.setState({
      iban: ibanWithoutSpaces,
      ibanFormatValid: isValidIban(ibanWithoutSpaces),
    }, async () => {
      const { ibanFormatValid } = this.state;
      if (ibanFormatValid) {
        this.setState({ formIsSubmitting: true });
        const response = await axios.get(
          `https://openiban.com/validate/${ibanWithoutSpaces}?getBIC=true&validateBankCode=true`,
        );

        if (response?.data) {
          const { data } = response;
          if (data?.checkResults?.bankCode === true) {
            if (data?.bankData?.bic) {
              this.setState({
                bic: data.bankData.bic,
                bicFound: true,
              });
            } else this.setState({ bicFound: false });
            if (data?.bankData?.name) {
              this.setState({
                bank: data.bankData.name,
                bankNameFound: true,
              });
            } else this.setState({ bankNameFound: false });
          } else {
            this.setState({
              bicFound: false,
              bankNameFound: false,
            });
          }
        }
        this.setState({ formIsSubmitting: false });
      }
      this.checkForm();
    });
  }

  async addNewAccountNumber() {
    this.setState({ formIsSubmitting: true });
    const {
      iban, bic, bank, accountHolder,
    } = this.state;
    const { businessPartnerId } = this.props;
    const inputData = {
      'businessPartnerId': parseInt(businessPartnerId, 10),
      'iban': iban,
      'bic': bic,
      'bankname': bank,
      'accountHolder': accountHolder,
    };

    await axios
      .post(`${window.quirion_rest}updatereferenceaccount`, inputData)
      .then((res) => {
        console.log(res, 'res');
        alert('Referenzkonto aktualisiert!');
        this.props.onChange();
        this.props.onCancel();
      })
      .catch((error) => {
        console.log(error);
        alert('Etwas ging schief!');
      });
    this.setState({ formIsSubmitting: false });
  }

  render() {
    const {
      iban,
      bank,
      bic,
      accountHolder,
      erroraccountHolder,
      erroriban,
      errorbic,
      errorbank,
      bicFound,
      bankNameFound,
      formIsSubmitting,
    } = this.state;
    return (
      <>
        <div className={`forms formChangeAccount  ${formIsSubmitting ? ' -loading' : ''}`}>
          <h2>Referenzkonto ändern</h2>
          <form noValidate>
            <label>
              <span>Kontoinhaber</span>
              <input
                onChange={this.inputHandler}
                value={accountHolder}
                type="text"
                name="accountHolder"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <div className="errorMessage">{erroraccountHolder}</div>
            <label>
              <span>IBAN</span>
              <input
                onChange={(e) => this.checkIban(e.target.value)}
                value={iban}
                type="text"
                name="iban"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <div className="errorMessage">{erroriban}</div>
            <label>
              <span>Bic</span>
              <input
                onChange={this.inputHandler}
                disabled={bicFound}
                value={bic}
                type="text"
                name="bic"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <div className="errorMessage">{errorbic}</div>
            <label>
              <span>Name der Bank</span>
              <input
                onChange={this.inputHandler}
                disabled={bankNameFound}
                value={bank}
                type="text"
                name="bank"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <div className="errorMessage">{errorbank}</div>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <button
                type="button"
                disabled={!bic || !bank || !iban || (accountHolder.length < 3)}
                onClick={() => this.addNewAccountNumber()}
              >
                Absenden
              </button>
              <button
                type="button"
                onClick={this.props.onCancel}
              >
                Abbrechen
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
