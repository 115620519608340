import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import helperGlobal from '../../../../../utils/helper_global';
import ModalWrapper from '../../../../../components/ModalWrapper';
import './style.scss';

export default class RegistrationProtocol extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getProtocolList = () => {
    const { protocols } = this.props;
    return protocols.length > 0
      ? (protocols.map((protocol, i) => (
        <div
          className="RegistrationProtocol-Item"
          role="button"
          key={`regDocs_${i + 1}`}
          onClick={() => helperGlobal.downloadDocument(protocol.filename, protocol.displayName)}
          onKeyPress={() => helperGlobal.downloadDocument(protocol.filename, protocol.displayName)}
          aria-hidden
        >
          <FontAwesomeIcon icon="file-alt" className="RegistrationProtocol-Item__icon" />
          <span
            className="RegistrationProtocol-Item__displayName"
          >
            {protocol.displayName}
          </span>
          <span
            className="RegistrationProtocol-Item__status"
          >
            {protocol.status}
          </span>
          <span
            className="RegistrationProtocol-Item__createdAt"
          >
            {moment(protocol.createdAt).format('YYYY-MM-DD hh:mm')}
          </span>
          <span
            className="RegistrationProtocol-Item__updatedAt"
          >
            {moment(protocol.updatedAt).format('YYYY-MM-DD hh:mm')}
          </span>
        </div>
      )))
      : (
        <div>Für diesen BP sind keine Dateien verfügbar...</div>
      );
  }

  body = () => (
    <>
      <h3>Protokole:</h3>
      <div className="RegistrationProtocol-List">
        {this.getProtocolList()}
      </div>
    </>
  )

  render() {
    const { trigger, show } = this.props;
    return (
      <ModalWrapper
        name="RegistrationProtocol"
        headerContent={<span>Registrierungsprotokol</span>}
        bodyContent={this.body()}
        confirmButton={() => trigger()}
        confirmButtonTitle="schließen"
        triggerModal={() => trigger()}
        showModal={show}
      />
    );
  }
}
