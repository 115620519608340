import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Grid } from 'gridjs-react';
import 'gridjs/dist/theme/mermaid.css';

const typeTexts = {
  TOPUP: 'Aufstockung',
  WITHDRAWAL: 'Entnahme',
  DISBURSEMENT: 'Auszahlung',
  STRATEGYCHANGE: 'Strategiewechsel',
  DELETESTRATEGY: 'Strategieauflösung',
  FIRST: 'Eröffnung',
  CHANGEPERSON: 'Persönliche Angaben',
  DELETEBP: 'Kontoauflösung',
  CHANGEPACKAGE: 'Paketwechsel',
};

const statusTexts = {
  NOTASSIGNED: 'Warten auf Geldeingang',
  RUNNING: 'Warten auf Geldeingang',
  OPEN: 'offen',
  CLOSED: 'ausgeführt',
  PENDING: 'vorgemerkt',
};

export default class BusinessPartnerOrders extends Component {
  constructor(props) {
    super();
    this.state = {
      BPHistoryDetails: [],
      isLoading: false,
    };
  }



  componentDidMount() {
    const { bp } = this.props;
    if (bp && bp.businessPartnerId) this.loadOrderHistoryBusinessPartner(bp.businessPartnerId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { bp } = this.props;
    if (bp.businessPartnerId !== nextProps.bp.businessPartnerId) {
      this.loadOrderHistoryBusinessPartner(nextProps.bp.businessPartnerId);
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { bp, lookupDays } = this.props;
    if (prevProps.lookupDays !== lookupDays) {
      this.loadOrderHistoryBusinessPartner(bp.businessPartnerId);
    }
  }

  getTypeName(orderId) {
    const orderType = orderId.split('#')[1];
    return typeTexts[orderType] || orderType;
  }

  async loadOrderHistoryBusinessPartner(id) {
    const { person, lookupDays } = this.props;
    this.setState({ isLoading: true });
    await axios.post(
      `${window.quirion_rest}getorder`,
      {
        'username': person.user.username,
        'businessPartner': true,
        'businessPartnerId': id,
        'ips': false,
        'range': parseInt(lookupDays, 10),
        'status': 'ALL',
      }
    )
      .then((response) => {
        this.setState({ isLoading: false });
        const currentBPOrders = response.data.businessPartner;
        if (currentBPOrders) this.setState({ BPHistoryDetails: currentBPOrders });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let formatedTableData = [];
    const { BPHistoryDetails, isLoading } = this.state;
    const { person, bp } = this.props;
    if (BPHistoryDetails) {
      formatedTableData = BPHistoryDetails.map((item) => ({
        ...item,
        createdAt: moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss'),
        orderId: this.getTypeName(item.orderId),
        amount: item.amount
          ? Number(item.amount).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
          : '',
        externalId: person.user.externalId,
        communicationEmail: person.user.communicationEmail,
        accountType: bp.accountType,
      }));
    }

    return (
      <div className={`orderHistory__content ${isLoading ? ' -loading' : ''}`}>
        <h3>Business Partner Orders</h3>
        <Grid
          data={formatedTableData}
          columns={[
            { id: 'createdAt', name: 'Datum und Uhrzeit' },
            { id: 'orderId', name: 'Auftragstyp' },
            { id: 'status', name: 'Status' },
            { id: 'amount', name: 'Höhe des Auftrags' },
            { id: 'businessPartnerId', name: 'BP Nummer' },
            { id: 'externalId', name: 'Quirion ID' },
            { id: 'communicationEmail', name: 'E-mail' },
            { id: 'accountType', name: 'Konto Typ' },
          ]}
          className={{
            container: 'table',
          }}
          search
          sort
          pagination={{
            enabled: true,
            limit: 30,
          }}
          language={{
            'search': {
              'placeholder': 'Suchen...',
            },
            'pagination': {
              'previous': 'zurück',
              'next': 'weiter',
              'showing': 'Zeigen',
              'results': () => 'Ergebnisse',
              'to': 'bis',
              'of': 'von',
            },
          }}
          autoWidth
        />
      </div>
    );
  }
}
