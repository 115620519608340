export default {
  dev: {
    birthCertificate: { bucketName: 'birthCertificate-dev' },
  },
  test: {
    birthCertificate: { bucketName: 'birthCertificate-test' },
  },
  prod: {
    birthCertificate: { bucketName: 'birthCertificate-prod' },
  },
};
