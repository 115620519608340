import React, { Component } from 'react';
import axios from 'axios';
import SearchInput from '../SearchInput';
import PostidentsList from '../PostidentsList';
import helperGlobal from '../../../../utils/helper_global';

export default class Search extends Component {
  constructor(props) {
    super();
    this.state = {
      postidents: {},
      form_is_submitting: false,
    };
  }

  async doSearch(searchValue, momentsRange) {
    let startDate = null;
    if (momentsRange[0].toISOString()) {
      startDate = momentsRange[0].toISOString();
    }
    let endDate = null;
    if (momentsRange[1].toISOString()) {
      endDate = momentsRange[1].toISOString();
    }

    this.setState({ form_is_submitting: true });
    const res = await axios.post(
      `${helperGlobal.getRestApiUrl()}ident-es-query`,
      { searchQuery: searchValue, startDate, endDate },
    );

    if (res.status === 200 && res.data && res.data.body && res.data.body.hits) {
      this.setState({ postidents: res.data.body.hits });
    }
    else {
      alert('Das hat nicht geklappt, bitte die IT informieren!');
    }
    this.setState({ form_is_submitting: false });
  }

  render() {
    return (
      <div>
        <SearchInput
          onSearch={
            (searchValue, dateRange) => {
              this.doSearch(searchValue, dateRange);
            }
          }
        />
        { this.state.form_is_submitting
          ? 'Lade...'
          : <PostidentsList postidents={this.state.postidents} />
        }
      </div>
    );
  }
}
