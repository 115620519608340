import React, { Component } from 'react';
// import { API, graphqlOperation } from 'aws-amplify';
// import * as queries_own from '../../../../../graphql/queries_own';
import axios from 'axios';

import SearchInput from '../SearchInput';
import SearchResults from '../SearchResults';
import SearchDetails from '../SearchDetails';

import DetailsPerson from '../DetailsPerson';
import DetailsSavingsPlans from '../DetailsSavingsPlans';
import DetailsProducts from '../DetailsProducts';
import DetailsCases from '../DetailsCases';
import DetailsPassword from '../DetailsPassword';
import DetailsDeleteRegistration from '../DetailsDeleteRegistration';
import OrderHistory from '../OrderHistory';
import { VoucherHistory } from '../VoucherHistory';
import { CouponHistory } from '../CouponHistory';
import DetailsGeburtsurkunde from '../DetailsGeburtsurkunde';

import TabNavigation from '../TabNavigation';

export default class Search extends Component {
  constructor(props) {
    super();
    this.state = {
      activeTab: 1,
      selectedPerson: null,
      is_submitting: false,
      response: '',
      listBps: [],
      responsePersons: '',
      listPersons: [],
      listPersonsStatusInfo: '',
      isChildCase: false,
    };
  }

  async getBp(id, fallbackToSearch = false) {
    this.setState({ is_submitting: true });
    this.setState({ responsePersons: '' });
    axios
      .post(
        `${window.quirion_rest}getbusinesspartner`,
        {
          'businessPartnerId': parseInt(id, 10),
        },
      )
      .then((response) => {
        this.setState({
          activeTab: 1,
          is_submitting: false,
        });
        this.setState({
          response: JSON.stringify(response),
          listPersons: response.data.users,
        });
      })
      .catch((error) => {
        console.error(error);
        if (fallbackToSearch) {
          this.doSearch(id);
        }
      });
  }

  // called by this.getBp
  async getPersonById(id) {
    this.setState({
      response: '',
      listPersonsStatusInfo: 'Laden...',
      isChildCase: false,
    });

    axios
      .post(
        `${window.quirion_rest}getuser`,
        { 'externalId': parseInt(id) },
      )
      .then((response) => {
        this.setState({
          response: JSON.stringify(response),
          listPersonsStatusInfo: '',
        });
        this.findChildCases(response.data.cases);
      })
      .catch((error) => {
        this.setState({ listPersonsStatusInfo: 'Fehler!' });
        console.log('error');
      });
  }

  doSearch = (searchQuery) => {
    if (!searchQuery) return;
    this.setState({ is_submitting: true });
    axios
      .post(
        `${window.quirion_rest}search`,
        { searchQuery },
      )
      .then((response) => {
        this.setState({
          is_submitting: false,
          listPersons: response.data.result,
          selectedPerson: null,
          activeTab: 1,
        });
      })
      .catch((error) => {
        this.setState({ is_submitting: false });
        alert(JSON.stringify(error));
        console.log('error');
      });
  }

  setInitialSearch = () => {
    this.setState({
      is_submitting: false,
      selectedPerson: null,
      listPersons: [],
      activeTab: 1,
      isChildCase: false,
    });
  }

  findChildCases = cases => {
    if (cases && cases.length > 0) {
      if (cases.some(item => /child/.test(item.accountType))) {
        this.setState({ isChildCase: true });
      }
    }
  }

  capitalize(arg) {
    if (typeof arg !== 'string') return '';
    return arg.charAt(0).toUpperCase() + arg.slice(1);
  }

  render() {
    const {
      activeTab,
      isChildCase,
      listPersons,
      response,
      is_submitting,
      selectedPerson,
      listPersonsStatusInfo,
    } = this.state;
    const res = response ? JSON.parse(response) : null;
    const person = res ? res.data : {};

    return (
      <div
        className={`Search${is_submitting ? ' -loading' : ''}`}
        style={{
          minHeight: '400px',
        }}
      >
        <SearchInput
          onSearch={(searchValue) => {
            this.setState({
              selectedPerson: null,
              activeTab: 1,
            });

            if (searchValue.length >= 8 && parseInt(searchValue, 10)) {
              // if searchValue is businessPartnerId then do getBp
              this.getBp(searchValue, true);
            } else if (searchValue.length) {
              this.doSearch(searchValue);
            }
          }}
        />

        {!selectedPerson && (
          <SearchResults
            listPersons={listPersons}
            onPersonSelected={(personId) => {
              this.setState({ selectedPerson: personId });
              this.getPersonById(personId);
            }}
          />
        )}

        {selectedPerson && (
          <SearchDetails>
            <TabNavigation
              activeTab={activeTab}
              isChildCase={isChildCase}
              onTabSelect={(tabNumber) => {
                this.setState({ activeTab: tabNumber });
              }}
            />
            {activeTab === 1
              && (
                <DetailsPerson
                  person={person}
                  personStatusInfo={listPersonsStatusInfo}
                  onPersonUpdated={(personId) => this.getPersonById(personId)}
                  onPersonDeleted={this.setInitialSearch}
                />
              )}
            {activeTab === 2 && (<DetailsSavingsPlans person={person} />)}
            {activeTab === 3 && <DetailsProducts person={person} />}
            {activeTab === 4 && <DetailsPassword person={person} />}
            {activeTab === 5 && (
              <DetailsDeleteRegistration person={person} />
            )}
            {activeTab === 6 && <OrderHistory person={person} />}
            {activeTab === 7 && (
              <DetailsCases
                person={person}
                doSearch={this.doSearch}
                reloadPerson={(id) => this.getPersonById(id)}
              />
            )}
            {(activeTab === 8)
              && (
                <DetailsGeburtsurkunde
                  person={person}
                  reloadCase={(id) => this.getPersonById(id)}
                />
              )}
            {activeTab === 9 && (
              <VoucherHistory
                person={person}
                doSearch={this.doSearch}
                reloadPerson={(id) => this.getPersonById(id)}
              />
            )}
            {activeTab === 10 && (
              <CouponHistory
                person={person}
                doSearch={this.doSearch}
                reloadPerson={(id) => this.getPersonById(id)}
              />
            )}
          </SearchDetails>
        )}
      </div>
    );
  }
}
