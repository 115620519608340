import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Toolbar.css';
import '../SideDrawer/DrawerToggleButton';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import Navigation from '../Navigation/Navigation';
import logo from '../../../assets/QON_Logo_QPB_negativ_160.png'
import classnames from 'classnames';

export default class Toolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  render() {
    return (
      <React.Fragment>
        {/* <header className="toolbar"> */}
        <header
          className={classnames('toolbar', {
            'toolbar--hidden': !this.state.visible,
          })}
        >
          <nav className="toolbarNavigation">
            {/* <nav
            className={classnames('toolbarNavigation', {
              'toolbarNavigation--hidden': !this.state.visible,
            })}
          > */}
            {/* hamburger icon */}
            {/* <div>
              <DrawerToggleButton click={this.props.drawerClickHandler} />
            </div> */}
            {/* clickable logo */}
            <img alt="Quirion Kundenbetreuung Logo" src={logo}></img>
            <a className="toolbarLogo" href="/"> Kundenbetreuung</a>
            <div className="spacer" />
            <div className="toolbarNavigationItems">
              {/* <Navigation /> */}
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}
