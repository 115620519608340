import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Grid } from 'gridjs-react';
import { num } from '@quirion/utils';
import 'gridjs/dist/theme/mermaid.css';

const typeTexts = {
  TOPUP: 'Aufstockung',
  WITHDRAWAL: 'Entnahme',
  DISBURSEMENT: 'Auszahlung',
  STRATEGYCHANGE: 'Strategiewechsel',
  DELETESTRATEGY: 'Strategieauflösung',
  FIRST: 'Eröffnung',
  CHANGEPERSON: 'Persönliche Angaben',
  DELETEBP: 'Kontoauflösung',
  CHANGEPACKAGE: 'Paketwechsel',
};

const statusTexts = {
  NOTASSIGNED: 'Warten auf Geldeingang',
  RUNNING: 'Warten auf Geldeingang',
  OPEN: 'offen',
  CLOSED: 'ausgeführt',
  PENDING: 'vorgemerkt',
};

export default class AssetOrders extends Component {
  constructor(props) {
    super();
    this.state = {
      OrderHistoryDetails: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { asset } = this.props;
    if (asset && asset.assetId) {
      this.loadOrderHistoryAssets(asset.assetId);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { asset } = this.props;
    if (asset.assetId !== nextProps.asset.assetId) {
      this.loadOrderHistoryAssets(nextProps.asset.assetId);
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { lookupDays, asset } = this.props;
    if (prevProps.lookupDays !== lookupDays) {
      this.loadOrderHistoryAssets(asset.assetId);
    }
  }

  static getTypeName(orderId) {
    const orderType = orderId.split('#')[2];
    return typeTexts[orderType] || orderType;
  }

  async loadOrderHistoryAssets(id) {
    this.setState({ isLoading: true });
    const { person, lookupDays } = this.props;
    const formatedId = id.replace('ASSET#', '');
    await axios.post(
      `${window.quirion_rest}getorder`,
      {
        'username': person.user.username,
        'businessPartner': false,
        'asset': true,
        'assetId': formatedId,
        'ips': false,
        'range': parseInt(lookupDays, 10),
        'status': 'ALL',
      },
    )
      .then((response) => {
        this.setState({ isLoading: false });
        const currentAssetOrders = response.data.assets;
        if (currentAssetOrders) {
          this.setState({
            OrderHistoryDetails: currentAssetOrders,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { OrderHistoryDetails, isLoading } = this.state;
    let formatedTableData = [];
    if (OrderHistoryDetails) {
      formatedTableData = OrderHistoryDetails.map((item) => ({
        ...item,
        createdAt: moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss'),
        orderId: AssetOrders.getTypeName(item.orderId),
        amount: item.amount
          ? num.toMoney(item.amount, { currency: item.currency })
          : '',
      }));
    }

    return (
      <div className={`orderHistory__content ${isLoading ? ' -loading' : ''}`}>
        <h3>Asset Orders</h3>
        <Grid
          data={formatedTableData}
          columns={[
            { id: 'createdAt', name: 'Datum und Uhrzeit' },
            { id: 'conditionId', name: 'Name' },
            { id: 'assetId', name: 'Id' },
            { id: 'status', name: 'Status' },
            { id: 'amount', name: 'Höhe des Auftrags' },
            { id: 'orderId', name: 'Bestellung Id' },
          ]}
          className={{
            container: 'table',
          }}
          search
          sort
          pagination={{
            enabled: true,
            limit: 30,
          }}
          language={{
            'search': {
              'placeholder': 'Suchen...',
            },
            'pagination': {
              'previous': 'zurück',
              'next': 'weiter',
              'showing': 'Zeigen',
              'results': () => 'Ergebnisse',
              'to': 'bis',
              'of': 'von',
            },
          }}
          autoWidth
        />
      </div>
    );
  }
}
