import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import {
  Country,
  MaritalStatus,
  Occupation,
  OccupationIndustry,
} from '@quirion/data';

import { CaseStatus } from '../CaseStatus';
import './DetailsCases.css';
import { doTestUser, isProduction } from '../../utils/doTestUser';

const accountTypes = {
  singleAccount: 'Einzelkonto',
  jointAccount: 'Gemeinschaftskonto',
  joinedAccount: 'Gemeinschaftskonto',
  childSingleAccount: 'Kinderkonto',
  childJointAccount: 'Kinderkonto',
};

const blackList = [
  'mmw',
  'optInt1',
  'refreshToken',
  'readonlyFields',
  'prevStep',
  'customerType',
];

const getTaxIds = (taxIds) => {
  if (taxIds && taxIds.length) {
    return (
      <>
        {
          taxIds.map((item) => (
            <React.Fragment key={item.country}>
              {Country.label(item.country)}
              {': '}
              {item.tin}
              <br />
            </React.Fragment>
          ))
        }
      </>
    );
  }
  return '';
};

const getDisplayValue = (value, key = '') => {
  let val = value;
  if (typeof val === 'object') {
    val = <pre>{JSON.stringify(val, null, 2)}</pre>;
  } else if (typeof val === 'string' && val?.includes('{')) {
    val = <pre>{JSON.stringify(JSON.parse(val), null, 2)}</pre>;
  } else if (key.toLowerCase()?.includes('amount')) {
    val = Number(val).toLocaleString(
      'de-DE',
      { style: 'currency', currency: 'EUR' },
    );
  } else {
    val = String(val);
  }
  return val;
};

export default class DetailsCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBPCases: true,
      showProductCases: true,
      showQuirionCases: true,
      showBloxxterCases: true,
      isLoading: false,
    };
  }

  deleteCase = (id) => {
    const confirmed = window.confirm(
      `Sind Sie sicher, einen Benutzer mit der Case-ID: ${id} zu löschen?:`
    );
    if (confirmed) {
      axios.post(
        `${window.quirion_rest}deletecase`,
        { caseId: id },
      )
        .then((response) => {
          if (response.status === 200) {
            alert(`Der Benutzer mit der Case-ID: ${id} wurde erfolgreich gelöscht.`);
            this.props.reloadPerson(this.props.person.user.externalId);
          }
        })
        .catch((error) => alert(
          `Fehler bei "Delete Case", bitte Fehler melden bei IT,
        ${error}`,
        ));
    }
  }

  render() {
    const { person } = this.props;
    const { user = {} } = person;
    const { username } = user;

    const {
      showBPCases,
      showProductCases,
      showQuirionCases,
      showBloxxterCases,
      isLoading
    } = this.state;

    const SearchLink = (props) => {
      if (!props.query) {
        return null;
      }
      return (
        <a
          href="#"
          className="search-link"
          onClick={(e) => {
            e.preventDefault();
            this.props.doSearch(String(props.query));
          }}
        >
          {props.query}
        </a>
      );
    };

    if (!person || !person.cases) return null;

    const cases = person.cases.sort((a, b) => (
      new Date(a.createdAt || null) - new Date(b.createdAt || null)
    ));

    return (
      <div className={`DetailsCases ${isLoading ? ' -loading' : ''}`}>
        <div className="card" style={{ padding: '1rem 2rem', textAlign: 'right' }}>
          <label>
            <input
              type="checkbox"
              checked={showQuirionCases}
              onChange={() => {
                this.setState({
                  showQuirionCases: !showQuirionCases,
                });
              }}
            />
            {' quirion '}
          </label>
          <label>
            <input
              type="checkbox"
              checked={showBloxxterCases}
              onChange={() => {
                this.setState({
                  showBloxxterCases: !showBloxxterCases,
                });
              }}
            />
            {' Bloxxter '}
          </label>
          <label>
            <input
              type="checkbox"
              checked={showBPCases}
              onChange={() => {
                this.setState({
                  showBPCases: !showBPCases,
                });
              }}
            />
            {' BP '}
          </label>
          <label>
            <input
              type="checkbox"
              checked={showProductCases}
              onChange={() => {
                this.setState({
                  showProductCases: !showProductCases,
                });
              }}
            />
            {' Product '}
          </label>
        </div>
        {
          !cases.length && (
            <div className="case  card">
              <h2>Keine offenen Cases.</h2>
            </div>
          )
        }
        {
          cases.map((cs) => {
            const isProductCase = cs.purpose?.includes('PRODUCT');
            const isBloxxter = cs.purpose?.includes('BLOXXTER');

            if (isProductCase && !showProductCases) return null;
            if (!isProductCase && !showBPCases) return null;
            if (isBloxxter && !showBloxxterCases) return null;
            if (!isBloxxter && !showQuirionCases) return null;
            let conditionType = cs?.conditionId?.charAt(cs?.conditionId?.length - 1);
            if (conditionType === 'P' || conditionType === 'M') conditionType = 'Premium';
            else if (conditionType === 'C') conditionType = 'Comfort';
            else if (cs?.conditionId?.includes('_R')) conditionType = 'Regular';
            else if (conditionType === 'S') conditionType = 'Digital';
            else if (conditionType === 'XL') conditionType = 'Privat';
            else conditionType = 'Tokens';

            let investmentType = 'Sachwerte';
            if (cs?.conditionId?.includes('AV.AV')) investmentType = 'Altersvorsorge';
            else if (cs.conditionId?.includes('VV.NH')) investmentType = 'Nachhaltigkeit';
            else if (cs?.conditionId?.includes('VV.VV')) investmentType = 'Vermögensaufbau';
            else if (cs?.conditionId?.includes('VV.ME')) investmentType = 'Meinung';
            else if (cs?.conditionId?.includes('VV.VG')) investmentType = 'Video Games';
            else if (cs?.conditionId?.includes('VV.SC')) investmentType = 'Smart Cities';
            else if (cs?.conditionId?.includes('VV.SCD')) investmentType = 'Semiconductors';
            else if (cs?.conditionId?.includes('VV.ML')) investmentType = 'Maschinelearning';
            else if (cs?.conditionId?.includes('VV.IS')) investmentType = 'Infrastructure';
            else if (cs?.conditionId?.includes('VV.HI')) investmentType = 'Healthcare';
            else if (cs?.conditionId?.includes('VV.FT')) investmentType = 'Fintech';
            else if (cs?.conditionId?.includes('VV.EM')) investmentType = 'E-Mobility';
            else if (cs?.conditionId?.includes('VV.DI')) investmentType = 'Digital Infrastructure';
            else if (cs?.conditionId?.includes('VV.CS')) investmentType = 'Cyber Security';
            else if (cs?.conditionId?.includes('VV.CC')) investmentType = 'Cloud Computing';
            else if (cs?.conditionId?.includes('VV.CE')) investmentType = 'Clean Energy';
            else if (cs?.conditionId?.includes('VV.CW')) investmentType = 'Canabis & Wellness';
            else if (cs?.conditionId?.includes('VV.BA')) investmentType = 'Batteries';
            else if (cs?.conditionId?.includes('VV.TH')) investmentType = 'Megatrends';

            return (
              <div
                className={`case card ${isProductCase ? '-PRODUCT ' : '-BP '}`}
                key={cs.caseId}
                id={cs.caseId.replace('CASE#', '')}
              >
                <h2>
                  {isProductCase ? 'PRODUCT ' : 'BP '}
                  {cs.caseId.split('#')[0]}
                  <span>#</span>
                  {cs.caseId.split('#')[1]}
                  <a
                    href={cs.caseId.replace('CASE', '')}
                    className="collapse-link"
                    onClick={(e) => {
                      this.setState({
                        [`${cs.caseId}_expanded`]: !this.state[`${cs.caseId}_expanded`],
                      });
                    }}
                  >
                    {this.state[`${cs.caseId}_expanded`] ? '➖' : '➕'}
                  </a>
                  <button className="case-button" onClick={() => this.deleteCase(cs.caseId)}>Löschen</button>
                </h2>

                <CaseStatus data={cs.caseStatus} />

                <div className="case-quickinfo  card">
                  <h3>
                    {isBloxxter ? 'BLOXXTER ' : 'QUIRION '}
                    {isProductCase ? '' : accountTypes[cs.accountType]}
                    {' @ '}
                    {moment(cs.createdAt).format('DD.MM.YYYY')}
                    {' / '}
                    {`${investmentType} ${cs.strategy ?? cs.conditionId}`}
                    {' / '}
                    {conditionType}
                  </h3>

                  <table>
                    <tbody>
                      {
                        isProductCase && (
                          <tr>
                            <td>businessPartnerId: </td>
                            <td>{cs.businessPartnerId}</td>
                            <td />
                            <td>{accountTypes[cs.accountType]}</td>
                          </tr>
                        )
                      }
                      <tr>
                        <td>{isBloxxter ? 'Anlagebetrag: ' : 'Erstanlage: '}</td>
                        <td>{getDisplayValue((cs.initialAmount || 0), 'amount')}</td>
                        <td>
                          {cs.initialProceed === 'WIRE'
                            ? 'Überweisung'
                            : cs.initialProceedReferenceAccount
                              ? cs.referenceAccountHolder || ''
                              : cs.initialProceedAccountHolder || ''}
                        </td>
                        <td>
                          {cs.initialProceed === 'SEPA'
                            && (cs.initialProceedReferenceAccount
                              ? `${cs.referenceIban ?? 'n/a'} (Referenzkonto)`
                              : `${cs.initialProceedIban ?? 'n/a'} (anderes Konto)`)}
                        </td>
                      </tr>
                      {
                        cs.savingsPlan && (
                          <tr>
                            <td>Sparplan: </td>
                            <td>{getDisplayValue((cs.savingsPlanAmount || 0), 'amount')}</td>
                            <td>
                              {cs.savingsPlanProceed === 'WIRE'
                                ? 'Überweisung'
                                : cs.savingsPlanProceedReferenceAccount
                                  ? cs.referenceAccountHolder || ''
                                  : cs.savingsPlanProceedAccountHolder || ''}
                            </td>
                            <td>
                              {cs.savingsPlanProceed === 'SEPA'
                                && (cs.savingsPlanProceedReferenceAccount
                                  ? `${cs.referenceIban ?? 'n/a'} (Referenzkonto)`
                                  : `${cs.savingsPlanProceedIban} (anderes Konto)`)}
                            </td>
                          </tr>
                        )
                      }
                      {
                        !isProductCase && (
                          <>
                            <tr>
                              <td>Inhaber 1: </td>
                              <td>{`${cs.owner1FirstName || '– '} ${cs.owner1LastName || ''}`}</td>
                              <td><SearchLink query={cs.owner1ExternalId} /></td>
                              <td><SearchLink query={cs.owner1EMail} /></td>
                            </tr>
                            {
                              cs.accountType && cs.accountType?.toLowerCase()?.includes('joint') && (
                                <tr>
                                  <td>Inhaber 2</td>
                                  <td>{`${cs.owner2FirstName || '– '} ${cs.owner2LastName || ''}`}</td>
                                  <td><SearchLink query={cs.owner2ExternalId} /></td>
                                  <td><SearchLink query={cs.owner2EMail} /></td>
                                </tr>
                              )
                            }
                            {
                              cs.accountType && cs.accountType?.toLowerCase()?.includes('child') && (
                                <tr>
                                  <td>Kind</td>
                                  <td>{`${cs.childFirstName || '– '} ${cs.childLastName || ''}`}</td>
                                  <td><SearchLink query={cs.childExternalId} /></td>
                                  <td />
                                </tr>
                              )
                            }
                          </>
                        )
                      }

                    </tbody>
                  </table>
                  <div>
                    {!isProduction && (
                      <button
                        type="button"
                        onClick={() => doTestUser.bp(username, cs?.caseId)}
                      >
                        Activate BP
                      </button>
                    )}
                    {cs.accountType?.includes('child') && !isProduction && (
                      <button
                        type="button"
                        onClick={() => doTestUser.birthCertificate(cs, cs?.childExternalId)}
                      >
                        Activate Child (birthCertificate, person)
                      </button>
                    )}
                  </div>
                </div>

                {
                  !!this.state[`${cs.caseId}_expanded`] && (
                    <div className="showmore">

                      {
                        !isProductCase && (
                          <div className="card">
                            <h3>Personendaten</h3>
                            <table>
                              <thead className="thead">
                                <tr>
                                  <th />
                                  <th>Inhaber 1</th>
                                  <th>
                                    {
                                      cs.accountType
                                        && cs.accountType.toLowerCase()?.includes('joint')
                                        ? 'Inhaber 2'
                                        : ''
                                    }
                                  </th>
                                  <th>
                                    {
                                      cs.accountType
                                        && cs.accountType.toLowerCase()?.includes('child')
                                        ? 'Kind'
                                        : ''
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>ExternalId</td>
                                  <td><SearchLink query={cs.owner1ExternalId} /></td>
                                  <td><SearchLink query={cs.owner2ExternalId} /></td>
                                  <td><SearchLink query={cs.childExternalId} /></td>
                                </tr>
                                <tr>
                                  <td>EMail</td>
                                  <td><SearchLink query={cs.owner1EMail} /></td>
                                  <td><SearchLink query={cs.owner2EMail} /></td>
                                  <td><SearchLink query={cs.childEMail} /></td>
                                </tr>
                                {
                                  [
                                    'Gender', 'Salutation', 'Title', 'FirstName',
                                    'LastName', 'Street', 'Zip', 'City',
                                  ].map((key) => (
                                    <tr key={key}>
                                      <td>{key}</td>
                                      {
                                        ['owner1', 'owner2', 'child'].map((user) => (
                                          <td key={user}>{cs[user + key]}</td>
                                        ))
                                      }
                                    </tr>
                                  ))
                                }
                                <tr>
                                  <td>ResidenceCountry (Adressland)</td>
                                  <td>{Country.label(cs.owner1ResidenceCountry)}</td>
                                  <td>{Country.label(cs.owner2ResidenceCountry)}</td>
                                  <td>{Country.label(cs.childResidenceCountry)}</td>
                                </tr>
                                <tr>
                                  <td>MaritalStatus</td>
                                  <td>{MaritalStatus.label(cs.owner1MaritalStatus)}</td>
                                  <td>{MaritalStatus.label(cs.owner2MaritalStatus)}</td>
                                  <td>{MaritalStatus.label(cs.childMaritalStatus)}</td>
                                </tr>
                                <tr>
                                  <td>Occupation</td>
                                  <td>{Occupation.label(cs.owner1Occupation)}</td>
                                  <td>{Occupation.label(cs.owner2Occupation)}</td>
                                  <td>{Occupation.label(cs.childOccupation)}</td>
                                </tr>
                                <tr>
                                  <td>OccupationIndustry</td>
                                  <td>{OccupationIndustry.label(cs.owner1OccupationIndustry)}</td>
                                  <td>{OccupationIndustry.label(cs.owner2OccupationIndustry)}</td>
                                  <td>{OccupationIndustry.label(cs.childOccupationIndustry)}</td>
                                </tr>
                                <tr>
                                  <td>BirthDate</td>
                                  <td>{cs.owner1BirthDate}</td>
                                  <td>{cs.owner2BirthDate}</td>
                                  <td>{cs.childBirthDate}</td>
                                </tr>
                                <tr>
                                  <td>Country (Geburtsland)</td>
                                  <td>{Country.label(cs.owner1Country)}</td>
                                  <td>{Country.label(cs.owner2Country)}</td>
                                  <td>{Country.label(cs.childCountry)}</td>
                                </tr>
                                <tr>
                                  <td>TaxIds</td>
                                  <td>{getTaxIds(cs.owner1TaxIds)}</td>
                                  <td>{getTaxIds(cs.owner2TaxIds)}</td>
                                  <td>{getTaxIds(cs.childTaxIds)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )
                      }

                      <div className="card">
                        <h3>Case-Daten</h3>
                        <table>
                          <tbody>
                            {
                              Object.keys(cs).sort().map((key) => {
                                if (
                                  !(key?.indexOf('owner1') === 0)
                                  && !(key?.indexOf('owner2') === 0)
                                  && !(key?.indexOf('child') === 0)
                                  && !(key?.indexOf('caseStatus') === 0)
                                  && !(key?.indexOf('reference') === 0)
                                  && !(key?.indexOf('fee') === 0)
                                  && !(key?.indexOf('payout') === 0)
                                  && !(key?.indexOf('initial') === 0)
                                  && !(key?.indexOf('savingsPlan') === 0)
                                  && !(key?.indexOf('wphg') === 0)
                                  && !blackList?.includes(key)
                                ) {
                                  return (
                                    <tr key={key}>
                                      <td style={{ width: '40%' }}>{key}</td>
                                      <td>{getDisplayValue(cs[key], key)}</td>
                                    </tr>
                                  );
                                }
                                return null;
                              })
                            }
                          </tbody>
                        </table>
                      </div>

                      {
                        !isProductCase && (
                          <div className="card">
                            <h3>Referenzkonto</h3>
                            <table>
                              <tbody>
                                {
                                  Object.keys(cs).sort().map((key) => {
                                    if (key?.indexOf('reference') === 0) {
                                      return (
                                        <tr key={key}>
                                          <td style={{ width: '40%' }}>{key}</td>
                                          <td>{getDisplayValue(cs[key], key)}</td>
                                        </tr>
                                      );
                                    }
                                    return null;
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        )
                      }

                      {
                        isBloxxter && (
                          <div className="card">
                            <h3>Konto für Zinsgutschriften</h3>
                            <p>Aktuell wird dieses Konto in der Registrierung nicht vom Kunden erfragt. Es werden automtatisch die Daten des Referenzkonto bzw. Verrechnungskonto hinterlegt.</p>
                            <table>
                              <tbody>
                                {
                                  Object.keys(cs).sort().map((key) => {
                                    if (key?.indexOf('payout') === 0) {
                                      return (
                                        <tr key={key}>
                                          <td style={{ width: '40%' }}>{key}</td>
                                          <td>{getDisplayValue(cs[key], key)}</td>
                                        </tr>
                                      );
                                    }
                                    return null;
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        )
                      }

                      {
                        isBloxxter && (
                          <div className="card">
                            <h3>Konto für Kosten (Gebührenkonto)</h3>
                            <table>
                              <tbody>
                                {
                                  Object.keys(cs).sort().map((key) => {
                                    if (key?.indexOf('fee') === 0) {
                                      return (
                                        <tr key={key}>
                                          <td style={{ width: '40%' }}>{key}</td>
                                          <td>{getDisplayValue(cs[key], key)}</td>
                                        </tr>
                                      );
                                    }
                                    return null;
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        )
                      }

                      <div className="card">
                        <h3>{isBloxxter ? 'Anlagebetrag' : 'Erstanlage'}</h3>
                        <table>
                          <tbody>
                            {
                              Object.keys(cs).sort().map((key) => {
                                if (key?.indexOf('initial') === 0) {
                                  return (
                                    <tr key={key}>
                                      <td style={{ width: '40%' }}>{key}</td>
                                      <td>{getDisplayValue(cs[key], key)}</td>
                                    </tr>
                                  );
                                }
                                return null;
                              })
                            }
                          </tbody>
                        </table>
                      </div>

                      {
                        !isBloxxter && (
                          <div className="card">
                            <h3>Sparplan</h3>
                            <table>
                              <tbody>
                                {
                                  Object.keys(cs).sort().map((key) => {
                                    if (key?.indexOf('savingsPlan') === 0) {
                                      return (
                                        <tr key={key}>
                                          <td style={{ width: '40%' }}>{key}</td>
                                          <td>{getDisplayValue(cs[key], key)}</td>
                                        </tr>
                                      );
                                    }
                                    return null;
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        )
                      }

                      <div className="card">
                        <h3>
                          WpHG-Daten
                          <a
                            className="collapse-link"
                            onClick={(e) => {
                              this.setState({
                                [`${cs.caseId}_wphg_expanded`]: !this.state[`${cs.caseId}_wphg_expanded`],
                              });
                            }}
                          >
                            {this.state[`${cs.caseId}_wphg_expanded`] ? '➖' : '➕'}
                          </a>
                        </h3>

                        {
                          !!this.state[`${cs.caseId}_wphg_expanded`] && (
                            <table>
                              <tbody>
                                {
                                  Object.keys(cs.wphg).sort().map((key) => {
                                    if (key === 'roboValues') return null;
                                    return (
                                      <tr key={key}>
                                        <td style={{ width: '40%' }}>{key}</td>
                                        <td>{getDisplayValue(cs.wphg[key], key)}</td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                            </table>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </div>
            );
          })
        }
      </div>
    );
  }
}
