import React, { Component } from 'react';
import { Storage } from 'aws-amplify';
import helperGlobal from '../../../../utils/helper_global';

export default class PostidentDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      documents: [],
    };
  }

  async showDocuments(caseId) {
    Storage.vault.list(
      caseId,
      {
        bucket: `quirion-owl-${helperGlobal.getEnv()}-post-idents`,
        level: 'cases',
        customPrefix: { public: 'cases/' },
      },
    )
      .then((result) => this.setState({ documents: result }))
      .catch((err) => console.log(err));
  }

  async getDocument(document) {
    Storage.get(document.key, {
      bucket: `quirion-owl-${helperGlobal.getEnv()}-post-idents`,
      level: 'cases',
      expires: 360,
      customPrefix: { public: 'cases/' },
    })
      .then((result) => {
        const url = result;
        var win = window.open(url, '_blank');
        win.focus();
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  componentDidMount() {
    if (this.props.hit.caseId) {
      this.showDocuments(this.props.hit.caseId);
    }
  }

  removePrefix(arg, prefix) {
    return arg.replace(`${prefix}/${prefix}`, prefix);
  }

  getFileTyp(document) {
    const fileNameParts = document.key.split('.');
    return fileNameParts[fileNameParts.length - 1];
  }

  isFileToDisplay(document) {
    let displayFile = true;
    if (document.size <= 0) { // foldername gets ignored
      displayFile = false;
    }

    const ignoreFileTypes = ['sig', 'rsapss'];
    if (ignoreFileTypes.includes(this.getFileTyp(document))) {
      displayFile = false;
    }

    return displayFile;
  }

  listDocuments() {
    const returnValue = [];
    const { hit } = this.props;
    if (
      hit.identificationStatus
      && hit.identificationStatus.subStatus
      && hit.identificationStatus.subStatus.code
      && hit.identificationStatus.subStatus.description) {
      returnValue.push(
        <div key={`${hit.caseId}code`}>
          {hit.identificationStatus.subStatus.description}
          {' '}
          (
          {hit.identificationStatus.subStatus.code}
          )
          {' '}
        </div>,
      );
    }

    if (
      hit.identificationStatus
      && hit.identificationStatus.subStatusReason
      && hit.identificationStatus.subStatusReason.description
      && hit.identificationStatus.subStatusReason.code) {
      returnValue.push(
        <div key={`${hit.caseId}reason`}>
          {hit.identificationStatus.subStatusReason.description}
          {' '}
          (
          {hit.identificationStatus.subStatusReason.code}
          )
        </div>,
      );
    }

    this.state.documents.forEach((document) => {
      if (this.isFileToDisplay(document)) {
        returnValue.push(
          <div
            key={document.eTag}
            onClick={() => this.getDocument(document)}
          >
            {this.removePrefix(document.key, this.props.hit.caseId)}
          </div>,
        );
      }
    });
    return returnValue;
  }

  render() {
    console.log(this.props);
    return (
      <div className="PostidentDetails">
        {this.listDocuments()}
      </div>
    );
  }
}
