import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';

/**
 * @author Tomas Bujalos <tomas.bujalos@quirion.de>
 * @param {String} name - modal custom css classes
 * @param {Object} headerContent - jsx content for the header
 * @param {Object} bodyContent - jsx for the modal body
 * @param {callback} confirmButton - for the modal confirm button
 * @param {String} confirmButtonTitle - confirm button title
 * @param {Boolean} showModal - property to trigger modal visibility
 * @param {Object} modalStyle - object with custom style
 * @returns modal
 *
 * Modal Wrapper is an modal which doesn't contain any logic.
 * All the logic has to be added from the parent component.
 */
export default function ModalWrapper({
  name,
  headerContent,
  bodyContent,
  confirmButton,
  confirmButtonTitle,
  showModal,
  triggerModal,
  modalStyle,
}) {
  return (
    showModal && (
      <div
        className={`modal ${name}`}
        style={modalStyle}
      >
        <div className="modal-header">
          {headerContent}
          <span
            role="button"
            tabIndex="0"
            onClick={() => triggerModal()}
            aria-hidden
          >
            <FontAwesomeIcon icon="times" />
          </span>

        </div>
        <div className="modal-body">
          {bodyContent}
        </div>
        <div className="modal-footer">
          <button
            onClick={() => confirmButton()}
            type="button"
          >
            {confirmButtonTitle}
          </button>
        </div>
      </div>
    )
  );
}

ModalWrapper.defaultProps = {
  headerContent: <span> Header Title </span>,
  bodyContent: <div> Body Content </div>,
  confirmButtonTitle: 'Button Title',
  showModal: false,
};
