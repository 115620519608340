import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Grid, _ } from 'gridjs-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'gridjs/dist/theme/mermaid.css';

const typeTexts = {
  TOPUP: 'Aufstockung',
  WITHDRAWAL: 'Entnahme',
  DISBURSEMENT: 'Auszahlung',
  STRATEGYCHANGE: 'Strategiewechsel',
  DELETESTRATEGY: 'Strategieauflösung',
  FIRST: 'Eröffnung',
  CHANGEPERSON: 'Persönliche Angaben',
  DELETEBP: 'Kontoauflösung',
  CHANGEPACKAGE: 'Paketwechsel',
};

const transferTypeText = {
  WIRE: 'Überweisung',
  SEPA: 'Lastschrift ',
};
export default class PersonOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipsHistoryDetails: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { ips } = this.props;
    if (ips && ips.ipsId) {
      this.loadOrderHistoryIps(ips.ipsId);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { ips } = this.props;
    if (ips.ipsId !== nextProps.ips.ipsId) {
      if (nextProps.ips && nextProps.ips.ipsId) {
        this.loadOrderHistoryIps(nextProps.ips.ipsId);
      }
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const { ips, lookupDays } = this.props;
    if (prevProps.lookupDays !== lookupDays) {
      this.loadOrderHistoryIps(ips.ipsId);
    }
  }

  getTypeName(orderId) {
    const orderType = orderId.split('#')[1];
    return typeTexts[orderType] || orderType;
  }

  getTransferTypeName(orderId) {
    const paymentType = orderId.split('#')[2];
    return transferTypeText[paymentType] || '';
  }

  async loadOrderHistoryIps(id) {
    const { person, lookupDays } = this.props;
    this.setState({ isLoading: true });
    await axios.post(
      `${window.quirion_rest}getorder`,
      {
        'username': person.user.username,
        'businessPartner': false,
        'ips': true,
        'ipsId': id,
        'range': parseInt(lookupDays, 10),
        'status': 'ALL',
      },
    )
      .then((response) => {
        const currentIpsOrders = response.data.ips; // check
        this.setState({ isLoading: false });
        if (currentIpsOrders) {
          this.setState({
            ipsHistoryDetails: currentIpsOrders,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  async deletePendingIps(ips) {
    const confirmed = window.confirm(
      `Möchten Sie Auftrag bestellung von ${ips.createdAt || ''} in höhe ${ips.amount} löschen?`,
    );
    if (confirmed) {
      await axios.post(
        `${window.quirion_rest}/deleteorder`,
        {
          'account': parseInt(ips.ipsId, 10), // IPS ID
          'orderId': ips.orderId, //  "ORDER#TOPUP#SEPA#7z1uQDuzwqYBYXnaBrVAj6" <-- FULL orderId
        },
      )
        .then((response) => {
          alert('The Order has been successfully deleted');
          this.loadOrderHistoryIps(ips.ipsId);
        })
        .catch((error) => alert(error));
    }
  }

  render() {
    const { ipsHistoryDetails, isLoading } = this.state;
    const { ips, person } = this.props;
    const formatedTableData = ipsHistoryDetails ? ipsHistoryDetails.map((item) => ({
      ...item,
      createdAt: moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss'),
      orderId: this.getTypeName(item.orderId),
      amount: item.amount
        ? Number(item.amount).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
        : '',
      paymentType: this.getTransferTypeName(item.orderId),
      businessPartnerId: ips.businessPartnerId,
      externalId: person.user.externalId,
      communicationEmail: person.user.communicationEmail,
      name: ips.name,
      strategy: ips.strategy,
      orderIdRaw: {
        orderId: item.orderId,
        ipsId: item.ipsId,
        ipsName: ips.name,
        createdAt: moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss'),
        amount: item.amount
          ? Number(item.amount).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
          : '',
      },
    })) : [];
    return (
      <div className={`orderHistory__content ${isLoading ? ' -loading' : ''}`}>
        <h3>Ips Orders</h3>
        <Grid
          data={formatedTableData}
          columns={[
            { id: 'createdAt', name: 'Datum und Uhrzeit' },
            { id: 'orderId', name: 'Auftragstyp' },
            { id: 'status', name: 'Status' },
            { id: 'amount', name: 'Höhe des Auftrags' },
            { id: 'paymentType', name: 'Zahlungsart' },
            { id: 'businessPartnerId', name: 'BP Nummer' },
            { id: 'externalId', name: 'Quirion ID' },
            { id: 'communicationEmail', name: 'E-mail' },
            { id: 'name', name: 'Ips ID' },
            { id: 'strategy', name: 'Gewichtung' },
            {
              id: 'orderIdRaw',
              name: 'Actions',
              formatter: (cell, row) => _(
                row.cell(2).data === 'PENDING' && (
                  <button
                    type="button"
                    onClick={() => this.deletePendingIps(row.cell(10).data)}
                  >
                    <FontAwesomeIcon icon="trash-alt" />
                  </button>
                ),
              ),
            },
          ]}
          className={{
            container: 'table',
          }}
          search
          sort
          pagination={{
            enabled: true,
            limit: 30,
          }}
          language={{
            'search': {
              'placeholder': 'Suchen...',
            },
            'pagination': {
              'previous': 'zurück',
              'next': 'weiter',
              'showing': 'Zeigen',
              'results': () => 'Ergebnisse',
              'to': 'bis',
              'of': 'von',
            },
          }}
          autoWidth
        />
      </div>
    );
  }
}
