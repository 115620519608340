/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import axios from 'axios';
import countries from '../../../countries';

const initialState = {
  externalId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  salutation: '',
  birthDate: '',
  birthPlace: '',
  birthCertificateNumber: '',
  birthCertificateIssuer: '',
  birthCertificateIssuerDate: '',
  birthCertificateCountryOfIssuer: '',
  birthCertificateCityOfIssuer: '',
  countries,
  caseStatus: {},
  validations: {},
  formIsSubmitting: false,
  readOnly: false,
  open: false,
};
const regExDateFormat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

class GeburtsurkundeFormSimple extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  // 1) search and check the externalID if its a child
  componentDidMount() {
    const { case: caseProp } = this.props;
    if (caseProp) this.getChildById(caseProp);
  }

  async getChildById(childCase) {
    axios
      .post(
        `${window.quirion_rest}getbyexternalid`,
        { externalId: parseInt(childCase.childExternalId, 10) },
      )
      .then((response) => {
        const child = response?.data?.user;
        this.setState({
          salutation: child.salutation || (child.gender === 'female' ? 'Frau' : 'Herr'),
          externalId: child.externalId || '',
          firstName: child.firstName || '',
          middleName: child.middleName || '',
          lastName: child.lastName || '',
          gender: child.gender || 'female',
          birthDate: child.birthDate || '',
          birthPlace: child.birthPlace || '',
          birthCertificateNumber: child.passportNumber || '',
          birthCertificateIssuer: child.authority || this.addCityOfIssuer(child.country),
          birthCertificateIssuerDate: child.dateIssued || '',
          birthCertificateCountryOfIssuer: child.countryOfDocument || 'DEU',
          birthCertificateCityOfIssuer: child.cityOfDocument || '',
        });
        this.setState({ caseStatus: this.setCaseStatus(childCase) });
      })
      .catch((error) => {
        this.setState({
          caseStatus: {
            message: `Kind mit Externe ID ${childCase.externalId}, \
          error Response ${JSON.stringify(error)}`,
            childFound: false,
          },
        });
      });
  }

  addCityOfIssuer = (country) => (country === 'DEU' ? 'Standesamt' : '');

  // input method for search field
  inputHandler = (e) => {
    if (e.target.name === 'birthPlace') {
      this.setState({
        [e.target.name]: e.target.value,
        birthCertificateCityOfIssuer: e.target.value,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  // 2) check the status of the case
  setCaseStatus = (childCase) => {
    const childFound = false;
    if (Object.keys(childCase).length > 0) {
      if (childCase.caseStatus && childCase.caseStatus.childIdent === true) {
        this.setState({ readOnly: true });
        return {
          childFound: true,
          message: 'Der Benutzer hat die Geburtsurkunde bereits eingereicht.',
        };
      }
      this.setState({ readOnly: false });
      return {
        childFound: true,
        message: null,
      };
    }
    return {
      childFound,
      message: 'Kein Kinderkonto im cases gefunden.',
    };
  };

  toggleOpen = () => this.setState({ open: !this.state.open });

  checkForm = (event) => {
    event.preventDefault();
    const validators = {};

    if (!this.state.firstName || this.state.firstName.length < 3) {
      validators.errorFirstName = 'Der Vorname muss mindestens 3 Buchstaben lang sein';
    }

    if (!this.state.lastName || this.state.lastName.length < 3) {
      validators.errorLastName = 'Der Nachname muss mindestens 3 Buchstaben lang sein';
    }

    if (!this.state.salutation || this.state.salutation.length <= 3) {
      validators.errorSalutation = 'Die Anrede muss mindestens drei Buchstaben lang sein';
    }

    if (regExDateFormat.test(this.state.birthDate) !== true) {
      validators.errorbirthDate = 'Das Geburtsdatum muss das folgende Format haben: JJJJ-MM-TT';
    }

    if (!this.state.birthPlace || this.state.birthPlace.length < 3) {
      validators.errorbirthPlace = 'Der Geburtsort muss mindestens 3 Buchstaben lang sein';
    }

    if (!this.state.birthCertificateNumber || this.state.birthCertificateNumber.length < 3) {
      validators.errorbirthCertificateNumber = 'Die Nummer der Geburtsurkunde muss mindestens 3 Zeichen lang sein';
    }

    if (!this.state.birthCertificateIssuer || this.state.birthCertificateIssuer.length < 3) {
      validators.errorbirthCertificateIssuer = 'Ausstellende Behörde muss mindestens 3 Buchstaben lang sein';
    }

    if (!this.state.birthCertificateCityOfIssuer || this.state.birthCertificateCityOfIssuer.length < 3) {
      validators.errorbirthCertificateCityOfIssuer = 'Ort der Ausstellung muss mindestens 3 Buchstaben lang sein';
    }

    if (regExDateFormat.test(this.state.birthCertificateIssuerDate) !== true) {
      validators.errorbirthCertificateIssuerDate = 'Datum der Ausstellung muss das folgende Format haben: JJJJ-MM-TT';
    }

    if (!this.state.birthCertificateCountryOfIssuer || this.state.birthCertificateCountryOfIssuer.length < 3) {
      validators.errorbirthCertificateCountryOfIssuer = 'Land der Behörde muss mindestens 3 Buchstaben lang sein';
    }

    if (Object.keys(validators).length === 0) {
      this.setState({ formIsSubmitting: true });
      this.sendBirthCertificate();
    } else {
      this.setState({
        validations: validators,
        formIsSubmitting: false,
      });
    }
  };

  // 5) submit geburtsurkunde
  async sendBirthCertificate() {
    const {
      externalId, firstName, middleName, lastName, gender, salutation, birthDate,
      birthPlace, birthCertificateNumber, birthCertificateCountryOfIssuer,
      birthCertificateIssuerDate, birthCertificateCityOfIssuer, birthCertificateIssuer,
    } = this.state;
    const data = {
      externalId,
      firstName,
      middleName,
      lastName,
      gender,
      salutation,
      birthDate,
      birthPlace,
      birthCertificateNumber,
      birthCertificateIssuer,
      birthCertificateIssuerDate,
      birthCertificateCountryOfIssuer,
      birthCertificateCityOfIssuer,
    };

    axios
      .post(`${window.quirion_rest}birthcertificate`, data)
      .then((res) => {
        this.props.reloadCase(externalId);
        alert('Vielen Dank!');
        this.resetForm();
      })
      .catch((error) => {
        console.log(error);
        alert('The submission went wrong');
        // location.reload();
      });
  }

  resetForm() {
    const { case: caseProp } = this.props;
    this.setState({
      validations: {},
      formIsSubmitting: false,
    });
    this.getChildById(caseProp);
  }

  // 4) render the form or message
  render() {
    const {
      readOnly, externalId, firstName, middleName, lastName, gender, salutation, birthDate,
      birthPlace, birthCertificateIssuer, birthCertificateCityOfIssuer, validations,
      birthCertificateCountryOfIssuer, birthCertificateIssuerDate, birthCertificateNumber,
      caseStatus, formIsSubmitting,
    } = this.state;

    const { case: caseProp } = this.props;

    // create dropdown elements for the countries
    const countriesList = countries.map((element) => (
      <option key={Object.values(element)[0]} value={Object.keys(element)[0]}>
        {Object.values(element)[0]}
      </option>
    ));

    return (
      <>
        <div className="savingsPlan__accounDesc">
          <div style={{ marginTop: '10px' }}>
            {
              caseProp?.caseStatus?.hasOwnProperty('owner1Avaloq')
                ? (
                  `Owner1:
                  ${caseProp?.owner1FirstName}
                  ${caseProp?.owner1LastName}
                  ${caseProp?.caseStatus?.owner1Avaloq
                    ? ' - Avaloq angelegt'
                    : ' - Noch nicht in Avaloq angelegt'}`
                ) : ('Kein Owner1')
            }
          </div>
          <div style={{ marginBottom: '20px' }}>
            {
              caseProp?.caseStatus?.hasOwnProperty('owner2Avaloq') ? (
                `Owner2:
              ${caseProp?.owner2FirstName}
              ${caseProp?.owner2LastName}
              ${caseProp?.caseStatus.owner2Avaloq ? ' - Avaloq angelegt' : ' - Noch nicht in Avaloq angelegt'}`
              ) : ('Kein Owner2')
            }
          </div>
        </div>
        <div className="GeburtsurkundeSimple">
          <div
            className="GeburtsurkundeSimple__Title"
            onClick={() => this.toggleOpen()}
          >
            <h3 className="GeburtsurkundeSimple__Title__Text">
              {`${lastName} ${firstName}, ID: ${externalId}`}
            </h3>
            <div className="GeburtsurkundeSimple__Title__Symbol">
              {open ? '➖' : '➕'}
            </div>
          </div>
          {
            open && (
              <>
                {readOnly === true && (
                  <div style={{ marginBottom: '20px' }}>
                    <b>
                      {caseStatus?.message}
                    </b>
                  </div>
                )}
                <form onSubmit={this.checkForm} noValidate>
                  <div>
                    <div className="GeburtsurkundeSimple__Row">
                      <label className="GeburtsurkundeSimple__Label">ExternalId:</label>
                      <span className="GeburtsurkundeSimple__Readonly">{externalId}</span>
                    </div>

                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childFirstName"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Vorname:
                      </label>
                      <input
                        id="childFirstName"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={firstName}
                        type="text"
                        name="firstName"
                        noValidate
                        placeholder=" "
                        required
                        disabled={readOnly}
                      />
                    </div>
                    {
                      validations.errorFirstName
                      && <div className="errorMessage">{validations.errorFirstName}</div>
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childMiddleName"
                        className="GeburtsurkundeSimple__Label"
                      >
                        weitere Vornamen:
                      </label>
                      <input
                        id="childMiddleName"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={middleName}
                        type="text"
                        name="middleName"
                        noValidate
                        placeholder=" "
                        required
                        disabled={readOnly}
                      />
                    </div>
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childLastName"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Nachname:
                      </label>
                      <input
                        id="childLastName"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={lastName}
                        type="text"
                        name="lastName"
                        noValidate
                        placeholder=" "
                        required
                        disabled={readOnly}
                      />
                    </div>
                    {
                      validations.errorLastName
                      && <div className="errorMessage">{validations.errorLastName}</div>
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childGenderMale childGenderFemale"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Geschlecht:
                      </label>
                      <div className="GeburtsurkundeSimple__Radio">
                        <input
                          id="childGenderMale"
                          type="radio"
                          name="gender"
                          value="male"
                          checked={gender === 'male'}
                          onChange={this.inputHandler}
                          noValidate
                          disabled={readOnly}
                        />
                        männlich
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          checked={gender === 'female'}
                          onChange={this.inputHandler}
                          noValidate
                          disabled={readOnly}
                        />
                        weiblich
                      </div>
                    </div>
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childSalutation"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Anrede:
                      </label>
                      <select
                        className={`GeburtsurkundeSimple__Select ${validations.errorSalutation ? 'invalid__field' : ''}`}
                        value={salutation}
                        name="salutation"
                        onChange={this.inputHandler}
                        disabled={readOnly}
                      >
                        <option value="Herr">Herr</option>
                        <option value="Frau">Frau</option>
                      </select>
                    </div>
                    {
                      validations.errorSalutation
                      && <div className="errorMessage">{validations.errorSalutation}</div>
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childBirthdate"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Geburtsdatum:
                      </label>
                      <input
                        id="childBirthdate"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={birthDate}
                        type="text"
                        name="birthDate"
                        placeholder="JJJJ-MM-TT"
                        noValidate
                        required
                        disabled={readOnly}
                      />
                    </div>
                    {
                      validations.errorbirthDate
                      && <div className="errorMessage">{validations.errorbirthDate}</div>
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childBirthplace"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Geburtsort:
                      </label>
                      <input
                        id="childBirthplace"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={birthPlace}
                        type="text"
                        name="birthPlace"
                        noValidate
                        placeholder=" "
                        required
                        disabled={readOnly}
                      />
                    </div>
                    {
                      validations.errorbirthPlace
                      && <div className="errorMessage">{validations.errorbirthPlace}</div>
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childBirthCertNo"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Geburtsurkunde Nummer:
                      </label>
                      <input
                        id="childBirthCertNo"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={birthCertificateNumber}
                        type="text"
                        name="birthCertificateNumber"
                        noValidate
                        placeholder=" "
                        required
                        disabled={readOnly}
                      />
                    </div>
                    {
                      validations.errorbirthCertificateNumber
                        && (
                        <div className="errorMessage">
                          {validations.errorbirthCertificateNumber}
                        </div>
                        )
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childBirthCertIssuer"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Ausstellende Behörde:
                      </label>
                      <input
                        id="childBirthCertIssuer"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={birthCertificateIssuer}
                        type="text"
                        name="birthCertificateIssuer"
                        noValidate
                        placeholder=" "
                        required
                        disabled={readOnly}
                      />
                    </div>
                    {
                      validations.errorbirthCertificateIssuer
                        && (
                        <div className="errorMessage">
                          {validations.errorbirthCertificateIssuer}
                        </div>
                        )
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childBirthCertCity"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Ort der Ausstellung:
                      </label>
                      <input
                        id="childBirthCertCity"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={birthCertificateCityOfIssuer}
                        type="text"
                        name="birthCertificateCityOfIssuer"
                        noValidate
                        placeholder=" "
                        required
                        disabled={readOnly}
                      />
                    </div>
                    {
                      validations.errorbirthCertificateCityOfIssuer
                        && (
                        <div className="errorMessage">
                          {validations.errorbirthCertificateCityOfIssuer}
                        </div>
                        )
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childBirthCertIssueDate"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Datum der Ausstellung:
                      </label>
                      <input
                        id="childBirthCertIssueDate"
                        className="GeburtsurkundeSimple__Input"
                        onChange={this.inputHandler}
                        value={birthCertificateIssuerDate}
                        type="text"
                        name="birthCertificateIssuerDate"
                        placeholder="JJJJ-MM-TT"
                        noValidate
                        required
                        disabled={readOnly}
                      />
                    </div>
                    {
                      validations.errorbirthCertificateIssuerDate
                        && (
                        <div className="errorMessage">
                          {validations.errorbirthCertificateIssuerDate}
                        </div>
                        )
                    }
                    <div className="GeburtsurkundeSimple__Row">
                      <label
                        htmlFor="childBirthCertCountryOfIssuer"
                        className="GeburtsurkundeSimple__Label"
                      >
                        Land der Behörde:
                      </label>
                      <select
                        id="childBirthCertCountryOfIssuer"
                        className="GeburtsurkundeSimple__Select"
                        onChange={this.inputHandler}
                        value={birthCertificateCountryOfIssuer}
                        name="birthCertificateCountryOfIssuer"
                        noValidate
                        disabled={readOnly}
                      >
                        {countriesList}
                      </select>
                    </div>
                    {
                      validations.errorbirthCertificateCountryOfIssuer
                        && (
                        <div className="errorMessage">
                          {validations.errorbirthCertificateCountryOfIssuer}
                        </div>
                        )
                    }
                    <button
                      type="submit"
                      disabled={readOnly || formIsSubmitting}
                    >
                      Absenden
                    </button>
                  </div>
                </form>
              </>
            )
          }
        </div>
      </>
    );
  }
}

export default GeburtsurkundeFormSimple;
