import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';
import { Auth } from 'aws-amplify';
import navigation from '../../../data/navigation';

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: []
    };
  }

  componentDidMount() {
    this.loadCognitoUser()
  }

  async loadCognitoUser() {
    const user = await Auth.currentAuthenticatedUser();
    const usersGroups = user.signInUserSession.accessToken.payload['cognito:groups'] ? user.signInUserSession.accessToken.payload['cognito:groups'] : []
    const navigationElements = []

    for (const [key, value] of Object.entries(navigation)) {
      let navPointAllowedForUser = false
      usersGroups.forEach(element => {
        if (value.access.indexOf(element) !== -1) {
          navPointAllowedForUser = true
        }
      })

      if (navPointAllowedForUser) {
        navigationElements.push(<li key={key}><Link to={value.link}>{key}</Link></li>)
      }
    }

    this.setState({ navigation: navigationElements })
  }

  render() {
    const { navigation } = this.state;
    return (
      <>
        <ul>{navigation}</ul>
      </>
    );
  }
}
