import React, { Component } from 'react';
// import './App.css';
import * as mutations from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

class Form1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: '',
      externalId: '',
      avaloqId: '',
      errorproductId: '',
      errorexternalId: '',
      erroravaloqId: '',
    };
  }

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  async addProduct() {
    let inputData = {
      productId: parseInt(this.state.productId),
      externalId: this.state.externalId,
      avaloqId: this.state.avaloqId,
    };
    let response = await API.graphql(
      graphqlOperation(mutations.addProduct, inputData)
    );
  }

  checkForm = event => {
    event.preventDefault();

    let errorproductId = false;
    let errorexternalId = false;
    let erroravaloqId = false;

    let errorExists = false;
    console.log(isNaN(this.state.productId), this.state.productId);
    if (this.state.productId.length < 3) {
      this.setState({
        errorproductId: 'First Name must be minimum 3 digits long!',
      });
      errorExists = true;
    } else if (!isNaN(this.state.productId)) {
      this.setState({
        errorproductId: 'Product Id must be a number!',
      });
      errorExists = true;
    } else {
      this.setState({ errorproductId: '' });
    }

    if (this.state.externalId.length < 3) {
      this.setState({
        errorexternalId: 'First Name must be 3 characters long!',
      });
      errorExists = true;
    } else {
      this.setState({ errorexternalId: '' });
    }

    if (this.state.avaloqId.length < 3) {
      this.setState({
        erroravaloqId: 'First Name must be 3 characters long!',
      });
      errorExists = true;
    } else {
      this.setState({ erroravaloqId: '' });
    }

    if (errorExists) {
      console.log('do nothing');
    } else {
      this.addProduct();
      console.log('jsonaction');
    }
  };

  render() {
    return (
      <div className="forms">
        <h2>Add Product</h2>

        <form className="form1" onSubmit={this.checkForm} noValidate>
          <label htmlFor="productId">
            Product Id:
            <input
              type="number"
              name="productId"
              onChange={this.handleInput}
              value={this.state.productId}
              id="productId"
            />
          </label>
          <div className="errorMessage">{this.state.errorproductId}</div>

          <label htmlFor="externalId">
            External Id:
            <input
              type="text"
              name="externalId"
              onChange={this.handleInput}
              value={this.state.externalId}
              id="externalId"
            />
          </label>
          <div className="errorMessage">{this.state.errorexternalId}</div>

          <label htmlFor="avaloqId">
            Avaloq Id:
            <input
              type="text"
              name="avaloqId"
              onChange={this.handleInput}
              value={this.state.avaloqId}
              id="avaloqId"
            />
          </label>
          <div className="errorMessage">{this.state.erroravaloqId}</div>

          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }
}

export default Form1;
