/**
 * Replace letters in an IBAN with numbers, according to ISO 7064.
 * Does *not* check for invalid characters
 *
 * @param {string} iban The IBAN to be modified
 * @return {string} The modified IBAN
 *
 * @author FSC
 */
function replaceIbanLettersWithNumbers(iban) {
  const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let newIban = iban;

  newIban = newIban
    .split('')
    .map((character) => alphabet.indexOf(character))
    .join('');

  return newIban;
}

/**
 * Check a provided IBAN for validity, according to ISO 7064
 *
 * @see https://en.wikipedia.org/wiki/International_Bank_Account_Number#Validating_the_IBAN
 *
 * @param {string} iban The IBAN to be checked
 * @return {boolean} If the IBAN is valid or not
 *
 * @author FSC
 */
export function isValidIban(iban) {
  if (!iban || iban.length < 15) {
    // as this is somewhat expensive, only proceed if IBAN length is valid..
    // shortest IBAN is currently 15 characters (Norway)
    // TODO: check regularly for new countries with shorter IBANS
    // TODO: maybe check length, based on country..
    return false;
  }

  let d = replaceIbanLettersWithNumbers(iban);

  // countryCode is four characters after letter replacement
  const countryCode = d.substr(0, 4);
  const checkDigits = d.substr(4, 2);

  // move countryCode and checkDigits to the end
  d = `${d.substr(6)}${countryCode}${checkDigits}`;

  let i = 0;
  let n;
  let remainder;

  do {
    if (i === 0) {
      // begin with 9 characters
      n = d.substr(0, 9);
    } else {
      // continue with remainder concat with 7 more characters
      n = `${remainder}${d.substr(i * 7 + 2, 7)}`;
    }
    // perform the modulo 97 test
    remainder = parseInt(n, 10) % 97;
    i += 1;
  } while (d.length > i * 7 + 2);

  // if remainder is 1, the IBAN is valid
  return remainder === 1;
}
