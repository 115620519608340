import React, { Component } from 'react';
import './App.css';

import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';

import {
  faFileAlt, faThumbsUp, faThumbsDown, faTrashAlt, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import awsConfigDev from '../data/aws-exports-dev';
import awsConfigTest from '../data/aws-exports-test';
import awsConfigProd from '../data/aws-exports-prod';
import configBuckets from '../data/configBuckets';
import Intro from './screens/Intro';
import Form1 from './screens/Form1';
import Geburtsurkunde from './screens/Geburtsurkunde/Geburtsurkunde';
import KundenInformationen from './screens/KundenInformationen';
import PostIdent from './screens/PostIdent';
import PostIdentSearch from './screens/PostIdentSearch';
import DeleteRegistration from './screens/DeleteRegistration';
import DeleteBusinessPartner from './screens/DeleteBusinessPartner';
import DeleteProdukt from './screens/DeleteProdukt';

library.add(faFileAlt, faThumbsUp, faThumbsDown, faThumbsUp, faThumbsDown, faTrashAlt, faTimes);

let awsConfig = awsConfigDev;
let env = 'dev'; // standard / develop

const prodRestUrl = 'https://l1q4todwch.execute-api.eu-central-1.amazonaws.com/prod/';

// default is DEV
window.quirion_rest = 'https://b2hfxzdg28.execute-api.eu-central-1.amazonaws.com/dev/';

if (process.env.NODE_ENV && process.env.REACT_APP_BRANCH === 'production') {
  awsConfig = awsConfigProd;
  env = 'prod';
  window.quirion_rest = prodRestUrl;
} else if (process.env.NODE_ENV && process.env.REACT_APP_BRANCH === 'test') {
  awsConfig = awsConfigTest;
  env = 'test';
}

const envHardToProd = false;
if (
  envHardToProd
  || (window.location && window.location.hostname && window.location.hostname.includes('preproduction')
  )) {
  awsConfig = awsConfigProd;
  env = 'prod';
  window.quirion_rest = prodRestUrl;
  alert('Achtung! Production!');
}


Amplify.configure(awsConfig);

class App extends Component {
  constructor(props) {
    super(props);

    const config = {};
    config.buckets = configBuckets[env];

    this.state = {
      config,
    };
  }

  componentDidMount() { }

  render() {
    const { config } = this.state;
    return (
      <BrowserRouter>
        <>
          <div>
            <Intro />
          </div>
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  // <Intro {...props} config={config} />
                  <KundenInformationen {...props} config={config} />
                )}
              />
              <Route
                path="/form1"
                render={props => (
                  <Form1 {...props} config={config} />
                )}
              />
              <Route
                path="/Geburtsurkunde"
                render={props => (
                  <Geburtsurkunde {...props} config={config} />
                )}
              />
              <Route
                path="/KundenInformationen"
                render={props => (
                  <KundenInformationen {...props} config={config} />
                )}
              />
              <Route
                path="/PostIdent"
                render={props => (
                  <PostIdent {...props} config={config} />
                )}
              />
              <Route
                path="/PostIdentSearch"
                render={props => (
                  <PostIdentSearch {...props} config={config} />
                )}
              />
              <Route
                path="/RegistrationLoeschen"
                render={props => (
                  <DeleteRegistration {...props} config={config} />
                )}
              />
              <Route
                path="/DeleteProdukt"
                render={props => (
                  <DeleteProdukt {...props} config={config} />
                )}
              />
              <Route
                path="/DeleteBusinessPartner"
                render={props => (
                  <DeleteBusinessPartner
                    {...props}
                    config={config}
                  />
                )}
              />
            </Switch>
          </div>
        </>
      </BrowserRouter>
    );
  }
}

export default withAuthenticator(App, true);
