import React, { Component } from 'react';
import GeburtsurkundeSimple from '../../../Geburtsurkunde/GeburtsurkundeWithoutSearch';


export default class DetailsGeburtsurkunde extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    if (this.props.person && this.props.person.cases && this.props.person.cases.length > 0) {
      let cases = this.props.person.cases;
      return (
        <div className="DetailsPerson__Geburtsurkune">
          { cases.map((item, index) => {
            if (/child/.test(item.accountType) && item.childExternalId) {
              return <GeburtsurkundeSimple key={`gbKunde_${index}`} case={item} reloadCase={id => this.props.reloadCase(id)} />;
            }
          })}
        </div>
      );
    }
    return null;
  }
}
