import React from 'react';
import { Link } from 'react-router-dom';
import './SideDrawer.css';
import Navigation from '../Navigation/Navigation';

const sideDrawer = props => {
  let drawerClasses = 'sideDrawer';
  if (props.show) {
    drawerClasses = 'sideDrawer open';
  }
  return (
    <nav className={drawerClasses}>
      <Navigation />
    </nav>
  );
};

export default sideDrawer
