import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';
import { num } from '@quirion/utils';
import ChangeBankAccount from './ChangeBankAccount';
import RegistrationProtocol from './RegistrationProtocol';
import RegProtokoll from './RegProtokoll';
import { doTestUser, isProduction } from '../../utils/doTestUser';

export default class DetailsProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedIps: {},
      openedBp: {},
      showChangeBankAccount: {},
      showRegistrationProtocol: {},
      protocols: [],
      isLoading: false,
      ipsTypeList: [
        { packageType: 'Change Product' },
      ],
    };
  }

  componentDidMount() {
    const { person } = this.props;
    if (person && person.user?.externalId) {
      this.loadAllIps();
    }
  }

  getDetailedIpsInfos(ips, bp) {
    const { person } = this.props;
    const outBpRow = [];

    if (bp.accountType && bp.accountType.includes('child')) {
      const childData = bp.users.filter((item) => item.role === 'owner');
      outBpRow.push(
        <div
          key={`${bp.businessPartnerId}_child`}
          className="DetailsProducts__content"
          style={{ flex: '1 0 20rem' }}
        >
          <h3 className="DetailsProducts__header">Kind</h3>
          <div className="flexTable__column">
            <div
              key="child__header"
              className="flexTable__row"
            >
              <div
                className="flexTable__row__header"
                style={{ flex: '1 0 4rem' }}
              >
                id
              </div>
              <div
                className="flexTable__row__header"
                style={{ flex: '1 0 8rem' }}
              >
                Nachname
              </div>
              <div
                className="flexTable__row__header"
                style={{ flex: '1 0 8rem' }}
              >
                Vorname
              </div>
            </div>
            {
              childData.map((child) => (
                <div
                  key={`child_id_${child.externalId}`}
                  className="flexTable__row"
                >
                  <div style={{ flex: '1 0 4rem' }}>{child.externalId}</div>
                  <div style={{ flex: '1 0 8rem' }}>{child.lastName}</div>
                  <div style={{ flex: '1 0 8rem' }}>{child.firstName}</div>
                </div>
              ))
            }
          </div>
        </div>,
      );
      const legalGuardians = bp.users.filter((item) => item.role !== 'owner');
      outBpRow.push(
        <div
          key={`${bp.businessPartnerId}_guardians`}
          className="DetailsProducts__content"
          style={{ flex: '1 0 33rem' }}
        >
          <h3 className="DetailsProducts__header">Erziehungsberechtigte/r</h3>
          <div className="flexTable__column">
            <div
              key="thead"
              className="flexTable__row"
            >
              <div className="flexTable__row__header" style={{ flex: '1 0 1rem' }}>id</div>
              <div className="flexTable__row__header" style={{ flex: '1 0 2rem' }}>Nachname</div>
              <div className="flexTable__row__header" style={{ flex: '1 0 2rem' }}>Vorname</div>
              <div className="flexTable__row__header" style={{ flex: '1 0 10rem' }}>E-Mail</div>
            </div>
            {
              legalGuardians.map((guardian) => (
                <div
                  key={`guardian_id_${guardian.externalId}`}
                  className="flexTable__row"
                >
                  <div style={{ flex: '1 0 1rem' }}>{guardian.externalId}</div>
                  <div style={{ flex: '1 0 2rem' }}>{guardian.lastName}</div>
                  <div style={{ flex: '1 0 2rem' }}>{guardian.firstName}</div>
                  <div style={{ flex: '1 0 10rem' }}>{guardian.communicationEmail}</div>
                </div>
              ))
            }
          </div>
        </div>,
      );
    }

    if (bp.accountType === 'jointAccount') {
      const otherOwner = bp.users.find((item) => (
        item.externalId !== person.user.externalId
      ));
      if (otherOwner) {
        outBpRow.push(
          <div
            key={`${bp.businessPartnerId}_otherowner`}
            className="DetailsProducts__content"
            style={{ flex: '0 0 30rem' }}
          >
            <h3 className="DetailsProducts__header">Weiterer Inhaber</h3>
            <div className="flexTable__column">
              <div className="flexTable__row">
                <div style={{ flex: '1 0 1rem' }}>id</div>
                <div style={{ flex: '1 0 2rem' }}>Nachname</div>
                <div style={{ flex: '1 0 2rem' }}>Vorname</div>
                <div style={{ flex: '1 0 10rem' }}>E-Mail</div>
              </div>
              <div className="flexTable__row">
                <div style={{ flex: '1 0 1rem' }}>{otherOwner.externalId}</div>
                <div style={{ flex: '1 0 2rem' }}>{otherOwner.lastName}</div>
                <div style={{ flex: '1 0 2rem' }}>{otherOwner.firstName}</div>
                <div style={{ flex: '1 0 10rem' }}>{otherOwner.communicationEmail}</div>
              </div>
            </div>
          </div>,
        );
      }
    }

    return outBpRow;
  }

  async getIpsConditions(ips) {
    axios
      .post(
        `${window.quirion_rest}condition`,
        {
          'advisor': ips.condition.advisor.toLowerCase(),
          'accountType': ips.condition.accountType,
        },
      )
      .then((response) => {
        const { listBps: prevListBps } = this.state;
        const listBps = [...prevListBps];
        listBps.forEach((bp, bpIdx) => {
          bp.ips.forEach((ipsItem, ipsIdx) => {
            if (ipsItem.ipsId === ips.ipsId) {
              const newIps = { ...ipsItem };
              newIps.conditions = this.mapIpsList(response.data);
              listBps[bpIdx].ips[ipsIdx] = newIps;
              this.setState({
                listBps,
              });
            }
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getProtocols(bp, key, prop) {
    const { person } = this.props;
    axios
      .post(
        `${window.quirion_rest}getpostbox`,
        {
          businessPartnerId: bp.businessPartnerId,
          username: person.user.username,
          status: 'ALL',
          from: '2000-01-01',
          to: moment().format('YYYY-MM-DD'),
        },
      )
      .then((res) => {
        this.setState({ protocols: res.data });
        this.toggleOpener(key, prop);
      })
      .catch((error) => console.log(error));
  }

  toggleOpener = (key, prop) => {
    this.setState((prevState) => ({
      [prop]: {
        ...prevState[prop],
        [key]: !prevState[prop][key],
      },
    }));
  }

  loadAllIps = () => {
    const bps = this.props.person.businessPartner;
    if (bps && bps.length) {
      this.setState({
        loadingBps: true,
        listBps: [],
      });
      bps.forEach((item) => {
        this.loadBusinessPartner(item.businessPartnerId);
      });
    }
  };

  mapIpsList = (ipsList) => {
    let newIpsList = ipsList;
    if (newIpsList?.length > 0) {
      newIpsList = newIpsList.map((ips) => {
        const conditionCharacters = ips.conditionId.split('_');
        const advisor = () => {
          if (conditionCharacters[0] === 'Q') return 'Quirion';
          if (conditionCharacters[0] === 'S') return 'Savedo';
          if (conditionCharacters[0] === 'X') return 'Xing';
        };
        const ipsType = () => {
          if (conditionCharacters[1] === 'VV.NH') return 'Nachhaltiges Portfolio';
          if (conditionCharacters[1] === 'AV.AV') return 'Altersvorsorge';
          if (conditionCharacters[1] === 'VV.VV') return 'Globales Portfolio';
          if (conditionCharacters[1] === 'VV.ME') return 'Meinung';
          if (conditionCharacters[1] === 'VV.VG') return 'Video Games';
          if (conditionCharacters[1] === 'VV.SC') return 'Smart Cities';
          if (conditionCharacters[1] === 'VV.SCD') return 'Semiconductors';
          if (conditionCharacters[1] === 'VV.ML') return 'Maschinelearning';
          if (conditionCharacters[1] === 'VV.IS') return 'Infrastructure';
          if (conditionCharacters[1] === 'VV.HI') return 'Healthcare';
          if (conditionCharacters[1] === 'VV.FT') return 'Fintech';
          if (conditionCharacters[1] === 'VV.EM') return 'E-Mobility';
          if (conditionCharacters[1] === 'VV.DI') return 'Digital Infrastructure';
          if (conditionCharacters[1] === 'VV.CS') return 'Cyber Security';
          if (conditionCharacters[1] === 'VV.CC') return 'Cloud Computing';
          if (conditionCharacters[1] === 'VV.CE') return 'Clean Energy';
          if (conditionCharacters[1] === 'VV.CW') return 'Canabis & Wellness';
          if (conditionCharacters[1] === 'VV.BA') return 'Batteries';
          if (conditionCharacters[1] === 'VV.TH') return 'Megatrends';
        };
        const accountType = () => {
          if (conditionCharacters[2] === 'SA') return 'singleAccount';
          if (conditionCharacters[2] === 'JA') return 'jointAccount';
          if (conditionCharacters[2] === 'CA') return 'childAccount';
        };

        const packageType = () => {
          if (conditionCharacters[3] === 'R') return 'Regular';
          if (conditionCharacters[3] === 'BA') return 'Basic Alt';
          if (conditionCharacters[3] === 'C') return 'Comfort';
          if (conditionCharacters[3] === 'CA') return 'Comfort Alt';
          if (conditionCharacters[3] === 'P') return 'Premium';
        };
        const additionalInfo = () => {
          if (conditionCharacters[4]) {
            if (conditionCharacters[4] === '0.38') return '0.38 %';
            if (conditionCharacters[4] === '0.68') return '0.68 %';
            if (conditionCharacters[4] === 'FF') return 'forFree';
          }
          return null;
        };
        return {
          ...ips,
          condition: {
            advisor: advisor(),
            ipsType: ipsType(),
            accountType: accountType(),
            packageType: packageType(),
            additionalInfo: additionalInfo(),
          },
        };
      });
    }
    return newIpsList;
  }

  async loadBusinessPartner(id) {
    this.setState({ isLoading: true });
    axios
      .post(
        `${window.quirion_rest}getbusinesspartner`,
        { businessPartnerId: parseInt(id, 10) },
      )
      .then((response) => {
        const bps = response.data;
        bps.ips = this.mapIpsList(bps.ips);
        this.setState((prevState) => ({
          isLoading: false,
          listBps: [].concat(
            prevState.listBps,
            [{
              ...bps,
              businessPartnerId: id,
            }],
          ),
        }));
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  closeIps(bpId, ipsId) {
    const confirmed = window.confirm(`Möchten Sie löschen IPS mit einer externe Id ${ipsId}`);
    if (confirmed) {
      axios
        .post(
          `${window.quirion_rest}ipsclose`,
          {
            businessPartnerId: parseInt(bpId, 10),
            ipsId: parseInt(ipsId, 10),
          },
        )
        .then(() => {
          this.loadAllIps();
          alert(`Ips mit einer externe ID: ${ipsId} wurde erfolgreich geschlossen!`);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  closeBp(bpId) {
    const confirmed = window.confirm(`Möchten Sie löschen Business Partner mit einer Id ${bpId}`);
    if (confirmed) {
      axios
        .post(
          `${window.quirion_rest}bpclose`,
          {
            businessPartnerId: parseInt(bpId, 10),
          },
        )
        .then(() => {
          this.loadAllIps();
          alert(`Business Partner  mit einer ID: ${bpId} wurde erfolgreich geschlossen!`);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async changeIpsAccountType(event, bp, ips) {
    const conditionChoice = {
      'businessPartnerId': parseInt(bp, 10),
      'ipsId': parseInt(ips.ipsId, 10),
      'conditionId': ips.conditions[event.target.options.selectedIndex].conditionId,
    };
    const confirmed = window.confirm(
      `Wollen Sie ${ips.conditionId}, zum ${conditionChoice.conditionId} Produkt wechseln?`,
    );

    if (confirmed) {
      axios
        .post(
          `${window.quirion_rest}kubechangepackage`,
          conditionChoice,
        )
        .then((response) => {
          this.loadAllIps();
        })
        .catch((error) => {
          console.error(`
            Request: ${error.config?.data}
            Response: ${JSON.stringify(error.response?.data?.error)}
          `);
        });
    } else {
      console.log(`${ips.conditionId} Package request canceled!`);
    }
  }

  render() {
    const { person } = this.props;
    const {
      listBps,
      openedBp,
      showChangeBankAccount,
      showRegistrationProtocol,
      protocols,
      isLoading,
    } = this.state;
    const markup = [];
    if (person.businessPartner && listBps) {
      for (let i = 0; i < listBps.length; i++) { // loop bps
        const bp = {
          ...listBps[i],
          ...person.businessPartner.find((bpItem) => (
            bpItem.businessPartnerId === listBps[i].businessPartnerId
          )),
        };
        const bpKey = `bp_item_${bp.businessPartnerId}_${i}`;
        const isBpOpen = !((bp.status === 'INCLOSE' || bp.status === 'CLOSED'));
        const isRegistrationModalOpen = Object.values(showRegistrationProtocol).find(
          (el) => el === true,
        );
        const isRefereneModalOpen = Object.values(showChangeBankAccount).find(
          (el) => el === true,
        );

        const isBloxxter = bp.provider === 'BLOXXTER';

        markup.push(
          <>
            <div
              key={`${bpKey}_content`}
              className={
                `DetailsProducts__content ${bp.status} \
                ${(isRegistrationModalOpen || isRefereneModalOpen) && 'on-blur'}
                `
              }
            >
              <div
                className="DetailsProducts__bp"
                key={`${bpKey}_bp`}
              >
                <div
                  className="DetailsProducts__bp__descType"
                  key={`${bpKey}_${i}_id&type`}
                >
                  <div>
                    {'BP ID / '}
                    {bp.provider}
                  </div>
                  <div>
                    {`${bp.businessPartnerId} / ${bp.accountType}`}
                  </div>
                </div>
                {(bp.reference || bp.payout) && (
                  <div
                    className="DetailsProducts__bp__descIban"
                    key={`${bpKey}_${i}_referenceaccount`}
                  >
                    <div>{isBloxxter ? 'Konto für Zinsgutschriften' : 'Referenzkonto'}</div>
                    <div>
                      {`${bp.reference?.iban || bp.payout?.iban || 'k.A.'} \
                      ${bp.reference?.bic || bp.payout?.bic || ''} \
                      ${bp.reference?.name || bp.payout?.name || ''} \
                      ${bp.reference?.accountHolder || bp.payout?.accountHolder || ''}
                      `}
                    </div>
                    {
                      isBloxxter && (
                        <>
                          <br />
                          <div>Konto für Kosten</div>
                          <div>
                            {`${bp.fee?.iban || 'k.A.'} \
                            ${bp.fee?.bic || ''} \
                            ${bp.fee?.name || ''} \
                            ${bp.fee?.accountHolder || ''}
                            `}
                          </div>
                        </>
                      )
                    }
                  </div>
                )}
                <div
                  className="DetailsProducts__bp__balanceDate"
                  key={`${bpKey}_${i}_balanceDate`}
                >
                  <div>Letzte Aktualisierung</div>
                  <Moment format="YYYY/MM/DD HH:mm:ss">
                    {bp.balanceDate || bp.updatedAt}
                  </Moment>
                </div>
                {isBpOpen && !isBloxxter && (
                  <>
                    <button
                      type="button"
                      className="
                    DetailsProducts__bp__button
                    DetailsProducts__bp__button__RegProtocol
                    "
                      onClick={() => this.getProtocols(bp, bpKey, 'showRegistrationProtocol')}
                      key={`${bpKey}_${i}_showRegistrationProtocol`}
                    >
                      Registrierungsprotokol
                    </button>
                    <button
                      type="button"
                      className="DetailsProducts__bp__button DetailsProducts__bp__button__RefChange"
                      onClick={() => this.toggleOpener(bpKey, 'showChangeBankAccount')}
                      key={`${bpKey}_${i}_changeAccountButton`}
                    >
                      Referenzkonto ändern
                    </button>
                    <button
                      type="button"
                      className="DetailsProducts__bp__button DetailsProducts__bp__button__Delete"
                      onClick={() => this.closeBp(bp.businessPartnerId)}
                      key={`${bpKey}_${i}_closeButton`}
                    >
                      BP schließen
                    </button>
                  </>
                )}
                <button
                  type="button"
                  className="button__symbol DetailsProducts__bp__button DetailsProducts__bp__button__Open"
                  onClick={() => this.toggleOpener(bpKey, 'openedBp')}
                  key={`${bpKey}_${i}_toggleOpenButton`}
                >
                  {openedBp[bpKey] ? '➖' : '➕'}
                </button>
              </div>
              {bp.ips.length > 0 && bp.ips.map((ips) => {
                const ipsKey = `ips_item${ips.ipsId}`;
                const isIpsOpen = !((ips.status === 'INCLOSE' || ips.status === 'CLOSED'));

                return (
                  <div
                    key={ipsKey}
                    className={
                      `DetailsProducts__content DetailsProducts__ips ${openedBp[bpKey]
                        ? 'opened '
                        : 'closed '
                      }${ips.status}`
                    }
                  >
                    <div className={`DetailsProducts__ips__group ${ips.status}`}>
                      <div className="DetailsProducts__ips__desc">
                        <div>
                          {`${ips.condition.advisor} - \
                                      ${ips.condition.ipsType} - \
                                      ${ips.condition.accountType} - \
                                      ${ips.condition.packageType}
                        `}
                          {ips.condition.additionalInfo !== null
                            && ` - ${ips.condition.additionalInfo}`}
                          {' / '}
                          {ips.conditionId ? `(${ips.conditionId}) / ` : '(keine Kondition) / '}
                          {`${ips.strategy} / `}
                          {`${ips.ipsId} / ${ips.status} / `}
                          {ips.balanceDate && (
                            <>
                              {'Letzte Aktualisierung: '}
                              <Moment format="YYYY/MM/DD HH:mm:ss">
                                {ips.balanceDate}
                              </Moment>
                              {' / '}
                            </>
                          )}
                          {ips.status === 'CLOSED' ? (
                            <>
                              {'Auflösungsdatum: '}
                              <Moment format="YYYY/MM/DD HH:mm:ss">
                                {ips.balanceClosedDate}
                              </Moment>
                              {` / AuM bei Auflösung: ${ips.balanceClosed} €`}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="DetailsProducts__ips__button__Close">
                          <RegProtokoll person={person} bp={bp} ips={ips} />
                          <>
                            {isIpsOpen && (
                              <button
                                type="button"
                                className="DetailsProducts__ips__button"
                                onClick={() => this.closeIps(bp.businessPartnerId, ips.ipsId)}
                              >
                                IPS Schließen
                              </button>
                            )}
                          </>
                          {ips.status === 'PENDING' && !isProduction && (
                            <button
                              type="button"
                              className="DetailsProducts__ips__button"
                              onClick={() => doTestUser.ips(ips.ipsId)}
                            >
                              Activate IPS
                            </button>
                          )}
                        </div>
                      </div>
                      {
                        (
                          ips.status === 'OPEN'
                          || ips.status === 'WAITMONEY'
                          || ips.status === 'PENDING'
                        ) && (
                          <div>
                            <span>
                              {'Produkt wechseln: '}
                            </span>
                            <select
                              className="DetailsProducts__ips__button"
                              value={ips.conditionId}
                              name="productType"
                              onClick={() => this.getIpsConditions(ips)}
                              onChange={(event) => this.changeIpsAccountType(
                                event, bp.businessPartnerId, ips,
                              )}
                            >
                              {
                                ips.conditions ? ips.conditions.map(
                                  (ipsType) => (
                                    <option
                                      key={`${ipsType.conditionId} key`}
                                      value={ipsType.conditionId}
                                    >
                                      {`${ipsType.condition.advisor} - \
                                  ${ipsType.condition.ipsType} - \
                                  ${ipsType.condition.accountType} - \
                                  ${ipsType.condition.packageType}
                                `}
                                      {ipsType.condition.additionalInfo !== null
                                        && ` - ${ipsType.condition.additionalInfo}`}
                                    </option>
                                  ),
                                )
                                  : (
                                    <option key="Default Option">
                                      Klicken Sie hier, um Optionen zu laden...
                                    </option>
                                  )
                              }
                            </select>
                          </div>
                        )
                      }
                    </div>
                    <div className="DetailsProducts__ips__content">{this.getDetailedIpsInfos(ips, bp)}</div>
                  </div>
                );
              })}
              {bp.assets.length > 0 && bp.assets.map((asset) => {
                const productKey = `ips_item${asset.assetId}`;
                const isAssetOpen = !((asset.status === 'INCLOSE' || asset.status === 'CLOSED'));

                return (
                  <div
                    key={productKey}
                    className={
                      `DetailsProducts__content DetailsProducts__ips ${openedBp[bpKey]
                        ? 'opened '
                        : 'closed '
                      }${asset.status}`
                    }
                  >
                    <div className={`DetailsProducts__ips__group ${asset.status}`}>
                      <div className="DetailsProducts__ips__desc">
                        <div>
                          {/* balance is the equivalent of Wert but backend can't calculate the
                          amount for kube and it displays it as the Wert without added
                          interests. That's why we display it as Investiert. */}

                          {`${asset.conditionId} / ${asset.assetId} `}
                          {`/  Investiert: ${num.toMoney(asset.balance)}`}
                          {`/ ${asset.status} `}
                          {`/ Erstellt am: ${new Date(asset.createdAt).toLocaleDateString()}`}
                          {`${new Date(asset.createdAt).toLocaleTimeString()}`}
                        </div>
                        <div className="DetailsProducts__ips__button__Close">
                          <RegProtokoll person={person} bp={bp} ips={asset} />
                          {/* <>
                            {isAssetOpen && (
                              <button
                                type="button"
                                className="DetailsProducts__ips__button"
                                onClick={() => this.closeIps(bp.businessPartnerId, asset.assetId)}
                              >
                                Asset Schließen
                              </button>
                            )}
                          </> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <RegistrationProtocol
              trigger={() => this.toggleOpener(bpKey, 'showRegistrationProtocol')}
              show={showRegistrationProtocol[bpKey]}
              protocols={protocols}
            />
            {showChangeBankAccount[bpKey] && (
              <ChangeBankAccount
                key={`cba_${bp.businessPartnerId}`}
                businessPartnerId={bp.businessPartnerId}
                onChange={this.loadAllIps}
                onCancel={() => this.toggleOpener(bpKey, 'showChangeBankAccount')}
              />
            )}
          </>,
        );
      }
    }

    return (
      <div className={`DetailsProducts ${isLoading ? ' -loading' : ''}`}>
        {markup}
      </div>
    );
  }
}
