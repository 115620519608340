import React, { Component } from 'react';

export default class TabNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
    };
  }

  onClickTab(arg) {
    // console.log(arg);
    this.setState({ activeTab: arg });
    this.props.onTabSelect(arg);
  }

  render() {
    return (
      <table className="dataFolders">
        <tbody>
          <tr>
            <td
              onClick={() => this.onClickTab(1)}
              className={this.state.activeTab === 1 ? 'activeTab' : ''}
            >
              Person
            </td>
            <td
              onClick={() => this.onClickTab(2)}
              className={this.state.activeTab === 2 ? 'activeTab' : ''}
            >
              Sparpläne
            </td>
            <td
              onClick={() => this.onClickTab(3)}
              className={this.state.activeTab === 3 ? 'activeTab' : ''}
            >
              BP - IPS
            </td>
            <td
              onClick={() => this.onClickTab(7)}
              className={this.state.activeTab === 7 ? 'activeTab' : ''}
            >
              Cases
            </td>
            <td
              onClick={() => this.onClickTab(4)}
              className={this.state.activeTab === 4 ? 'activeTab' : ''}
            >
              Passwort setzen
            </td>
            {/* <td
              onClick={() => this.onClickTab(5)}
              className={this.state.activeTab === 5 ? 'activeTab' : ''}
            >
              Registration löschen
            </td> */}
            <td
              onClick={() => this.onClickTab(6)}
              className={this.state.activeTab === 6 ? 'activeTab' : ''}
            >
              Kundenaufträge
            </td>
            <td
              onClick={() => this.onClickTab(9)}
              className={this.state.activeTab === 9 ? 'activeTab' : ''}
            >
              Gutscheinkarten
            </td>
            <td
              onClick={() => this.onClickTab(10)}
              className={this.state.activeTab === 10 ? 'activeTab' : ''}
            >
              Aktionscodes
            </td>
            {this.props.isChildCase === true
              && (
                <td
                  onClick={() => this.onClickTab(8)}
                  className={this.state.activeTab === 8 ? 'activeTab' : ''}
                >
                  Geburtsurkunde(n)
                </td>
              )}
          </tr>
        </tbody>
      </table>
    );
  }
}
