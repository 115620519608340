import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';

export default class DeleteProdukt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: '',
      errorProductId: '',
      form_is_submitting: false,
    };
  }

  async deleteBusinessPartner() {
    let inputData = {
      productId: this.state.productId,
    };

    let response = await API.graphql(
      graphqlOperation(mutations.kubeDeleteProduct, inputData)
    );
    console.log(response);
  }

  checkForm = event => {
    event.preventDefault();
    let errorProductId = false;
    let errorExists = false;
    let regExProductId = /^\d{8}$/;

    if (regExProductId.test(this.state.productId) === false) {
      this.setState({
        errorProductId: 'Bitte geben Sie eine gültige Produkt ID ein!',
      });
      errorExists = true;
    } else {
      this.setState({ errorProductId: '' });
    }

    if (errorExists) {
      console.log('do nothing');
    } else {
      console.log('jsonaction');
      if (this.state.form_is_submitting === false) {
        this.setState({ form_is_submitting: true });
        this.deleteProdukt();
      }
    }
  };

  inputHandler = event => {
    // console.log('Was changed', event.target);
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="forms form2">
          <h2>Delete Produkt</h2>
          <form onSubmit={this.checkForm} noValidate>
            <label>
              Produkt ID
              <input
                onChange={this.inputHandler}
                value={this.state.productId}
                type="text"
                name="productId"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <div className="errorMessage">{this.state.errorProductId}</div>
            <button type="submit">Absenden</button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
