// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify.
// It will be overwritten.

const awsmobile = {
  'aws_project_region': 'eu-central-1',

  'aws_cognito_identity_pool_id': 'eu-central-1:309b72dd-99a0-43d1-8dbf-f4d22ca31367',
  'aws_user_pools_id': 'eu-central-1_JkQ1kHdaD',
  'aws_user_pools_web_client_id': '3lgevluuri27nmlgi96jgo7utg',

  //    "aws_cognito_identity_pool_id": "eu-central-1:66bfb254-a69d-4b2c-8a0a-2e3be0e73b35",
  //    "aws_user_pools_id": "eu-central-1_KBKVZ0iGy",
  //    "aws_user_pools_web_client_id": "rucm82fs32gsbo77mqmohhhid",

  'aws_cognito_region': 'eu-central-1',
  'oauth': {},
  'aws_appsync_graphqlEndpoint': 'https://iknwp5dqhfcqlpjdeqrdc645w4.appsync-api.eu-central-1.amazonaws.com/graphql',
  'aws_appsync_region': 'eu-central-1',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  'aws_cloud_logic_custom': [
    {
      'name': 'quirionrest',
      'endpoint': 'https://hieuzh9hf6.execute-api.eu-central-1.amazonaws.com/dev',
      'region': 'eu-central-1',
    },
  ],
  // "aws_mobile_analytics_app_id": "a6e9e97b1b834910874134e549c0c52d",
  // "aws_mobile_analytics_app_region": "eu-central-1",
  'aws_user_files_s3_bucket': 'quirion-postfach-dev',
  'aws_postident_s3_bucket': 'quirion-owl-dev-post-idents',
  'aws_user_files_s3_bucket_region': 'eu-central-1',
};

export default awsmobile;
