import React, { Component } from 'react';
import { Storage } from 'aws-amplify';

export default class ChangeBankAccount extends Component {
  constructor(props) {
    super();
    this.state = {
      file: false,
    };
  }

  async openProtokoll() {
    Storage.get(
      this.state.file,
      {
        level: 'private',
        identityId: this.props.person.user.externalId,
        expires: 360,
      },
    )
      .then((result) => {
        console.log(`Storage Get for ${this.props.person.user.externalId}: ${JSON.stringify(result)}`)
        let url = result;
        var win = window.open(url, '_blank');
        win.focus();
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  componentDidMount() {
    this.getRegProtokollButton()
  }

  getRegProtokollButton() {
    Storage.list('', {
      level: 'private',
      identityId: this.props.person.user.externalId,
    })
      .then((result) => {
        console.log(`Storage List for ${this.props.person.user.externalId}: ${JSON.stringify(result)}`)
        result.forEach((element) => {
          if (element.key.indexOf('Registrierungsprotokoll_' + this.props.product.id) >= 0) {
            this.setState({ file: element.key })
          }
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    var divStyle = {
      marginLeft: '10px',
    };

    let button = <></>
    if (this.state.file) {
      button = (
        <button
          style={divStyle}
          className="buttonAccountChange"
          onClick={() => this.openProtokoll()}
        >
          Registrierungsprokoll anzeigen
        </button>
      )
    }

    return (
      <React.Fragment>
        {button}
      </React.Fragment>
    );
  }
}
