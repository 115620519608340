import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { num } from '@quirion/utils';
import axios from 'axios';
import {
  SegmentedControl,
  Radio,
  SelectField,
  InputField,
  CheckboxField,
} from '@quirion/components';
import { Grid } from 'gridjs-react';
import { prepareDataForValidation, validateYupSchema } from 'formik';
import { SseKmsEncryptedObjects } from '@aws-sdk/client-s3';

const useCouponHistory = () => {
  const [user, setUser] = useState(Object);
  const [casesTableData, setCasesTableData] = useState([]);
  const [ipsCouponList, setIpsCouponList] = useState([]);
  const [ipsCouponTableData, setIpsCouponTableData] = useState([]);
  const [view, setView] = useState('ips');
  const [isLoading, setIsLoading] = useState(false);
  const [ipsList, setIpsList] = useState([]);

  const [selectedIps, setSelectedIps] = useState('');
  const [couponToActivate, setCouponToActivate] = useState(Object);
  const [couponOptions, setCouponOptions] = useState([]);
  const [kwk, setKwk] = useState(false);
  const [customCoupon, setCustomCoupon] = useState('');

  const canBeActivated = () => {
    if (kwk) {
      if (selectedIps && customCoupon.length > 0) return true;
      return false;
    }
    if (couponToActivate?.pk && selectedIps) return true;
    return false;
  };

  const resetCouponActivationValues = () => {
    setCustomCoupon('');
    setKwk(false);
    setCouponToActivate('');
    setSelectedIps('');
  };

  const loadIpsCoupons = (ips) => {
    axios
      .post(
        `${window.quirion_rest}getips`,
        { ipsId: parseInt(ips.ipsId, 10) },
      )
      .then((response) => {
        if (response.data?.coupons?.length > 0) {
          response.data.coupons.map((coupon) => {
            setIpsCouponList((prevList) => [...prevList, {
              ...coupon,
              ipsId: ips.ipsId,
              bpId: ips.bpId,
            }]);
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getIpsFromPersonBPs = (bps) => {
    if (bps !== undefined && bps.length > 0) {
      setIpsCouponList([]);
      setIpsList([]);
      setIsLoading(true);
      bps.forEach((bp) => {
        axios.post(
          `${window.quirion_rest}getbusinesspartner`,
          { 'businessPartnerId': parseInt(bp.businessPartnerId, 10) },
        )
          .then((response) => {
            if (response.data.ips?.length > 0) {
              response.data.ips.map((ipsItem) => {
                loadIpsCoupons({
                  ...ipsItem,
                  bpId: bp.businessPartnerId,
                });
                setIpsList((prevList) => [...prevList, ipsItem]);
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(`While loading Ips's in Voucher History got an error: ${error}`);
          })
          .finally(() => setIsLoading(false));
      });
    }
  };

  const activateCoupon = (businessPartner) => {
    const data = {
      'user': user.username,
      'coupon': kwk ? customCoupon : couponToActivate.pk.replace('COUPON#', ''),
      'isKwk': kwk,
      'ips': selectedIps,
    };

    axios.post(
      `${window.quirion_rest}activatecoupon`,
      data,
    )
      .then((response) => {
        if (response.data.length > 0) setIpsList([...response.data]);
        getIpsFromPersonBPs(businessPartner);
        // resetCouponActivationValues();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(`While loading Ips's in Voucher History got an error: ${error}`);
        resetCouponActivationValues();
      })
      .finally(() => setIsLoading(false));
  };

  const getCouponOptions = () => {
    setCouponOptions([]);
    setIsLoading(true);
    axios.post(`${window.quirion_rest}coupons`)
      .then((response) => {
        if (response.data.length > 0) setCouponOptions([...response.data]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(`While loading Coupon options got an error: ${error}`);
      })
      .finally(() => setIsLoading(false));
  };

  // const getUserVouchers = () => {
  //   const currentIpsVouchers = [];
  //   setIsLoading(true);
  //   couponList.forEach((ips) => {
  //     axios.post(
  //       `${window.quirion_rest}getorder`,
  //       {
  //         'username': user.username,
  //         'businessPartner': false,
  //         'ips': true,
  //         'ipsId': ips.ipsId,
  //         'range': parseInt(7300, 10), // TODO add dropdown for time
  //         'status': 'ALL',
  //       },
  //     )
  //       .then((response) => {
  //         setIsLoading(false);
  //         const orders = response.data.ips;
  //         const ordersWithVouchers = orders.filter(
  //           (order) => Object.prototype.hasOwnProperty.call(order, 'voucher'),
  //         ).map((orderVoucher) => ({
  //           ...orderVoucher,
  //           createdAt: moment(orderVoucher.createdAt).format('YYYY/MM/DD HH:mm:ss'),
  //         }));
  //         if (ordersWithVouchers.length > 0) {
  //           currentIpsVouchers.push(ordersWithVouchers);
  //           setIpsVouchers(currentIpsVouchers.flat());
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setIsLoading(false);
  //       });
  //   });
  // };

  const switchHistoryType = (e) => setView(e.target.value);

  return {
    user,
    setUser,
    casesTableData,
    setCasesTableData,
    ipsCouponTableData,
    setIpsCouponTableData,
    ipsCouponList,
    getIpsFromPersonBPs,
    switchHistoryType,
    view,
    isLoading,
    ipsList,
    selectedIps,
    setSelectedIps,
    couponToActivate,
    setCouponToActivate,
    activateCoupon,
    couponOptions,
    getCouponOptions,
    setKwk,
    kwk,
    setCustomCoupon,
    customCoupon,
    canBeActivated,
  };
};

export const CouponHistory = (props) => {
  const { person } = props;
  const {
    setUser,
    casesTableData,
    setCasesTableData,
    ipsCouponTableData,
    setIpsCouponTableData,
    ipsCouponList,
    getIpsFromPersonBPs,
    switchHistoryType,
    view,
    isLoading,
    ipsList,
    selectedIps,
    setSelectedIps,
    couponToActivate,
    setCouponToActivate,
    activateCoupon,
    couponOptions,
    getCouponOptions,
    setKwk,
    kwk,
    setCustomCoupon,
    customCoupon,
    canBeActivated,
  } = useCouponHistory();

  // load coupon options
  useEffect(() => getCouponOptions(), []);

  // load user data
  useEffect(() => setUser({ ...person.user }), [person.user]);

  // get ips from persons bp via getbusinesspartner and run getIps on each to load coupons from ips
  useEffect(() => getIpsFromPersonBPs(person.businessPartner), []);

  // useEffect(() => {
  //   if (person.cases?.length > 0) {
  //     // filter cases with voucher
  //     const casesWithCoupons = person.cases.filter(
  //       (c) => Object.prototype.hasOwnProperty.call(c, 'coupons'),
  //     );
  //     // map vouchers with data from case and return the vouchers
  //     const caseCoupons = casesWithCoupons.map((caseCoupon) => (
  //       caseCoupon.coupons.map((coupon) => ({
  //         amount: num.toMoney(coupon.amount),
  //         serialNumber: coupon.serialNumber,
  //         caseId: coupon.caseId,
  //         ipsId: coupon.ipsId || '',
  //         createdAt: moment(coupon.createAt).format('YYYY/MM/DD HH:mm:ss'),
  //       }))
  //     )).flat();
  //     if (caseCoupons.length > 0) setCasesTableData(caseCoupons);
  //   }
  // }, [person.cases]);

  useEffect(() => {
    let tableData = [];
    if (ipsCouponList.length > 0) {
      tableData = ipsCouponList.map((item) => ({
        createdAt: moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss'),
        ipsId: item.ipsId,
        bpId: item.bpId,
        serialNumber: item.sortKey.replace('COUPON#', ''),
        confirmed: item.confirmed,
      }));
      setIpsCouponTableData(tableData);
    }
  }, [ipsCouponList]);

  return (
    <div className="orderHistory couponHistory">
      <div className={`orderHistory__content ${isLoading ? ' -loading' : ''}`}>
        {/* <SegmentedControl
          className="couponHistory__segmentControl"
          name="TopupTypeControl"
          onChange={switchHistoryType}
          value={view}
          style={{ width: '100%' }}
        >
          <Radio
            key="view_cases"
            name="view_cases"
            value="cases"
            label="Cases"
          />
          <Radio
            key="view_voucher"
            name="view_voucher"
            value="ips"
            label="Ips"
          />
        </SegmentedControl> */}
        {/* {((view === 'cases') && casesTableData.length > 0) && (
          <Grid
            data={casesTableData}
            columns={[
              { id: 'caseId', name: 'Case Id' },
              { id: 'serialNumber', name: 'Serienummer' },
              { id: 'amount', name: 'Wert' },
              { id: 'ipsId', name: 'Ips Id' },
              { id: 'createdAt', name: 'Erstellt' },
            ]}
            className={{
              container: 'table',
            }}
            search
            sort
            pagination={{
              enabled: true,
              limit: 30,
            }}
            language={{
              'search': {
                'placeholder': 'Suchen...',
              },
              'pagination': {
                'previous': 'zurück',
                'next': 'weiter',
                'showing': 'Zeigen',
                'results': () => 'Ergebnisse',
                'to': 'bis',
                'of': 'von',
              },
            }}
            autoWidth
          />
        )} */}
        {((view === 'ips')
        //  && ipsCouponTableData.length > 0
        )
         && (
         <>
           <div className="card">
             <h2>gebrauchte Coupons:</h2>
             <Grid
               data={ipsCouponTableData}
               columns={[
                 { id: 'ipsId', name: 'Ips Id' },
                 { id: 'bpId', name: 'BP Id' },
                 { id: 'serialNumber', name: 'Serienummer' },
                 {
                   id: 'confirmed',
                   name: 'Bestätigt',
                   formatter: (cell) => (cell ? 'Ja' : 'Nein'),
                 },
                 { id: 'createdAt', name: 'Erstellt' },
               ]}
               className={{
                 container: 'table',
               }}
                // search
               sort
               pagination={{
                 enabled: true,
                 limit: 30,
               }}
               language={{
                 // 'search': {
                 //   'placeholder': 'Suchen...',
                 // },
                 'pagination': {
                   'previous': 'zurück',
                   'next': 'weiter',
                   'showing': 'Zeigen',
                   'results': () => 'Ergebnisse',
                   'to': 'bis',
                   'of': 'von',
                 },
               }}
               autoWidth
             />
           </div>
           <div className="card">
             <h2>Coupon Aktivieren:</h2>
             <div className="activateCoupon_container">
               <SelectField
                 id="selectedIps"
                 className="fieldElement"
                 label="Ips wahlen"
                 name="selectedIps"
                 size="sm"
                 value={selectedIps}
                 onChange={(e) => setSelectedIps(parseInt(e.target.value, 10))}
                 required
               >
                 {ipsList.length > 0
                   ? <option selected disabled>Ips wählen</option>
                   : <option selected disabled>keine Ips</option>}
                 {ipsList.map((e, i) => (
                   <option
                     key={`${e.createdAt}_${i + 1}`}
                     value={e.ipsId}
                     disabled={e.status !== 'OPEN'}
                   >
                     { e.customName
                       ? `${e.customName} - }${e.name} / ${e.ipsId} / Status: ${e.status}`
                       : `${e.name} - ${e.ipsId} / Status: ${e.status}`}
                   </option>
                 ))}
               </SelectField>
               <label>
                 <input
                   type="checkbox"
                   checked={kwk}
                   onChange={() => setKwk(!kwk)}
                 />
                 KWK (individueller Coupon)
               </label>
               <InputField
                 id="customCoupon"
                 type="text"
                 className="fieldElement"
                 label="Kwk Coupon"
                 name="customCoupon"
                 value={customCoupon}
                 onChange={(e) => setCustomCoupon(e.target.value)}
                 disabled={!kwk}
                 required={kwk}
               />
               <SelectField
                 id="couponToActivate"
                 className="fieldElement"
                 label="Aktionscode"
                 name="couponToActivate"
                 value={couponToActivate}
                 onChange={(e) => setCouponToActivate(JSON.parse(e.target.value))}
                 disabled={kwk}
                 required={!kwk}
               >
                 {couponOptions.length > 0
                   ? <option selected disabled>Coupon wählen</option>
                   : <option selected disabled>keine Coupons</option>}

                 {couponOptions.map((e, i) => (
                   <option key={`CouponID_${i + 1}`} value={JSON.stringify(e)}>
                     { `${e.pk.replace('COUPON#', '')} \
                       / Valid: ${moment(e.validFrom).format('YYYY/MM/DD')} - \
                       ${moment(e.validTo).format('YYYY/MM/DD')} \
                       / Times used: ${e.timesUsed} \
                       / Usage limit: ${e.usageLimit}
                      `}
                   </option>
                 ))}
               </SelectField>
               {(couponToActivate?.terms && !kwk)
                  && <span className="couponTerms">{couponToActivate?.terms}</span>}
               <button
                 role="button"
                 onClick={() => activateCoupon(person.businessPartner)}
                 disabled={!canBeActivated()}
               >
                 Aktivieren
               </button>
             </div>
           </div>

         </>
         )}
      </div>
    </div>
  );
};
