import React, { Component } from 'react';

export default class SearchResults extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    let arrOut = [];
    if (this.props.listPersons) {
      for (let i = 0; i < this.props.listPersons.length; i++) {
        let element = this.props.listPersons[i];
        if (element) {
          arrOut.push(
            <div
              key={i}
              className="listElement"
              onClick={() => this.props.onPersonSelected(element.externalId)}
            >
              <div>{element.username}</div>
              <div>{element.salutation}</div>
              <div>{element.firstName}</div>
              <div>{element.lastName}</div>
              <div>{element.city}</div>
              <div>{element.birthPlace}</div>
              <div>{element.birthDate}</div>
              <div>{element.telLandline || element.telMobile}</div>
              <div>{element.externalId}</div>
            </div>
          );
        }
        else {
          arrOut.push(
            <div
              key={i}
              className="listElement"
            >
              <div>unbekannt</div>
              <div>unbekannt</div>
              <div>unbekannt</div>
              <div>unbekannt</div>
              <div>unbekannt</div>
              <div>unbekannt</div>
              <div>unbekannt</div>
              <div>unbekannt</div>
              <div>unbekannt</div>
            </div>
          );
        }
      }
    }

    return (
      <div className="SearchResults">
        <div className="table">
          <div className="thead">
            <div>ID</div>
            <div>Anrede</div>
            <div>Vorname</div>
            <div>Nachname</div>
            <div>Wohnort</div>
            <div>Geburtsort</div>
            <div>Geburtsdatum</div>
            <div>Telefon</div>
            <div>QuirionId</div>
          </div>
          {arrOut}
        </div>
      </div>
    );
  }
}
