import React, { Component } from 'react';

export default class SearchInput extends Component {
  constructor(props) {
    super();
    this.state = {
      errorId: '',
      searchValue: '',
      form_is_submitting: false,
    };
  }

  checkSubmitHandler = (event) => {
    event.preventDefault();
    let errorExists = false;
    const input = this.state.searchValue.trim();
    if (input.length < 3) {
      this.setState({ errorId: 'Please enter a valid email address' });
      errorExists = true;
    } else {
      this.setState({ errorId: '' });
    }

    if (!errorExists && !this.state.form_is_submitting) {
      this.props.onSearch(input);
    }
  };

  inputHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div className="SearchInput">
        <form className="form3flex" onSubmit={this.checkSubmitHandler}>
          <label style={{ marginLeft: '15px' }}>
            Suchkriterium
            <input
              className="inputform3"
              type="text"
              name="searchValue"
              onChange={this.inputHandler}
              value={this.state.searchValue}
            >
            </input>
            <div className="errorMessage">{this.state.errorId}</div>
          </label>
          <button
            className="form3button"
            type="submit"
            onClick={() => {
              this.setState({ activeTab: 1 });
            }}
          >
            Absenden
            {/* {is_submittingPersons} */}
            {/* implement loading spinner
              is the state loading - show loading text */}
            {this.state.form_is_submitting && <div>Loading</div>}
          </button>
        </form>
      </div>
    );
  }
}
