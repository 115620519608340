import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import * as mutations from '../../../../../graphql/mutations';


export default class DetailsDeleteRegistration extends Component {
  constructor(props) {
    super();
    this.state = {
      form_is_submitting: false,
    };
  }

  async deleteRegistration() {
    let inputData = {
      email: this.props.person.id,
    };

    let response = await API.graphql(
      graphqlOperation(mutations.kubeDeleteRegistration, inputData)
    );

    if (response && response.data && response.data.kubeDeleteRegistration) {
      let answer = JSON.parse(response.data.kubeDeleteRegistration);
      console.log(response, answer);

      if (answer.status) {
        alert('Gut gemacht! Person ist gelöscht!');
        // eslint-disable-next-line no-restricted-globals
        // clearing all input fields after successful submission
        this.setState({ email: '' });
      } else {
        alert(answer.message);
      }
    } else {
      alert('Etwas ist schief gelaufen!');
    }

    this.setState({ form_is_submitting: false });
  }

  render() {
    return (
      <div className="DetailsDeleteRegistration">
        <div>
          <div className="sicherMsg">
            Sind Sie sicher, dass Sie Registration löschen möchten?
          </div>
          <button
            className="deleteBtn"
            type="submit"
            onClick={() => {
              this.deleteRegistration();
            }}
          >
            Löschen
          </button>
        </div>
      </div>
    );
  }
}
