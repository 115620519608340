import React, { Component } from 'react';

export default class SearchDetails extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    return <div className="SearchDetails">{this.props.children}</div>;
  }
}
