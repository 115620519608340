import React, { Component } from 'react';
import axios from 'axios';
import { ErrorMessage } from '@quirion/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class DetailsPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextNewPassword: '',
      nextNewPasswordResponse: '',
      passwordWasSent: false,
      validators: {
        stringLength: false,
        smallCharacters: false,
        capitalCharacters: false,
        specialCharacters: false,
        numbers: false,
      },
    };
  }

  async setPwd() {
    if (this.props.person.user.externalId) {
      axios.post(window.quirion_rest + 'resetpassword',
        {
          'username': this.props.person.user.username,
          'eMail': this.props.person.user.communicationEmail,
          'password': this.state.nextNewPassword,
          'forceUpdatePassword': true,
          'sendMail': true,
        })
        .then((response) => {
          this.setState({
            nextNewPasswordResponse: `Die E-Mail zum Zurücksetzen des Kennworts wurde erfolgreich\
            an der E-Mail-Adresse: ${this.props.person.user.communicationEmail} gesendet`,
            passwordWasSent: true,
            nextNewPassword: '',
            validators: {
              stringLength: false,
              smallCharacters: false,
              capitalCharacters: false,
              specialCharacters: false,
              numbers: false,
            },
          });
          setTimeout(() => {
            this.setState({ nextNewPasswordResponse: '' });
          }, 10000);
        })
        .catch((error) => {
          if (error.response.data && error.response.data.error) {
            alert(`${ErrorMessage.read(error.response.data.error.code)}`);
          } else if (error.response.data) {
            alert(`${JSON.stringify(error.response.data, null, 2)}`);
          } else {
            alert(`${JSON.stringify(error.response, null, 2)}`);
          }
        });
    }
  }

  setNextNewPassword(e) {
    this.setState({ [e.target.name]: e.target.value }, () => this.validatePassword());
  }

  validatePassword() {
    this.setState({
      validators: {
        stringLength: /^(?=.{8,})/.test(this.state.nextNewPassword),
        smallCharacters: /^(?=.*[a-z])/.test(this.state.nextNewPassword),
        capitalCharacters: /^(?=.*[A-Z])/.test(this.state.nextNewPassword),
        numberChars: /^(?=.*[0-9])/.test(this.state.nextNewPassword),
        specialCharacters: /^(?=.*[!@\$\*=+?])/.test(this.state.nextNewPassword),
      },
    });
  }

  generatePassword() {
    let myPwd = [];
    var numberChars = '23456789';
    var lowerChars = 'abcdefghkmnpqrstwxyz';
    var upperChars = 'ABCDEFGHKMNPQRSTWXYZ';
    var otherChars = '!@$*=+?';

    // zwei numbers
    myPwd.push(
      numberChars.charAt(Math.floor(Math.random() * numberChars.length)),
    );
    myPwd.push(
      numberChars.charAt(Math.floor(Math.random() * numberChars.length)),
    );

    // zwei lower
    myPwd.push(
      lowerChars.charAt(Math.floor(Math.random() * lowerChars.length)),
    );
    myPwd.push(
      lowerChars.charAt(Math.floor(Math.random() * lowerChars.length)),
    );

    // zwei upper
    myPwd.push(
      upperChars.charAt(Math.floor(Math.random() * upperChars.length)),
    );
    myPwd.push(
      upperChars.charAt(Math.floor(Math.random() * upperChars.length)),
    );

    // zwei special
    myPwd.push(
      otherChars.charAt(Math.floor(Math.random() * otherChars.length)),
    );
    myPwd.push(
      otherChars.charAt(Math.floor(Math.random() * otherChars.length)),
    );

    // shuffle
    let i;
    let x;
    let j;
    for (i = myPwd.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = myPwd[i];
      myPwd[i] = myPwd[j];
      myPwd[j] = x;
    }
    this.setState({ nextNewPassword: myPwd.join('') }, () => this.validatePassword());
  }

  componentDidMount() {
    this.generatePassword();
  }

  render() {
    let markup = [];
    if (this.props.person) {
      let personId = this.props.person.id;

      markup.push(
        <div
          key="p3"
          className="DetailsPassword__text"
        >
          Möchtest Du du dem Benutzer:
          {' '}
          {personId}
          {' '}
          eine neues Passwort
          zusenden?
        </div>,
      );

      markup.push(
        <div key="p6">
          <input
            className="DetailsPassword__input"
            type="text"
            name="nextNewPassword"
            onChange={(e) => this.setNextNewPassword(e)}
            value={this.state.nextNewPassword}
          />
          <button
            className="DetailsPassword__button"
            onClick={() => this.generatePassword()}
          >
            Passwort generieren
          </button>
          <button
            className="DetailsPassword__button"
            onClick={() => this.setPwd()}
            disabled={
              !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\$\*=+?])(?=.{8,})/.test(this.state.nextNewPassword)
            }
          >
            Passwort senden
          </button>
        </div>,
      );

      markup.push(
        <div
          key="p7"
          className="DetailsPassword__message"
        >
          {this.state.passwordWasSent && <span>{this.state.nextNewPasswordResponse}</span>}
        </div>,
      );

      markup.push(
        <div className="DetailsPassword__rules" key="p8">
          <h3>Passwortregeln:</h3>
          <div>{'(Mindestens ein Zeichen von jedem.)'}</div>
          <div>
            <span>{'Mindestlänge, 8: '}</span>
            {
              this.state.validators.stringLength
                ? <FontAwesomeIcon icon="thumbs-up" color="green" />
                : <FontAwesomeIcon icon="thumbs-down" color="red" />
            }
          </div>
          <div>
            <span>{'Kleine Zeichen: '}</span>
            {this.state.validators.smallCharacters
              ? <FontAwesomeIcon icon="thumbs-up" color="green" />
              : <FontAwesomeIcon icon="thumbs-down" color="red" />
            }
          </div>
          <div>
            <span>{'Großbuchstaben: '}</span>
            {this.state.validators.capitalCharacters
              ? <FontAwesomeIcon icon="thumbs-up" color="green" />
              : <FontAwesomeIcon icon="thumbs-down" color="red" />
            }
          </div>
          <div>
            <span>{'Sonderzeichen !@$*=+? : '}</span>
            {this.state.validators.specialCharacters
              ? <FontAwesomeIcon icon="thumbs-up" color="green" />
              : <FontAwesomeIcon icon="thumbs-down" color="red" />
            }
          </div>
          <div>
            <span>{'Zahlen: '}</span>
            {this.state.validators.numberChars
              ? <FontAwesomeIcon icon="thumbs-up" color="green" />
              : <FontAwesomeIcon icon="thumbs-down" color="red" />
            }
          </div>
        </div>,
      );
    }

    return (
      <div className="DetailsPassword">
        <div>{markup}</div>
      </div>
    );
  }
}
