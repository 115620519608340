import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

export default class DetailsSavingsPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBps: [],
      savingsPlans: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { person } = this.props;
    if (person && person?.user?.externalId) {
      const bps = person?.businessPartner;
      if (bps && bps.length) {
        this.setState({
          listBps: [],
        });
        bps.forEach((item) => {
          this.loadBusinessPartner(item.businessPartnerId);
        });
      }
    }
  }

  async loadBusinessPartner(id) {
    this.setState({ isLoading: true });
    axios
      .post(
        `${window.quirion_rest}getbusinesspartner`,
        { businessPartnerId: parseInt(id, 10) },
      )
      .then((response) => {
        this.setState({ isLoading: false });
        this.setState((prevState) => ({
          listBps: [].concat(
            prevState.listBps,
            [{
              ...response.data,
              businessPartnerId: id,
            }],
          ),
        }));

        response.data.ips.forEach((ips) => {
          this.loadIps(ips);
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error(error);
      });
  }

  async loadIps(ips) {
    this.setState({ isLoading: true });
    axios
      .post(
        `${window.quirion_rest}getips`,
        { ipsId: parseInt(ips.ipsId, 10) },
      )
      .then((response) => {
        this.setState((prevState) => ({
          savingsPlans: [].concat(
            prevState.savingsPlans,
            response.data.savingsPlans.map((item) => {
              item.ipsId = ips.ipsId;
              item.businessPartnerId = ips.businessPartnerId;
              return item;
            }),
          ),
          isLoading: false,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const bpsWithSavinsplans = [];
    const { listBps, savingsPlans, isLoading } = this.state;
    const { person } = this.props;
    if (listBps) {
      for (let i = 0; i < listBps.length; i++) {
        const bp = {
          ...listBps[i],
          ...person.businessPartner.find((bpItem) => (
            bpItem.businessPartnerId === listBps[i].businessPartnerId
          )),
        };
        for (let k = 0; k < bp.ips.length; k++) {
          const ips = bp.ips[k];
          if (savingsPlans) {
            for (let m = 0; m < savingsPlans.length; m++) {
              const savingsPlan = savingsPlans[m];
              if (
                savingsPlan.ipsId !== ips.ipsId
                || savingsPlan.businessPartnerId !== bp.businessPartnerId
              ) {
                continue;
              }

              const iban = savingsPlan.bankAccount.referenceAccount
                ? `${bp.reference.iban} / ${bp.reference.bic}`
                : `${savingsPlan.bankAccount.iban} / ${savingsPlan.bankAccount.bic}`;
              bpsWithSavinsplans.push(
                <div key={`${bp.businessPartnerId}-${i}-${k}-${m}`} className="listElement">
                  <div>{`${bp.accountType}(${bp.businessPartnerId})`}</div>
                  <div>
                    <div>{ips.name}</div>
                    <div>
                      {`Ips ID: ${ips.ipsId} Status: `}
                      <span className={`statusProduct ${ips.status}`}>
                        {`${ips.status}`}
                      </span>
                    </div>
                  </div>
                  <div>{`${savingsPlan.amount} €`}</div>
                  <div>
                    {`${savingsPlan.savingsPlanCounter} ${savingsPlan.savingsPlanInterval}`}
                  </div>
                  {
                    moment(savingsPlan.startDate).isValid()
                      ? (
                        <div>
                          <div><Moment format="YYYY-MM-DD" date={savingsPlan.startDate} /></div>
                          <div><Moment format="(HH:mm)" date={savingsPlan.startDate} /></div>
                        </div>
                      )
                      : <div />
                  }
                  {
                    moment(savingsPlan.nextDate).isValid()
                      ? (
                        <div>
                          <div><Moment format="YYYY-MM-DD" date={savingsPlan.nextDate} /></div>
                          <div><Moment format="(HH:mm)" date={savingsPlan.nextDate} /></div>
                        </div>
                      )
                      : <div />
                  }
                  {
                    moment(savingsPlan.endDate).isValid()
                      ? (
                        <div>
                          <div><Moment format="YYYY-MM-DD" date={savingsPlan.endDate} /></div>
                          <div><Moment format="(HH:mm)" date={savingsPlan.endDate} /></div>
                        </div>
                      )
                      : <div />
                  }
                  {
                    moment(savingsPlan.createdAt).isValid()
                      ? (
                        <div>
                          <div><Moment format="YYYY-MM-DD" date={savingsPlan.createdAt} /></div>
                          <div><Moment format="(HH:mm)" date={savingsPlan.createdAt} /></div>
                        </div>
                      )
                      : <div />
                  }
                  <div>
                    <div className="savingsPlan__accounDesc">
                      {
                        savingsPlan.bankAccount.referenceAccount
                          ? '(Referenzkonto)'
                          : '(Sparkonto)'
                      }
                    </div>
                    <div>{iban}</div>
                  </div>
                  <div className="savingsPlan__isWalle">
                    {savingsPlan.wallE ? 'ja' : 'nein'}
                  </div>
                  <div className="savingsPlan__WalleeMinimumBalancee">
                    {savingsPlan.wallEMinimumBalance ? `${savingsPlan.wallEMinimumBalance} €` : '-'}
                  </div>
                  <div className="savingsPlan__WalleeUpperThreshold">
                    {savingsPlan.wallEUpperThreshold ? `${savingsPlan.wallEUpperThreshold} €` : '-'}
                  </div>
                </div>,
              );
            }
          }
        }
      }
    }

    return (
      <div className={`DetailsSavingsPlans ${isLoading ? ' -loading' : ''}`}>
        <div className="savinsplans">
          <div className="table">
            <div>
              <div>BP</div>
              <div>Produkt</div>
              <div>Rate</div>
              <div>Intervall</div>
              <div>erster Termin</div>
              <div>nächster Termin</div>
              <div>letzter Termin</div>
              <div>angelegt am</div>
              <div>IBAN/BIC</div>
              <div>WallE Typ</div>
              <div>Untergrenze Girokonto</div>
              <div>Maximale Sparplanrate</div>
            </div>
            {bpsWithSavinsplans}
          </div>
        </div>
      </div>
    );
  }
}
