import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';

export default class DeleteRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorEmail: '',
      form_is_submitting: false,
    };
  }

  async deleteRegistration() {
    let inputData = {
      email: this.state.email,
    };

    let response = await API.graphql(
      graphqlOperation(mutations.kubeDeleteRegistration, inputData)
    );

    if (response && response.data && response.data.kubeDeleteRegistration) {
      let answer = JSON.parse(response.data.kubeDeleteRegistration);
      console.log(response, answer);

      if (answer.status) {
        alert('Gut gemacht! Person ist gelöscht!');
        // eslint-disable-next-line no-restricted-globals
        // clearing all input fields after successful submission
        this.setState({ email: '' });
      } else {
        alert(answer.message);
      }
    } else {
      alert('Etwas ist schief gelaufen!');
    }

    this.setState({ form_is_submitting: false });
  }

  inputHandler = event => {
    // console.log('Was changed', event.target);
    this.setState({ [event.target.name]: event.target.value });
  };

  checkForm = event => {
    event.preventDefault();
    let regExEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let errorEmail = false;
    let errorExists = false;

    if (regExEmail.test(this.state.email) === false) {
      this.setState({
        errorEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
      });
      errorExists = true;
    } else {
      this.setState({ errorEmail: '' });
    }

    if (errorExists) {
      console.log('do nothing');
    } else {
      console.log('jsonaction');
      if (this.state.form_is_submitting === false) {
        this.setState({ form_is_submitting: true });
        this.deleteRegistration();
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="forms form2">
          <h2>Registration Löschen</h2>
          <form onSubmit={this.checkForm} noValidate>
            <label>
              E-mail
              <input
                onChange={this.inputHandler}
                value={this.state.email}
                type="text"
                name="email"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <div className="errorMessage">{this.state.errorEmail}</div>
            <button type="submit">Absenden</button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
