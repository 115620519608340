import React from 'react';
import moment from 'moment';

import './CaseStatus.css';

const statusOrder = [
  'owner1Confirmed',
  'owner1Accepted',
  'owner1Ident',
  'owner1Robo',
  'owner1Avaloq',
  'owner2Confirmed',
  'owner2Code',
  'owner2Ident',
  'owner2Robo',
  'owner2Avaloq',
  'owner1Allowed',
  'childIdent',
  'childRobo',
  'childAvaloq',
  'robo',
  'avaloq',
  'bloxxterOrder',
  'bloxxterUser',
];

export function CaseStatus(props) {
  const { data } = props;
  return (
    <div className="CaseStatus">
      <table>
        <tbody>
          <tr>
            {
              statusOrder.map((status, idx) => {
                if (typeof data[status] !== 'undefined') {
                  return (
                    <th
                      key={status + idx}
                      className={
                        data[status] ? 'yes' : 'no'
                      }
                    >
                      {
                        status.includes('child')
                          ? 'child'
                          : status.includes('owner')
                            ? status.substr(0, 6)
                            : status.includes('bloxxter')
                              ? status.substr(0, 8)
                              : ''
                      }
                      <br />
                      {
                        status.includes('child')
                          ? status.substr(5)
                          : status.includes('owner')
                            ? status.substr(6)
                            : status.includes('bloxxter')
                              ? status.substr(8)
                              : status
                      }
                    </th>
                  );
                }
                return null;
              })
            }
          </tr>
          <tr>
            {
              statusOrder.map(status => {
                if (typeof data[status] !== 'undefined') {
                  if (data[status + 'Date']) {
                    const statusDate = moment(data[status + 'Date']);
                    return (
                      <td
                        key={status}
                        className="yes"
                      >
                        {statusDate.format('DD.MM.YYYY')}
                        <br />
                        {statusDate.format('HH:mm') + ' Uhr'}
                      </td>
                    );
                  }
                  return (
                    <td
                      key={status}
                      className={data[status] ? 'yes' : 'no'}
                    >
                      –
                    </td>
                  );
                }
                return null;
              })
            }
          </tr>
        </tbody>
      </table>
    </div>
  );
}
