import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalWrapper from '../../../../components/ModalWrapper';
import StreetAutocompleteInput from '../../../../components/StreetAutocompleteInput';
import countries from '../../../../../countries';
import helperGlobal from '../../../../utils/helper_global';
import { doAvaloq, doTestUser, isProduction } from '../../utils/doTestUser';

// uses query from the SearchResults, no own query
export default class DetailsPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newValues: {}, // empty object with values to be updated on server
      person: {},
      editable: false,
      isLoading: false,
      openUsernameModal: false,
      newUsername: '',
      validators: {
        salutation: true,
        firstName: true,
        userName: true,
        lastName: true,
        telMobile: true,
        telLandline: true,
        birthDate: true,
        birthPlace: true,
        maritalStatus: true,
        street: true,
        houseNumber: true,
        tin: true,
        zip: true,
        city: true,
        country: true,
        nationality: true,
        countryOfDocument: true,
        residenceCountry: true,
      },
    };
  }

  // Initialize values for state.person and state.newValues (couldn't be initialized in constructor
  //  from props)
  static getDerivedStateFromProps(props, state) {
    if (state.person.user === undefined && props.person.user !== undefined) {
      const tempPerson = props.person;
      // filter out xml and mp4 from person.identFiles
      if (tempPerson.user.identFiles && tempPerson.user.identFiles.length > 0) {
        tempPerson.user.identFiles = tempPerson.user.identFiles.filter(
          (i) => !i.displayName.match(/(.xml)|(.mp4)/),
        );
      }
      return {
        person: tempPerson,
      };
    }
    return null;
  }

  updateValue = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const { newValues: tempValues, person: tempPerson } = this.state;
    const newValues = { ...tempValues, [e.target.name]: value };
    const person = JSON.parse(JSON.stringify(tempPerson)); // TODO dirt clone
    person.user[e.target.name] = value;
    this.setState({ newValues, person });
  };

  updateAddressValues = (props) => {
    const { address } = props;
    const { newValues: tempValues, person: tempPerson } = this.state;
    const person = JSON.parse(JSON.stringify(tempPerson)); // TODO dirt clone

    const mapAddressProperties = (objectName) => {
      const newProps = {
        street: address?.street,
        houseNumber: address?.houseNumber,
        zip: address?.postalCode,
        city: address?.city,
        residenceCountry: address?.countryCode,
      };
      // remove undefined props
      Object.keys(newProps).forEach((key) => newProps[key] === undefined && delete newProps[key]);

      return { ...objectName, ...newProps };
    };

    person.user = mapAddressProperties(person.user);
    const newValues = mapAddressProperties(tempValues);

    this.setState({ newValues, person });
  }

  updateTax = (e, i) => {
    const { person: tempPerson, newValues: tempValues } = this.state;
    const person = JSON.parse(JSON.stringify(tempPerson)); // TODO dirt clone
    person.user.tax[i][e.target.name] = e.target.value;
    const newValues = { ...tempValues, tax: person.user.tax };
    this.setState({ newValues, person });
  }

  /**
  * Empty local person state, disable the edit view
  * @param id: user's external id, needed to trigger the parent api via
  * onPersonUpdated to load the api
  * @param resetEdit: Enables the editView toggle.
  */
  updateView = (id, resetEdit) => {
    const { onPersonUpdated } = this.props;
    if (resetEdit) this.editView();
    onPersonUpdated(id);
    this.setState({
      person: {},
      newValues: {},
      isLoading: false,
    });
  }

  validateProps = (values) => {
    if (values !== undefined && Object.keys(values).length > 0) {
      const validations = {};
      const updateValidations = (prop, condition) => {
        validations[prop] = condition;
      };
      Object.keys(values).forEach((property) => {
        // check property
        switch (property) {
          case 'salutation':
            updateValidations(property, values[property] === ('Herr' || 'Frau'));
            break;
          case 'firstName':
          case 'lastName':
          case 'telMobile':
          case 'telLandline':
          case 'street':
          case 'zip':
          case 'city':
          case 'birthPlace':
          case 'tin': // TODO validate properly
            updateValidations(property, values[property].length > 3);
            break;
          case 'houseNumber':
          case 'nationality':
          case 'countryOfDocument':
          case 'country':
            updateValidations(property, values[property].length >= 1);
            break;
          case 'birthDate': // 1234-12-31
            updateValidations(property, /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(values[property]));
            break;
          case 'maritalStatus':
            updateValidations(property, values[property]
              === ('single' || 'married' || 'divorced' || 'widowed'));
            break;
          default:
        }
      });
      this.setState((prevState) => ({
        validators: {
          ...prevState.validators,
          ...validations,
        },
      }));
      Object.keys(validations).forEach((property) => {
        if (validations[property] === false) return false;
      });

      return true;
    }
    this.setState({
      validators: {
        salutation: true,
        firstName: true,
        lastName: true,
        telMobile: true,
        telLandline: true,
        birthDate: true,
        birthPlace: true,
        maritalStatus: true,
        street: true,
        houseNumber: true,
        zip: true,
        city: true,
        country: true,
        nationality: true,
        countryOfDocument: true,
        tin: true,
        residenceCountry: true,
      },
    });
  }

  // enable view to update mode
  editView = () => {
    this.setState((prevState) => ({
      editable: !prevState.editable,
    }));
  };

  cancelEditView = () => {
    this.editView();
    this.setState({
      person: this.props.person,
      newValues: {},
    });
    this.validateProps();
  }

  getCountryList = (type) => {
    const countryList = countries.map((element, i) => (
      <option key={`${type}_${i + 1}`} value={Object.keys(element)[0]}>
        {Object.values(element)[0]}
      </option>
    ));
    return countryList;
  };

  hasOpenBps = (bps) => {
    if (bps && bps.length > 0) {
      return bps.some((bp) => bp.status === 'OPEN');
    }
    return false;
  }

  validateUsername = () => {
    const { newUsername } = this.state;
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    this.setState((prevState) => ({
      validators: {
        ...prevState.validators,
        userName: emailRegex.test(newUsername),
      },
    }));
    return emailRegex.test(newUsername);
  }

  updateUsernameValue(e) {
    this.setState({ newUsername: e.target.value });
  }

  toggleUsernameModal() {
    this.setState((prevState) => ({
      openUsernameModal: !prevState.openUsernameModal,
      newUsername: '',
      validators: {
        ...prevState.validators,
        userName: true,
      },
    }));
  }

  async updateUsername() {
    const { newUsername, person } = this.state;
    if (this.validateUsername()) {
      this.toggleUsernameModal();
      const id = parseInt(person.user.externalId, 10);
      this.setState({ isLoading: true });
      axios
        .post(`${window.quirion_rest}changeusername`, {
          'oldMail': person.user.username,
          'newMail': newUsername,
        })
        .then(() => {
          alert(`Benutzername: ${newUsername} war erfolgreich Aktualisiert.`);
          this.updateView(id, false);
        })
        .catch((error) => {
          if (error.response?.data?.error) {
            const { code } = error.response.data.error;
            switch (code) {
              case '9223':
                alert(`Der Benutzer mit ${newUsername} existiert schoen!`);
                break;
              default:
                break;
            }
          }
          console.log(
            `The username for a user with ExternalID: ${person.user.externalId} could not be updated... ${error}`,
          );
          this.updateView(id, false);
        });
    }
  }

  async updateUser() {
    const { newValues, person } = this.state;
    if (this.validateProps(newValues)) {
      const id = parseInt(person.user.externalId, 10);
      newValues.externalId = id;
      this.setState({ isLoading: true });
      this.setState(
        { newValues },
        () => {
          axios
            .post(`${window.quirion_rest}updateuser`, { ...newValues })
            .then(() => {
              alert(`Benutzer mit External ID: ${id} war erfolgreich "Updated" !!!`);
              this.updateView(id, true);
            })
            .catch((error) => {
              console.log(
                `The user with ExternalID: ${person.user.externalId} could not be updated... \
                ${error}`,
              );
            });
        },
      );
    }
  }

  /**
     * Api call to delete a user or a registration depending on the provided type parameter.
     * @param type could be 'userdelete' or 'deleteregistration'
     * @property id - integer value of the users external id.
     * @property who - message for the alert box.
    * */
  async delete(type) {
    const { person } = this.state;
    const { onPersonDeleted } = this.props;
    const id = parseInt(person.user.externalId, 10);
    const who = (type === 'userdelete')
      ? 'Benutzer'
      : (type === 'deleteregistration')
        ? 'Benutzer Registration'
        : '';
    const confirmed = window.confirm(
      `Möchten Sie löschen ${person.user.salutation || ''} ${person.user.lastName || ''} mit Externe ID: ${id}?`,
    );
    if (confirmed) {
      this.setState({ isLoading: true });
      axios.post(
        `${window.quirion_rest + type}`,
        { 'externalId': id },
      ).then((response) => {
        if (response.status === 200) {
          alert(`Der ${who} mit der externen ID: ${id} wurde erfolgreich gelöscht.`);
          onPersonDeleted();
          this.setState({ isLoading: false });
        }
      }).catch((error) => {
        console.log(`An error occurred when deleting a user: ${error}`);
        this.setState({ isLoading: false });
      });
    }
  }

  render() {
    const {
      person,
      newUsername,
      openUsernameModal,
      isLoading
    } = this.state;
    const { user = {}, personStatusInfo } = person;
    const { externalId } = user;

    if (user) {
      const bps = person?.businessPartner;
      const { validators, editable, newValues } = this.state;
      const additionalTax = user.tax && user.tax.length > 0 ? user.tax : [];
      const bpOpen = this.hasOpenBps(bps);

      return (
        <>
          <div className={
            `DetailsPerson ${openUsernameModal ? 'on-blur' : ''} ${isLoading ? ' -loading' : ''}`
            }
          >
            <div className="personinfos__container">
              <div className="personinfos__column" key="collumn_1">
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Externe ID:</span>
                  <span className="personinfos__element__value">{user.externalId}</span>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Benutzerkonto:</span>
                  <span className="personinfos__element__value">{user.username}</span>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Kontakt E-mail:</span>
                  <span className="personinfos__element__value">{user.communicationEmail}</span>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Case ID:</span>
                  <span className="personinfos__element__value">{user.caseId}</span>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Status:</span>
                  <span className="personinfos__element__value">{user.status}</span>
                </div>
                {/* <div className="personinfos__element">
                    <span className="personinfos__element__title">Benutzername:</span>
                    <span className="personinfos__element__value">{user.username}</span>
                  </div> */}
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Anrede:</span>
                  {editable && (
                    <select
                      className={`personinfos__dropdown ${editable ? '' : 'input--disabled '}
                      ${!validators.salutation ? 'invalid__field' : ''}`}
                      value={user.salutation}
                      name="salutation"
                      onChange={this.updateValue}
                      disabled={!editable}
                    >
                      <option key="herrOption" value="Herr">Herr</option>
                      <option key="frauOption" value="Frau">Frau</option>
                      {/* <option value="Diverse">Diverse</option> */}
                    </select>
                  )}
                  {!editable && <div className="personinfos__element__value">{user.salutation}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Vorname:</span>
                  {editable && (
                    <input
                      value={user.firstName}
                      name="firstName"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.firstName ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  )}
                  {!editable && <div className="personinfos__element__value">{user.firstName}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Nachname:</span>
                  {editable && (
                    <input
                      value={user.lastName}
                      name="lastName"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.lastName ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  )}
                  {!editable && <div className="personinfos__element__value">{user.lastName}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Mobile:</span>
                  {editable && (
                    <input
                      value={user.telMobile}
                      name="telMobile"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.telMobile ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  )}
                  {!editable && <div className="personinfos__element__value">{user.telMobile}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Festnetz:</span>
                  {editable && (
                    <input
                      value={user.telLandline}
                      name="telLandline"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.telLandline ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  )}
                  {!editable
                    && <div className="personinfos__element__value">{user.telLandline}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">
                    Geburtsdatum:
                  </span>
                  {editable && (
                    <input
                      value={user.birthDate}
                      name="birthDate"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.birthDate ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  )}
                  {!editable && <div className="personinfos__element__value">{user.birthDate}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">
                    Geburtsort:
                  </span>
                  {editable && (
                    <input
                      value={user.birthPlace}
                      name="birthPlace"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.birthPlace ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  )}
                  {!editable && <div className="personinfos__element__value">{user.birthPlace}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">
                    Familienstand:
                  </span>
                  {editable && (
                    <select
                      className={
                        `personinfos__dropdown ${editable ? '' : 'input--disabled '
                        }${!validators.maritalStatus ? 'invalid__field' : ''}`
                      }
                      value={user.maritalStatus}
                      name="maritalStatus"
                      onChange={this.updateValue}
                      disabled={!editable}
                    >
                      <option key="marStat_single" value="single">ledig</option>
                      <option key="marStat_married" value="married">verheiratet</option>
                      <option key="marStat_divorced" value="divorced">geschieden</option>
                      <option key="marStat_widowed" value="widowed">verwitwet</option>
                    </select>
                  )}
                  {!editable && <div className="personinfos__element__value">{user.maritalStatus}</div>}
                </div>
              </div>
              <div className="personinfos__column" key="collumn_2">
                <div>
                  <strong>Adresse:</strong>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Strasse:</span>
                  {editable ? (
                    <StreetAutocompleteInput
                      value={user.street}
                      name="street"
                      placeholder="Straße"
                      autocomplete="nope"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.street ? 'invalid__field' : '')
                      }
                      // onBlur={this.updateValue}
                      onAddressSelect={this.updateAddressValues}
                      disabled={!editable}
                    />

                  ) : (<div className="personinfos__element__value">{user.street}</div>)}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Hausnummer:</span>
                  {editable ? (
                    <input
                      value={user.houseNumber}
                      name="houseNumber"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.houseNumber ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  ) : (<div className="personinfos__element__value">{user.houseNumber}</div>)}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">
                    Postleitzahl:
                  </span>
                  {editable && (
                    <input
                      value={user.zip}
                      name="zip"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.zip ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  )}
                  {!editable && <div className="personinfos__element__value">{user.zip}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Stadt:</span>
                  {editable && (
                    <input
                      value={user.city}
                      name="city"
                      onChange={this.updateValue}
                      className={
                        (editable ? '' : 'input--disabled ')
                        + (!validators.city ? 'invalid__field' : '')
                      }
                      disabled={!editable}
                    />
                  )}
                  {!editable && <div className="personinfos__element__value">{user.city}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Land:</span>
                  {editable && (
                    <select
                      className={
                        `personinfos__dropdown ${editable ? '' : 'input--disabled '
                        }${!validators.residenceCountry ? 'invalid__field' : ''}`
                      }
                      value={user.residenceCountry}
                      name="residenceCountry"
                      onChange={this.updateValue}
                      disabled={!editable}
                    >
                      {this.getCountryList('personCountry')}
                    </select>
                  )}
                  {!editable && <div className="personinfos__element__value">{user.residenceCountry}</div>}
                </div>
                <div>
                  <br />
                  <strong>Ausweis</strong>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Nummer:</span>
                  <span className="personinfos__element__value">{user.passportNumber}</span>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Aussteller:</span>
                  <span className="personinfos__element__value">{user.authority}</span>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Ausgestellt:</span>
                  <span className="personinfos__element__value">{user.dateIssued}</span>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Gültig:</span>
                  <span className="personinfos__element__value">{user.dateOfExpiry}</span>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">
                    Nationalität:
                  </span>
                  {editable && (
                    <select
                      className={
                        `personinfos__dropdown ${editable ? '' : 'input--disabled '
                        }${!validators.nationality ? 'invalid__field' : ''}`
                      }
                      value={user.nationality}
                      name="nationality"
                      onChange={this.updateValue}
                      disabled={!editable}
                    >
                      {this.getCountryList('personNationality')}
                    </select>
                  )}
                  {!editable
                    && <div className="personinfos__element__value">{user.nationality}</div>}
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">
                    Land des Dokuments:
                  </span>
                  {editable && (
                    <select
                      className={
                        `personinfos__dropdown ${editable ? '' : 'input--disabled '
                        }${!validators.countryOfDocument ? 'invalid__field' : ''}`
                      }
                      value={user.countryOfDocument}
                      name="countryOfDocument"
                      onChange={this.updateValue}
                      disabled={!editable}
                    >
                      {this.getCountryList('personCountryOfDocument')}
                    </select>
                  )}
                  {!editable
                    && <div className="personinfos__element__value">{user.nationality}</div>}
                </div>
              </div>
              <div className="personinfos__column" key="collumn_3">
                <div>
                  <strong>Steuer-IDs:</strong>
                </div>
                {editable && (
                  Array.isArray(additionalTax) && additionalTax.length
                    ? additionalTax.map((item, i) => (
                      <div key={`TaxElement_${i + 1}`}>
                        <div
                          key={`TaxInput_${i + 1}`}
                          className="personinfos__element"
                        >
                          <span className="personinfos__element__title">
                            {`Steuer-ID-${i + 1}:`}
                          </span>
                          <input
                            value={item.tin}
                            name="tin"
                            onChange={(e) => this.updateTax(e, i)}
                            className={
                              (editable ? '' : 'input--disabled ')
                              + (!validators.tin ? 'invalid__field' : '')
                            }
                            disabled={!editable}
                          />
                        </div>
                        <div
                          key={`TaxCountry_${i + 1}`}
                          className="personinfos__element"
                        >
                          <span className="personinfos__element__title">
                            {`Steuerland-${i + 1}:`}
                          </span>
                          <select
                            className={
                              `personinfos__dropdown ${editable ? '' : 'input--disabled '}\
                              ${!validators.countryOfDocument ? 'invalid__field' : ''}`
                            }
                            value={item.country}
                            name="country"
                            onChange={(e) => this.updateTax(e, i)}
                            disabled={!editable}
                          >
                            {this.getCountryList('person_taxCountry')}
                          </select>
                        </div>
                      </div>
                    ))
                    : ''

                )}
                {!editable && (
                  <div>
                    {Array.isArray(additionalTax) && additionalTax.length
                      ? additionalTax.map((item, i) => (
                        <div key={`TaxElementReadOnly_${i + 1}`}>
                          <div
                            className="personinfos__element"
                            key={`TaxNo_${i + 1}`}
                          >
                            <span key={item.tin} className="personinfos__element__title">
                              {`Steuer-ID-${i + 1}:`}
                            </span>
                            <div className="personinfos__element__value">
                              {item.tin}
                            </div>
                          </div>
                          <div className="personinfos__element">
                            <span key={item.tin} className="personinfos__element__title">
                              {`Steuerland-${i + 1}:`}
                            </span>
                            <div className="personinfos__element__value">
                              {item.country}
                            </div>
                          </div>
                        </div>
                      ))
                      : ''}
                  </div>
                )}
                <div className="personinfos__element">
                  <span className="personinfos__element__title">PEP:</span>
                  <span className="personinfos__element__value">
                    <input
                      name="pep"
                      type="checkbox"
                      checked={user.pep}
                      onChange={this.updateValue}
                      disabled={!editable}
                    />
                  </span>
                </div>
                <div>
                  <br />
                  <strong>Postident</strong>
                </div>
                {user.identStatus ? (
                  <div>PostIdent: ERLEDIGT</div>
                ) : (
                  <>
                    <div>PostIdent: UNERLEDIGT</div>
                    <div>
                      <button
                        type="button"
                        onClick={() => doTestUser.ident(user, externalId, 'postident')}
                      >
                        perform postIdent
                      </button>
                      <button
                        type="button"
                        onClick={() => doTestUser.ident(user, externalId, 'idnow')}
                      >
                        perform idNow
                      </button>
                    </div>
                  </>
                )}
                {!isProduction && (
                  <button
                    type="button"
                    onClick={() => doTestUser.person(externalId)}
                  >
                    Activate person
                  </button>
                )}
                <div>
                  <br />
                  <strong>Dokumente</strong>
                </div>
                <div className="personinfos--documents">
                  {Array.isArray(user.identFiles)
                    && user.identFiles.length > 0
                    ? user.identFiles.map((item, i) => (
                      <div
                        role="button"
                        key={`identFile_${i + 1}`}
                        onClick={() => helperGlobal.downloadDocument(item.key, item.displayName)}
                        onKeyPress={() => helperGlobal.downloadDocument(item.key, item.displayName)}
                        className="personinfos--documents__item"
                        aria-hidden
                      >
                        <span style={{ padding: '0 5px 0 0' }}>-</span>
                        <FontAwesomeIcon icon="file-alt" />
                        <span className="personinfos--documents__item--name">
                          {item.displayName}
                        </span>
                      </div>
                    ))
                    : ''}
                </div>
              </div>
            </div>
            <div className="personinfos_buttons">
              {(editable === true && Object.keys(newValues).length > 0)
                && <button type="button" onClick={() => this.updateUser()}>Speichern</button>}
              {editable === true
                && <button type="button" onClick={() => this.cancelEditView()}>Abbrechen</button>}
              {editable === false
                && <button type="button" onClick={this.editView}>Bearbeiten</button>}
              <button
                type="button"
                title={
                  user.status === 'OPEN'
                    ? 'Es ist möglich, den Benutzerkonto nur für Benutzer mit dem Status: Offen zu ändern'
                    : ''
                }
                disabled={(user.status !== 'OPEN') || editable === true}
                onClick={() => this.toggleUsernameModal()}
              >
                Benutzerkonto ändern
              </button>
              <button
                type="button"
                title={
                  user.identStatus
                    ? 'Es können nur Benutzerkonten ohne bestätigte Identität und mit geschlossene Bps gelöscht werden.'
                    : ''
                }
                disabled={bpOpen || user.status === 'CLOSED'}
                onClick={() => this.delete('deleteregistration')}
              >
                {'Regi '}
                <FontAwesomeIcon icon="trash-alt" />
              </button>
              <button
                type="button"
                title={
                  user.status === 'OPEN'
                    ? 'Es können nur Benutzerkonten mit dem Status OFFEN gelöscht werden.'
                    : ''
                }
                disabled={(user.status !== 'OPEN') || editable === true}
                onClick={() => this.delete('userdelete')}
              >
                {'Nutzer '}
                <FontAwesomeIcon icon="trash-alt" />
              </button>
            </div>
          </div>
          <ModalWrapper
            showModal={openUsernameModal}
            triggerModal={() => this.toggleUsernameModal()}
            headerContent={<span>Benutzerkonto wechseln</span>}
            bodyContent={(
              <>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Aktuell:</span>
                  <div className="personinfos__element__value">{user.username}</div>
                </div>
                <div className="personinfos__element">
                  <span className="personinfos__element__title">Neue:</span>
                  <input
                    value={newUsername}
                    name="username"
                    onChange={(e) => this.updateUsernameValue(e)}
                    className={(!validators.userName ? 'invalid__field' : '')}
                  />
                </div>
              </>
            )}
            confirmButton={() => this.updateUsername()}
            confirmButtonTitle="speichern"
            modalStyle={{
              width: '400px',
              height: '200px',
              top: 'calc(45vh - 202px)',
              margin: '0px calc(50vw - 200px)',
            }}
          />
        </>
      );
    }
    return (
      <div className="personinfos__status">
        {personStatusInfo}
      </div>
    );
  }
}
