import React, { Component } from 'react';
import axios from 'axios';
import PersonOrders from './PersonOrders/PersonOrders';
import BusinessPartnerOrders from './BusinessPartnerOrders/BusinessPartnerOrders';
import IpsOrders from './IpsOrders/IpsOrders';
import AssetOrders from './AssetOrders';

export default class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: {},
      ips: [],
      assets: [],
      selectedObject: props.person, // preselcting Person for table on Init
      selectedObjectType: 'Person',
      activeTab: 0,
      lookupDays: 30,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { person } = this.props;
    if (person && person.user !== undefined) {
      this.setState({ person });
      this.getIpsAndAssetsFromPersonBPs();
    }
  }

  getIpsAndAssetsFromPersonBPs() {
    const { person } = this.props;
    const bps = person.businessPartner;
    if (bps !== undefined && bps.length > 0) {
      this.setState({ isLoading: true });
      bps.map((bp) => {
        axios.post(
          `${window.quirion_rest}getbusinesspartner`,
          { 'businessPartnerId': parseInt(bp.businessPartnerId, 10) },
        )
          .then((response) => {
            this.setState((prevState) => {
              const ips = prevState.ips.concat(response.data.ips);
              const assets = prevState.assets.concat(response.data.assets);
              return {
                ips,
                assets,
              };
            });
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            console.log(`While loading Ips's in for Kube Order History got an error: ${error}`);
            this.setState({ isLoading: false });
          });
      });
    }
  }

  generateListOfIps() {
    const { ips } = this.state;
    let ipsList = [];
    ipsList = ips.map((Ips) => (
      <option
        key={Ips.ipsId}
        value={JSON.stringify(
          {
            selectedObject: Ips,
            selectedObjectType: 'Ips',
          },
        )}
      >
        Ips:
        {' '}
        {Ips.ipsId}
        {' '}
        {Ips.name}
      </option>
    ));
    return ipsList;
  }

  generatetListOfBPs() {
    const { person } = this.state;
    let listBPsElements = [];
    listBPsElements = person.businessPartner.map((BPItem) => (
      <option
        key={BPItem.businessPartnerId}
        value={JSON.stringify(
          {
            selectedObject: BPItem,
            selectedObjectType: 'BP',
          },
        )}
      >
        {`BP ID: ${BPItem.businessPartnerId} Konto Typ: ${BPItem.accountType}`}
      </option>
    ));
    return listBPsElements;
  }

  generateListOfAssets() {
    const { assets } = this.state;
    const assetsList = assets.map((asset) => (
      <option
        key={asset.assetId}
        value={JSON.stringify(
          {
            selectedObject: asset,
            selectedObjectType: 'Asset',
          },
        )}
      >
        {`Asset: ${asset.conditionId} `}
      </option>
    ));
    return assetsList;
  }

  selectObject(e) {
    const value = JSON.parse(e.target.value);
    this.setState({
      selectedObject: value.selectedObject,
      selectedObjectType: value.selectedObjectType,
    });
  }

  updateDaysLookup(e) {
    this.setState({
      lookupDays: e.target.value,
    });
  }

  render() {
    const {
      person,
      selectedObject,
      lookupDays,
      selectedObjectType,
      ips,
      assets,
      isLoading,
    } = this.state;
    const { user } = person;
    if (Object.keys(person).length > 0) {
      let ordersComponent = [];
      if (selectedObjectType === 'Person') {
        ordersComponent = (
          <PersonOrders
            person={selectedObject}
            lookupDays={lookupDays}
          />
        );
      }
      if (selectedObjectType === 'BP') {
        ordersComponent = (
          <BusinessPartnerOrders
            bp={selectedObject}
            person={person}
            lookupDays={lookupDays}
          />
        );
      }
      if (selectedObjectType === 'Ips') {
        ordersComponent = (
          <IpsOrders
            ips={selectedObject}
            person={person}
            lookupDays={lookupDays}
          />
        );
      }
      if (selectedObjectType === 'Asset') {
        ordersComponent = (
          <AssetOrders
            asset={selectedObject}
            person={person}
            lookupDays={lookupDays}
          />
        );
      }

      return (
        <>
          <div className={`orderHistory ${isLoading ? ' -loading' : ''}`}>
            <div>
              <select
                className="orderHistoryList"
                onChange={(e) => this.selectObject(e)}
              >
                <option
                  key={person.externalId}
                  value={
                    JSON.stringify({
                      selectedObject: person,
                      selectedObjectType: 'Person',
                    })
                  }
                >
                  {`Person: ${user.firstName} ${user.lastName} , Externe ID: ${user.externalId}`}
                </option>
                {person.businessPartner.length > 0 && this.generatetListOfBPs()}
                {ips.length > 0 && this.generateListOfIps()}
                {assets.length > 0 && this.generateListOfAssets()}
              </select>
              <div className="orderHistoryList__rangeList">
                <label>Aufträge im Zeitraum:</label>
                <select
                  onChange={e => this.updateDaysLookup(e)}
                  defaultValue={lookupDays}
                >
                  <option value="30">30 Tage</option>
                  <option value="60">60 Tage</option>
                  <option value="90">90 Tage</option>
                  <option value="180">180 Tage</option>
                  <option value="365">1 Jahr</option>
                  <option value="730">2 Jahre</option>
                  <option value="1825">5 Jahre</option>
                  <option value="3650">10 Jahre</option>
                  <option value="7300">20 Jahre</option>
                </select>

              </div>
            </div>
            <div>
              <div>{ordersComponent}</div>
            </div>
          </div>
        </>
      );
    }
    return (
      <div />
    );
  }
}
