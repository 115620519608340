import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { num } from '@quirion/utils';
import axios from 'axios';
import {
  SegmentedControl,
  Radio,
} from '@quirion/components';
import { Grid } from 'gridjs-react';

const useVoucherHistory = () => {
  const [user, setUser] = useState(Object);
  const [casesTableData, setCasesTableData] = useState([]);
  const [ipsList, setIpsList] = useState([]);
  const [ipsVouchers, setIpsVouchers] = useState([]);
  const [ipsVouchersTableData, setIpsVouchersTableData] = useState([]);
  const [view, setView] = useState('cases');
  const [isLoading, setIsLoading] = useState(false);

  const getIpsFromPersonBPs = (bps) => {
    const ipsItems = [];
    if (bps !== undefined && bps.length > 0) {
      setIsLoading(true);
      bps.forEach((bp) => {
        axios.post(
          `${window.quirion_rest}getbusinesspartner`,
          { 'businessPartnerId': parseInt(bp.businessPartnerId, 10) },
        )
          .then((response) => {
            setIsLoading(false);
            if (response.data.ips?.length > 0) ipsItems.push(response.data.ips);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(`While loading Ips's in Voucher History got an error: ${error}`);
          })
          .finally(() => setIpsList(ipsItems.flat()));
      });
    }
  };

  const getUserVouchers = () => {
    const currentIpsVouchers = [];
    setIsLoading(true);
    ipsList.forEach((ips) => {
      axios.post(
        `${window.quirion_rest}getorder`,
        {
          'username': user.username,
          'businessPartner': false,
          'ips': true,
          'ipsId': ips.ipsId,
          'range': parseInt(7300, 10), // TODO add dropdown for time
          'status': 'ALL',
        },
      )
        .then((response) => {
          setIsLoading(false);
          const orders = response.data.ips;
          const ordersWithVouchers = orders.filter(
            (order) => Object.prototype.hasOwnProperty.call(order, 'voucher'),
          ).map((orderVoucher) => ({
            ...orderVoucher,
            createdAt: moment(orderVoucher.createdAt).format('YYYY/MM/DD HH:mm:ss'),
          }));
          if (ordersWithVouchers.length > 0) {
            currentIpsVouchers.push(ordersWithVouchers);
            setIpsVouchers(currentIpsVouchers.flat());
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    });
  };

  const switchHistoryType = (e) => setView(e.target.value);

  return {
    user,
    setUser,
    casesTableData,
    setCasesTableData,
    ipsVouchers,
    setIpsVouchers,
    getUserVouchers,
    ipsVouchersTableData,
    setIpsVouchersTableData,
    ipsList,
    getIpsFromPersonBPs,
    switchHistoryType,
    view,
    isLoading,
  };
};

export const VoucherHistory = (props) => {
  const { person } = props;
  const {
    setUser,
    casesTableData,
    setCasesTableData,
    getUserVouchers,
    ipsVouchers,
    ipsVouchersTableData,
    setIpsVouchersTableData,
    ipsList,
    getIpsFromPersonBPs,
    switchHistoryType,
    view,
    isLoading
  } = useVoucherHistory();

  // get user data
  useEffect(() => setUser({ ...person.user }), [person.user]);

  // get ips from persons bp via getorder
  useEffect(() => getIpsFromPersonBPs(person.businessPartner), [person.businessPartner]);

  useEffect(() => {
    if (person.cases?.length > 0) {
      // filter cases with voucher
      const casesWithVouchers = person.cases.filter(
        (c) => Object.prototype.hasOwnProperty.call(c, 'voucherCodes'),
      );
      // map vouchers with data from case and return the vouchers
      const vouchersWithCase = casesWithVouchers.map((caseVoucher) => (
        caseVoucher.voucherCodes.map((voucher) => ({
          amount: num.toMoney(voucher.amount),
          serialNumber: voucher.serialNumber,
          caseId: caseVoucher.caseId,
          ipsId: caseVoucher.ipsId || '',
          createdAt: moment(caseVoucher.createAt).format('YYYY/MM/DD HH:mm:ss'),
        }))
      )).flat();
      if (vouchersWithCase.length > 0) setCasesTableData(vouchersWithCase);
    }
  }, [person.cases]);

  useEffect(() => {
    if (ipsList.length > 0) getUserVouchers();
  }, [ipsList]);

  useEffect(() => {
    let ipsVoucherTableData = [];
    if (ipsVouchers.length > 0) {
      ipsVoucherTableData = ipsVouchers.map((item) => ({
        createdAt: item.createdAt,
        ipsId: item.ipsId,
        serialNumber: item.voucher.serialNumber,
        amount: num.toMoney(item.amount),
        status: item.status,
      }));
      setIpsVouchersTableData(ipsVoucherTableData);
    }
  }, [ipsVouchers]);

  return (
    <div className="orderHistory voucherHistory">
      <div className={`orderHistory__content ${isLoading ? ' -loading' : ''}`}>
        <SegmentedControl
          className="voucherHistory__segmentControl"
          name="TopupTypeControl"
          onChange={switchHistoryType}
          value={view}
          style={{ width: '100%' }}
        >
          <Radio
            key="view_cases"
            name="view_cases"
            value="cases"
            label="Cases"
          />
          <Radio
            key="view_voucher"
            name="view_voucher"
            value="ips"
            label="Ips"
          />
        </SegmentedControl>
        {((view === 'cases') && casesTableData.length > 0) && (
          <Grid
            data={casesTableData}
            columns={[
              { id: 'caseId', name: 'Case Id' },
              { id: 'serialNumber', name: 'Serienummer' },
              { id: 'amount', name: 'Wert' },
              { id: 'ipsId', name: 'Ips Id' },
              { id: 'createdAt', name: 'Erstellt' },
            ]}
            className={{
              container: 'table',
            }}
            search
            sort
            pagination={{
              enabled: true,
              limit: 30,
            }}
            language={{
              'search': {
                'placeholder': 'Suchen...',
              },
              'pagination': {
                'previous': 'zurück',
                'next': 'weiter',
                'showing': 'Zeigen',
                'results': () => 'Ergebnisse',
                'to': 'bis',
                'of': 'von',
              },
            }}
            autoWidth
          />
        )}
        {((view === 'ips') && ipsVouchersTableData.length > 0) && (
          <Grid
            data={ipsVouchersTableData}
            columns={[
              { id: 'ipsId', name: 'Ips Id' },
              { id: 'serialNumber', name: 'Serienummer' },
              { id: 'amount', name: 'Wert' },
              { id: 'status', name: 'Status' },
              { id: 'createdAt', name: 'Erstellt' },
            ]}
            className={{
              container: 'table',
            }}
            search
            sort
            pagination={{
              enabled: true,
              limit: 30,
            }}
            language={{
              'search': {
                'placeholder': 'Suchen...',
              },
              'pagination': {
                'previous': 'zurück',
                'next': 'weiter',
                'showing': 'Zeigen',
                'results': () => 'Ergebnisse',
                'to': 'bis',
                'of': 'von',
              },
            }}
            autoWidth
          />
        )}
      </div>
    </div>
  );
};
