import React, { useState } from 'react';
import { Here } from '@quirion/api';
import './StreetAutocompleteInput.scss';

/**
 * Autocomplete input for street field with dopdown list.
 * On selection these properties will be set:
 * street, houseNumber, zip, city, residenceCountry
 *
 * @component
 *
 * @author TB
 */

let addresses = [];

const AddressList = ({
  items,
  setAddress,
  toggleList,
}) => {
  if (items.length > 0) {
    const addressList = items.map(item => {
      return (
        <li
          key={`key_${item.address.label}`}
          className="AddressList__li"
          onClick={() => {
            setAddress(item);
            toggleList(false);
          }}
        >
          {item.address.label}
        </li>
      )
    });
    return (
      <ul className="AddressList__ul">
        {addressList}
      </ul>
    );
  }
  return null;
};

export default function StreetAutocompleteInput(props) {
  const [showAddressList, setShowAddressList] = useState(false);

  const {
    value,
    name,
    onChange,
    onBlur,
    disabled,
    id,
    className = '',
    onAddressSelect,
    style,
    label,
  } = props;

  function generateAddressListData(props, onChange) {
    onChange(props);
    const { value } = props.target;
    if (value.length > 1) {
      setShowAddressList(false);
      Here.getAddressSuggestion({
        searchText: value,
        onSuccess: data => {
          addresses = data.data.items;
          setShowAddressList(true);
        },
        onError: error => {
          console.log(error);
          addresses = null;
          setShowAddressList(false);
        }
      })
    }
  }

  function addressBlur(e, onBlur) {
    const evt = { ...e };
    setTimeout(() => {
      if (onBlur) onBlur(evt);
      setShowAddressList(false);
    }, 200);
  }

  return (
    <>
      <input
        id={id}
        className={`StreetAutocompleteInput ${className}`}
        style={style || {}}
        type="text"
        placeholder={label}
        value={value}
        name={name}
        onChange={(e) => generateAddressListData(e, onChange)}
        onBlur={(e) => addressBlur(e, onBlur)}
        disabled={disabled}
        autoComplete="nope"
      />
      {
        showAddressList
        && (
          <AddressList
            items={addresses}
            setAddress={onAddressSelect}
            toggleList={setShowAddressList}
          />
        )
      }
    </>
  );
}
