import React, { Component } from 'react';
import moment from 'moment';
import PostidentDetails from '../PostidentDetails';

export default class PostidentsList extends Component {
  constructor(props) {
    super();
    this.state = {
      showCaseDetails: null,
    };
  }

  getPostedentsList() {
    const returnValue = [];

    if (this.props.postidents && this.props.postidents.hits) {
      this.props.postidents.hits.forEach((hit) => {
        if (returnValue.length === 0) {
          returnValue.push(
            <div key='head'>
              <div className="listElement head">
                <div>Vorname</div>
                <div>Nachname</div>
                <div>Datum</div>
                <div>Methode</div>
                <div>Status</div>
                <div>caseId</div>
              </div>
            </div>,
          );
        }

        returnValue.push(
          <div
            key={hit.caseId}
            onClick={() => this.setState({ showCaseDetails: hit.caseId })}
          >
            <div className="listElement">
              <div>{hit.firstName}</div>
              <div>{hit.lastName}</div>
              <div>{moment(hit.identificationStatus.created).format('DD.MM.YYYY')}</div>
              <div>{hit.identificationMethod}</div>
              <div>{hit.identificationStatus.status}</div>
              <div>{hit.caseId}</div>
            </div>
            <div className="details">
              {this.state.showCaseDetails === hit.caseId ? <PostidentDetails hit={hit} /> : ''}
            </div>
          </div>,
        );
      });
    }

    if (returnValue.length === 0) {
      returnValue.push(
        <div key="nohits">
          <div>Keine Treffer.</div>
        </div>,
      );
    }

    return returnValue;
  }

  render() {
    return (
      <div className="PostidentList">
        {this.getPostedentsList()}
      </div>
    );
  }
}
