import { DynamoDBClient, QueryCommand } from '@aws-sdk/client-dynamodb';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import axios from 'axios';

// TODO: Replace alert() in functions by component from @quirion/components when available.

const { marshall, unmarshall } = require('@aws-sdk/util-dynamodb');

const myCredentials = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

const config = {
  credentials: myCredentials,
  region: 'eu-central-1',
};

const NO_ENV_VARS_ERROR = 'Make sure to provide REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_SECRET_ACCESS_KEY, REACT_APP_AWS_REGION as environment variables to ".env".';
const NO_EXTERNAL_ID_ERROR = 'It is required to provide an externalId to create an ident file';

const CLIENT_DYNAMO = new DynamoDBClient(config);
const CLIENT_S3 = new S3Client(config);
const AVALOQ_ID = '01841773';

const isProduction = process?.env?.REACT_APP_BRANCH === 'production';

const createInput = (
  bucket,
  key,
  filename,
  body,
) => ({
  Body: body, // Must be stringified JSON
  Bucket: bucket, // e.g. qb-ident-dev-quirion
  Key: `${key}/${filename}.json`, // e.g.'postident-cases/600213.json'
});

const putToAwsS3 = async (
  bucket,
  key,
  filename,
  body,
) => {
  const input = createInput(bucket, key, filename, body);
  const command = new PutObjectCommand(input);
  try {
    if (!CLIENT_S3) throw new Error(NO_ENV_VARS_ERROR);
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(error);
    return {
      response: false,
    };
  }
  const response = await CLIENT_S3.send(command);
  const statusCode = response?.$metadata?.httpStatusCode;
  const isUploaded = statusCode === 200;
  return {
    response,
    statusCode,
    isUploaded,
  };
};

const identFile = (personalData, externalId) => {
  const {
    gender,
    firstName,
    lastName,
    street,
    zip,
    city,
    houseNumber,
    telMobile: mobilePhone = '',
  } = personalData;

  try {
    if (!externalId) throw new Error(NO_EXTERNAL_ID_ERROR);
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(error);
    return false;
  }

  const requiredData = {
    gender, firstName, lastName, street, zip, city, houseNumber,
  };

  try {
    if (Object.values(requiredData).includes(undefined || '' || null)) {
      const falsyData = Object.entries(requiredData).map(([key, value]) => (!value ? key : false));
      throw new Error(`You are missing some required fields to create an ident file: ${falsyData}`);
    }
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(error);
    return false;
  }

  return {
    idnow: `
    {
      "identificationprocess": {
          "result": "SUCCESS_DATA_CHANGED",
          "companyid": "quirion",
          "filename": "542997.zip",
          "agentname": "BÖZTÜRK",
          "identificationtime": "${new Date().toISOString()}",
          "id": "QQU-KFFBL",
          "href": "/api/v1/quirion/identifications/542997.zip",
          "type": "APP",
          "transactionnumber": "${externalId}"
      },
      "customdata": {
          "custom3": null,
          "custom4": null,
          "custom1": null,
          "custom2": null,
          "custom5": null
      },
      "contactdata": {
          "mobilephone": "${mobilePhone}",
          "email": "someMail@mail.de"
      },
      "userdata": {
          "birthday": {
              "status": "NEW",
              "value": "1995-01-01"
          },
          "firstname": {
              "status": "MATCH",
              "value": "${firstName}"
          },
          "address": {
              "zipcode": {
                  "status": "MATCH",
                  "value": "${zip}"
              },
              "country": {
                  "status": "MATCH",
                  "value": "DE"
              },
              "city": {
                  "status": "MATCH",
                  "value": "${city}"
              },
              "street": {
                  "status": "MATCH",
                  "value": "${street}"
              },
              "streetnumber": {
                  "status": "MATCH",
                  "value": "${houseNumber}"
              }
          },
          "birthplace": {
              "status": "NEW",
              "value": "${city}"
          },
          "nationality": {
              "status": "CHANGE",
              "value": "DE",
              "original": "DE"
          },
          "gender": {
              "status": "MATCH",
              "value": "${gender.toUpperCase()}"
          },
          "identlanguage": {
              "status": "MATCH",
              "value": "de"
          },
          "lastname": {
              "status": "MATCH",
              "value": "${lastName}"
          }
      },
      "identificationdocument": {
          "country": {
              "status": "NEW",
              "value": "DE"
          },
          "number": {
              "status": "NEW",
              "value": "P00667704"
          },
          "issuedby": {
              "status": "NEW",
              "value": "MPB"
          },
          "dateissued": {
              "status": "NEW",
              "value": "2015-08-06"
          },
          "type": {
              "status": "NEW",
              "value": "PASSPORT"
          },
          "validuntil": {
              "status": "NEW",
              "value": "2025-08-05"
          }
      },
      "attachments": {
          "pdf": "542997.pdf",
          "audiolog": "542997.mp3",
          "addressVerification": "542997_addressVerification.jpg",
          "xml": "542997.xml",
          "videolog": "542997.mp4",
          "idbackside": "542997_idbackside.jpg",
          "idfrontside": "542997_idfrontside.jpg",
          "security1": "542997_security1.jpg",
          "userface": "542997_userface.jpg",
          "security2": "542997_security2.jpg",
          "security_covered": "542997_security_covered.jpg",
          "security3": "542997_security3.jpg"
      }
    }
  `,
    postident: `
    {
      "caseId": "AFQ310D1RQC5",
      "caseStatus": {
        "status": "closed",
        "archived": false,
        "validUntil": "2020-10-25T15:30:46+01:00",
        "created": "2020-07-27T16:30:46+02:00",
        "modified": "2020-07-29T17:26:03+02:00"
      },
      "orderData": {
        "customData": {
          "custom1": "${externalId}",
          "custom2": "p2019"
        },
        "processData": {
          "targetCountry": "DEU",
          "preferredLanguage": "DE_DE",
          "webHookUrl": "https://multiconnect.lionware.de/scr.php?e=webHookUrl&c=UCr7Ggk6F7rKj6Xs4GAVKAQUpHd50pky&s=Uk6q3EyImvag8jDnlzhQn2Ill5BtirXm",
          "referenceId": "quirion-dxYqt2",
          "callbackUrlCouponRequested": {
            "webUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/coupon/1595860245",
            "iOSUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/coupon/1595860245"
          },
          "callbackUrlReviewPending": {
            "webUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/inreview/1595860245",
            "iOSUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/inreview/1595860245"
          },
          "callbackUrlIncomplete": {
            "webUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/incomplete/1595860245",
            "iOSUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/incomplete/1595860245"
          },
          "callbackUrlSuccess": {
            "webUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/ok/1595860245",
            "iOSUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/ok/1595860245"
          },
          "callbackUrlDeclined": {
            "webUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/nok/1595860245",
            "iOSUrl": "https://multiconnect.lionware.de/pi/return/8wsWV7bsUReK3Wm6U5WKHEfD4ZjZpXwZ/nok/1595860245"
          }
        },
        "contactDataProvided": {
          "salutation": "2",
          "firstName": "${firstName}",
          "lastName": "${lastName}",
          "email": "marcel.mueller@mlvv.de",
          "address": {}
        },
        "identificationDocumentProvided": {},
        "drivingLicenceProvided": {
          "drivingLicenceClasses": []
        }
      },
      "contactData": {
        "salutation": {
          "status": "match",
          "value": "2"
        },
        "title": {},
        "firstName": {
          "status": "match",
          "value": "${firstName}"
        },
        "lastName": {
          "status": "match",
          "value": "${lastName}"
        },
        "mobilePhone": {},
        "email": {
          "status": "match",
          "value": "marcel.mueller@mlvv.de"
        },
        "epost": {},
        "address": {
          "streetAddress": {},
          "appendix": {},
          "postalCode": {},
          "city": {},
          "country": {}
        }
      },
      "identifications": [
        {
          "identificationMethod": "basic",
          "identificationStatus": {
            "status": "success",
            "identificationTime": "2020-07-29T16:25:57+02:00",
            "created": "2020-07-27T16:30:59+02:00",
            "modified": "2020-07-29T17:05:30+02:00"
          },
          "identificationDocument": {
            "type": {
              "status": "new",
              "value": "1"
            },
            "number": {
              "status": "new",
              "value": "152326954"
            },
            "firstName": {
              "status": "new",
              "value": "${firstName}"
            },
            "lastName": {
              "status": "new",
              "value": "${lastName}"
            },
            "birthName": {},
            "birthDate": {
              "status": "new",
              "value": "1984-08-29"
            },
            "birthPlace": {
              "status": "new",
              "value": "${city}"
            },
            "nationality": {
              "status": "new",
              "value": "DEU"
            },
            "address": {
              "streetAddress": {
                "status": "new",
                "value": "${street} ${houseNumber}"
              },
              "appendix": {},
              "postalCode": {
                "status": "new",
                "value": "${zip}"
              },
              "city": {
                "status": "new",
                "value": "${city}"
              },
              "country": {
                "status": "new",
                "value": "DEU"
              },
              "addressMatch": {
                "mailRedirectionDomestic": false,
                "mailRedirectionInternational": false,
                "undeliverable": false,
                "addresseeDeceased": false
              }
            },
            "dateIssued": {
              "status": "new",
              "value": "2010-10-27"
            },
            "dateOfExpiry": {
              "status": "new",
              "value": "2020-10-26"
            },
            "authority": {
              "status": "new",
              "value": "Gemeinde Emmerthal"
            },
            "placeOfIssue": {},
            "countryOfDocument": {
              "status": "new",
              "value": "DEU"
            },
            "records": [
              {
                "recordId": "1038133377",
                "fileName": "AFQ310D1RQC5_idimage_1.jpg",
                "belongsTo": "identificationdocument",
                "type": "idimage",
                "mimeType": "image/jpeg"
              },
              {
                "recordId": "1038133379",
                "fileName": "AFQ310D1RQC5_idimage_2.jpg",
                "belongsTo": "identificationdocument",
                "type": "idimage",
                "mimeType": "image/jpeg"
              }
            ]
          },
          "records": [
            {
              "recordId": "1038133324",
              "fileName": "AFQ310D1RQC5_usersignature.jpg",
              "belongsTo": "method",
              "type": "usersignature",
              "mimeType": "image/jpeg"
            },
            {
              "recordId": "1038135997",
              "fileName": "AFQ310D1RQC5_result_basic.pdf",
              "belongsTo": "method",
              "type": "result",
              "mimeType": "application/pdf"
            }
          ],
          "additionalDataBasic": {
            "couponByEmailRequestCount": 1,
            "couponByEmailRequestLastTimestamp": "2020-07-27T16:31:04+02:00",
            "postOfficeStreetAddress": "Test Weg 1337",
            "postOfficeCity": "${zip} ${city}",
            "couponByPiAppRequestCount": 1,
            "couponByPiAppRequestLastTimestamp": "2020-07-27T21:15:25+02:00"
          }
        }
      ],
      "accountingData": {
        "accountingNumber": "63077231483702",
        "accountingProduct": "Postident Basic Zusatzprodukt 2"
      }
    }
  `,
  };
};

const createJson = {
  ident: (personalData, externalId, identMethod) => (
    identFile(personalData, externalId)[identMethod]
  ),
  person: (externalId) => (`
  {
    "avaloqId": "${AVALOQ_ID}",
    "externalId": "${externalId}"
  }
  `),
  bp: (bpId) => (`
  {
    "businessPartnerId": "${bpId}"
  }
  `),
  ips: (ipsId) => (`
  {
    "ipsId": "${ipsId}"
  }
  `),
  birthCertificate: (
    {
      childGender: gender,
      childFirstName: firstName,
      childLastName: lastName,
      childCity: city,
    },
    externalId,
  ) => (`
    {
      "externalId": ${externalId},
      "firstName": "${firstName}",
      "lastName": "${lastName}",
      "gender": "${gender}",
      "salutation": "${gender === 'female' ? 'Frau' : 'Herr'}",
      "birthDate": "2000-01-01",
      "birthPlace": "${city}",
      "birthCertificateNumber": "123456",
      "birthCertificateCountryOfIssuer": "DE",
      "birthCertificateIssuer": "Standesamt Jungfernstieg",
      "birthCertificateIssuerDate": "2000-01-02",
      "birthCertificateCityOfIssuer": "Jungfernstieg"
      }
    `),
};

const doIdent = async (
  personalData,
  externalId,
  identMethod = 'postident',
) => {
  const awsKeyIdent = {
    idnow: 'idnow-cases',
    postident: 'postident-cases',
  };
  const AWS_BUCKET_IDENT = 'qb-ident-dev';
  const AWS_KEY_IDENT = awsKeyIdent[identMethod];

  const input = createJson.ident(personalData, externalId, identMethod);
  if (!input) return;
  const {
    response,
    statusCode,
    isUploaded,
  } = await putToAwsS3(
    AWS_BUCKET_IDENT,
    AWS_KEY_IDENT,
    externalId,
    input,
  );
  if (!response) return;
  // eslint-disable-next-line no-alert
  alert(`
  Status Code: ${statusCode}
  S3 Bucket: ${AWS_BUCKET_IDENT}
  S3 Key: ${AWS_KEY_IDENT}
  Ident Method: ${identMethod}

  ${isUploaded
    ? '✅ The ident file was successfully uploaded.'
    : `🚫 Something went wrong (${statusCode}). See console for more.`}
  `);

  try {
    if (!isUploaded) throw Error(response);
    // eslint-disable-next-line no-alert
  } catch (error) { alert(error); }
};

const doPerson = async (externalId) => {
  const AWS_BUCKET = 'qb-avaloq-fileshare-dev';
  const AWS_KEY = 'onboarding/person';

  if (!externalId) {
    // eslint-disable-next-line no-alert
    alert(NO_EXTERNAL_ID_ERROR);
    return;
  }
  const input = createJson.person(externalId);
  const {
    response,
    statusCode,
    isUploaded,
  } = await putToAwsS3(
    AWS_BUCKET,
    AWS_KEY,
    externalId,
    input,
  );
  if (!response) return;
  // eslint-disable-next-line no-alert
  alert(`
  Status Code: ${statusCode}
  S3 Bucket: ${AWS_BUCKET}
  S3 Key: ${AWS_KEY}

  ${isUploaded
    ? '✅ The person file was successfully uploaded.'
    : `🚫 Something went wrong (${statusCode}). See console for more.`}
  `);

  try {
    if (!isUploaded) throw Error(response);
    // eslint-disable-next-line no-alert
  } catch (error) { alert(error); }
};

const doIps = async (ipsId) => {
  const AWS_BUCKET = 'qb-avaloq-fileshare-dev';
  const AWS_KEY = 'onboarding/ips';

  const input = createJson.ips(ipsId);
  const {
    response,
    statusCode,
    isUploaded,
  } = await putToAwsS3(
    AWS_BUCKET,
    AWS_KEY,
    ipsId,
    input,
  );
  if (!response) return;
  // eslint-disable-next-line no-alert
  alert(`
  Status Code: ${statusCode}
  S3 Bucket: ${AWS_BUCKET}
  S3 Key: ${AWS_KEY}

  ${isUploaded
    ? '✅ The ips file was successfully uploaded.'
    : `🚫 Something went wrong (${statusCode}). See console for more.`}
  `);

  try {
    if (!isUploaded) throw Error(response);
    // eslint-disable-next-line no-alert
  } catch (error) { alert(error); }
};

const getBpId = async (email, caseId) => {
  const params = {
    TableName: 'quirion_test',
    KeyConditionExpression: 'pk = :pk AND begins_with(sk, :sk)',
    ExpressionAttributeValues: marshall({
      ':pk': `USER#${email}`,
      ':sk': 'BP#',
    }),
  };
  const command = new QueryCommand(params);

  const doRequest = async () => {
    try {
      if (!CLIENT_DYNAMO) throw new Error(NO_ENV_VARS_ERROR);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
      return false;
    }
    const response = await CLIENT_DYNAMO.send(command);
    const { Items: items } = response;

    if (items.length === 0) {
      const MAX_RETRIES = 5;
      let retryIdx = 0;
      setTimeout(() => {
        doRequest();
        retryIdx += 1;
      }, 4000);
      try {
        if (retryIdx >= MAX_RETRIES) throw new Error(`Couldn't find a BP connected to ${caseId} in the DynamoDB.`);
        // eslint-disable-next-line no-alert
      } catch (error) { alert(error); }
    } else {
      const rawBp = items.find((item) => item.caseId.S === caseId);
      if (rawBp) {
        const bp = unmarshall(rawBp);
        const bpId = bp.sk.match(/[^#]*$/gm)[0];
        return bpId;
      };
      alert(`The caseId ${caseId} was not found under the ${email}`);
    }
  };

  const bpId = await doRequest();
  return bpId;
};

const doBp = async (email, caseId) => {
  const AWS_BUCKET = 'qb-avaloq-fileshare-dev';
  const AWS_KEY = 'onboarding/bp';

  const bpId = await getBpId(email, caseId);
  if (!bpId) return;

  const input = createJson.bp(bpId);
  const {
    response,
    statusCode,
    isUploaded,
  } = await putToAwsS3(AWS_BUCKET, AWS_KEY, bpId, input);
  if (!response) return;
  // eslint-disable-next-line no-alert
  alert(`
  Status Code: ${statusCode}
  S3 Bucket: ${AWS_BUCKET}
  S3 Key: ${AWS_KEY}

  ${isUploaded
    ? '✅ The bp file was successfully uploaded.'
    : `🚫 Something went wrong (${statusCode}). See console for more.`}
  `);

  try {
    if (!isUploaded) throw Error(response);
    // eslint-disable-next-line no-alert
  } catch (error) { alert(error); }
};

const doBirthCertificate = async (personalData, childExternalId) => {
  const API_POST_BIRTH_CERTIFICATE_URL = 'https://b2hfxzdg28.execute-api.eu-central-1.amazonaws.com/dev/birthcertificate';

  const response = await axios.post(
    API_POST_BIRTH_CERTIFICATE_URL,
    createJson.birthCertificate(personalData, childExternalId),
  );

  const statusCode = response.status;
  const wasSuccessful = statusCode === 200;

  // eslint-disable-next-line no-alert
  alert(`
    POST Birth Certificate

    Status Code: ${statusCode}

    ${wasSuccessful
    ? '✅ The birth certificate was successfully posted.'
    : `🚫 Something went wrong (${statusCode}). See console for more.`}
  `);

  try {
    if (!wasSuccessful) throw Error(response);
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(error);
    return;
  }

  doPerson(childExternalId);
};

const doTestUser = {
  ident: doIdent,
  person: doPerson,
  ips: doIps,
  bp: doBp,
  birthCertificate: doBirthCertificate,
};

export {
  isProduction,
  doTestUser,
};
