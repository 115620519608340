import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';

export default class DeleteBusinessPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessPartner: '',
      errorBusinessPartner: '',
      form_is_submitting: false,
    };
  }

  async deleteBusinessPartner() {
    const inputData = {
      businessPartner: this.state.businessPartner,
    };

    const response = await API.graphql(
      graphqlOperation(mutations.kubeDeleteBusinessPartner, inputData)
    );
    console.log(response);

    if (response && response.data && response.data.kubeDeleteBusinessPartner) {
      const answer = JSON.parse(response.data.kubeDeleteBusinessPartner);
      console.log(response, answer);

      if (answer.status) {
        alert('Gut gemacht! Business Partner ist gelöscht!');
        // eslint-disable-next-line no-restricted-globals
        // clearing all input fields after successful submission
        this.setState({ businessPartner: '' });
      } else {
        alert(answer.message);
      }
    } else {
      alert('Etwas ist schief gelaufen!');
    }

    this.setState({ form_is_submitting: false });
  }

  checkForm = (event) => {
    event.preventDefault();
    let errorExists = false;

    // regExDeleteBusinessPartner conditions:
    // 1.input must be a number
    // 2.must be 8 digits long,
    // 3.must start with 8, 4. 7th digit must be a 0
    const regExDeleteBusinessPartner = /^(?=8)(?=.{6}0)[0-9]{8}$/g;

    // condition must be 8 digit number /^\d{8}$/
    // condition must be an 8 digit number /^\d{8}$/
    // condition first digit must be 8: /^8/

    if (regExDeleteBusinessPartner.test(this.state.businessPartner) === false) {
      this.setState({
        errorBusinessPartner: 'Bitte geben Sie eine gültige BP ID ein!',
      });
      errorExists = true;
    } else {
      this.setState({ errorBusinessPartner: '' });
    }

    if (errorExists) {
      console.log('do nothing');
    } else {
      console.log('jsonaction');
      if (this.state.form_is_submitting === false) {
        this.setState({ form_is_submitting: true });
        this.deleteBusinessPartner();
      }
    }
  };

  inputHandler = (event) => {
    // console.log('Was changed', event.target);
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="forms form2">
          <h2>Business Partner Löschen</h2>
          <form onSubmit={this.checkForm} noValidate>
            <label>
              BP ID
              <input
                onChange={this.inputHandler}
                value={this.state.businessPartner}
                type="text"
                name="businessPartner"
                noValidate
                placeholder=" "
                required
              />
            </label>
            <div className="errorMessage">
              {this.state.errorBusinessPartner}
            </div>
            <button type="submit">Absenden</button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
